/* eslint-disable prefer-arrow-callback */
/* eslint-disable camelcase */
import round from 'lodash/round';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { clamp } from '@design-stack-ct/utility-core';

import { EMPTY_VALUE, getPrecisionValue, useComponentWillUnmount, useSyncRef, validateNumberString } from './helpers';
import type { PrecisionFormatting } from './types';

type BaseProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'value' | 'defaultValue' | 'onInput' | 'onChange' | 'min' | 'max' | 'step' | 'type'
>;

export type NumberInputBaseProps = BaseProps & {
    value?: number;
    defaultValue?: number;
    /**
     * Behaves like a [native `change` event listener](https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/change_event).
     * `onChange` is called when the user commits the change explicitely (e.g., by using the spinner buttons), or when the input loses focus.
     */
    onChange?: (value: number) => void;
    precision?: number;
    precisionFormatting?: PrecisionFormatting;
    min?: number;
    max?: number;
    step?: number | 'any';
    /** @internal */
    __testing_inputType?: React.InputHTMLAttributes<HTMLInputElement>['type'];
};

export function NumberInputBase(props: NumberInputBaseProps) {
    const {
        value,
        defaultValue,
        onChange,
        precision,
        precisionFormatting = 'fixed',
        min = -Infinity,
        max = Infinity,
        onFocus,
        onBlur,
        onKeyUp,
        __testing_inputType,
        ...restProps
    } = props;

    const [state, _setState] = useState<number | string>(() => {
        if (value != null) return value;
        if (defaultValue != null) return defaultValue;
        return EMPTY_VALUE;
    });
    const stateRef = useRef(state);
    const inputRef = useRef<HTMLInputElement>(null);
    const onChangeRef = useSyncRef(onChange);
    const previousValidState = useRef(state);
    const componentWillUnmount = useComponentWillUnmount();

    const setState = useCallback((newState: string | number) => {
        _setState(newState);
        stateRef.current = newState;
    }, []);

    const resetToPreviousValidState = useCallback(() => {
        setState(previousValidState.current);
    }, [setState]);

    const commit = useCallback(
        (newValue: string) => {
            const { isValid, parsed } = validateNumberString(newValue);

            if (!isValid) {
                resetToPreviousValidState();
                return;
            }

            let newState = clamp(parsed, min, max);
            if (precision != null) {
                newState = round(newState, precision);
            }

            setState(newState);
            previousValidState.current = newState;
            onChangeRef.current?.(newState);
        },
        [resetToPreviousValidState, min, max, precision, setState, onChangeRef],
    );

    const isValuePropSpecified = 'value' in props;
    useEffect(
        function syncValueWithState() {
            if (!isValuePropSpecified) return;

            const newState = value != null ? value : EMPTY_VALUE;
            setState(newState);
            previousValidState.current = newState;
        },
        [isValuePropSpecified, value, setState],
    );

    // We utilize the native `change` event because it allows us to react when the user explicitly commits the change,
    // which includes clicking a spinner button, or using the arrow keys to increment or decrement.
    //
    // React doesn't support attaching an event listener to the `change` event, so we attach it manually.
    // https://reactjs.org/docs/dom-elements.html#onchange
    // https://github.com/facebook/react/issues/9567
    useEffect(
        function attachChangeEventHandler() {
            const input = inputRef.current;

            function handleChange() {
                commit(stateRef.current.toString());
            }

            input?.addEventListener('change', handleChange);

            return () => {
                input?.removeEventListener('change', handleChange);
            };
        },
        [commit],
    );

    // We need to commit the current input value on unmount, as an input might be unmounted when it's blurred (e.g., a modal dialog).
    // React's `onBlur` is not fired on unmount, and the native `blur` event doesn't fire on unmount for Firefox.
    useEffect(
        function commitOnUnmount() {
            return () => {
                // eslint-disable-next-line react-hooks/exhaustive-deps -- irrelevant, because `componentWillUnmount` doesn't refer to a DOM node
                if (!componentWillUnmount.current) return;
                if (stateRef.current.toString() === previousValidState.current.toString()) return;

                // eslint-disable-next-line react-hooks/exhaustive-deps -- irrelevant, because `commit` doesn't refer to a DOM node
                commit(stateRef.current.toString());
            };
        },
        [componentWillUnmount, commit],
    );

    return (
        <input
            {...restProps}
            ref={inputRef}
            type={__testing_inputType ?? 'number'}
            inputMode="decimal"
            value={
                precision != null && typeof state === 'number'
                    ? getPrecisionValue(state, precision, precisionFormatting)
                    : state
            }
            min={min}
            max={max}
            onChange={(e) => {
                setState(e.currentTarget.value);
            }}
            onFocus={(e) => {
                onFocus?.(e);

                inputRef.current?.select();
            }}
            onBlur={(e) => {
                onBlur?.(e);

                const { isValid } = validateNumberString(e.currentTarget.value);

                if (!isValid) {
                    resetToPreviousValidState();
                }
            }}
            onKeyUp={(e) => {
                onKeyUp?.(e);

                if (e.key === 'Enter') {
                    commit(e.currentTarget.value);
                }
            }}
            data-testid="dsc-number-input"
        />
    );
}
