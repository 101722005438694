import { createContext } from 'react';

import type {
    PreviewSettings,
    ProductConfiguration,
    SceneConfiguration,
    TenantConfiguration,
    VortexConfiguration,
} from '../types';

export interface PreviewConfigurationProps {
    productConfiguration: ProductConfiguration;
    tenantConfiguration?: TenantConfiguration;
    vortexConfiguration?: VortexConfiguration;
    sceneConfiguration?: SceneConfiguration;
    previewSettings?: PreviewSettings;
    localization?: Record<string, string>;
}

export const PreviewConfigurationContext = createContext<PreviewConfigurationProps | undefined>(undefined);
