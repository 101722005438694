import type { PointerEvent, ReactNode } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { css, cx } from '@emotion/css';

import { useClickOutside } from '@design-stack-ct/utility-react';

import DragHandle from './DragHandle';
import { Button } from '../buttons';
import { portalTargetId } from '../portalTarget';
import { cvar } from '../theme';

const CONTENT_PADDING = 14;

export interface DrawerProps {
    title: string;
    children: ReactNode | ReactNode[];
    /** Temporarily removed due to a bug, see: https://gitlab.com/Cimpress-Technology/DocDesign/designexperience/design-stack/-/merge_requests/1003. */
    contentKey?: string;
    onClose?: () => void;
    /** Temporarily removed due to a bug, see: https://gitlab.com/Cimpress-Technology/DocDesign/designexperience/design-stack/-/merge_requests/1003. */
    onTransitionEnd?: (endHeight: number) => void;
    isOpen: boolean;
    closeButtonText: string;
    initialPositionY?: number;
    positionY?: number;
    autoSizeOnOpen?: boolean;
    /** Temporarily removed due to a bug, see: https://gitlab.com/Cimpress-Technology/DocDesign/designexperience/design-stack/-/merge_requests/1003. */
    pushContent?: boolean;
    onClickOutside?: () => void;
}

function getDrawerStyle(shouldAnimate: boolean) {
    return css`
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        display: flex;
        flex-direction: column;
        background-color: white;

        ${shouldAnimate &&
        `
            transition: height .2s ease-in-out;
        `}

        touch-action: none;
        -webkit-overflow-scrolling: none;
        overflow: hidden;
        overscroll-behavior: none;
        pointer-events: auto;
        box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
    `;
}

const optionBarStyle = css`
    position: relative;
`;

const contentStyle = css`
    overflow: hidden;
    padding: ${CONTENT_PADDING}px;
    flex: 1;
`;

const doneButtonStyle = css`
    position: absolute;
    right: 1em;
    padding: 0;
`;

const dragHandleIconStyle = css`
    border-top: 2px solid ${cvar('primaryColor')};
    border-bottom: 2px solid ${cvar('primaryColor')};
    border-left: 0;
    border-right: 0;
    background-color: transparent;
    cursor: grab;
    height: 0.35rem;
    width: 5%;
    margin: 0 auto 20%;
`;

const titleStyle = css`
    margin-left: 14px;
    color: #3a414c;
    font-size: 14px;
    letter-spacing: 0;
`;

function getViewportHeight() {
    return document.documentElement.clientHeight;
}

const getContentHeight = (divElement: HTMLDivElement) => {
    let height = CONTENT_PADDING * 2; // padding of content container - top and bottom

    if (divElement.children) {
        for (let i = 0; i < divElement.children.length; i++) {
            height += divElement.children[i].getBoundingClientRect().height;
        }
    }
    return height;
};

export function Drawer({
    title,
    initialPositionY = getViewportHeight() / 1.5,
    positionY = undefined,
    autoSizeOnOpen = false,
    onClose = () => {},
    children,
    isOpen,
    closeButtonText,
    onClickOutside: onClickOutsideCallback = () => {},
    ...props
}: DrawerProps) {
    if ('contentKey' in props) {
        // eslint-disable-next-line no-console
        console.warn(
            '[Drawer] WARNING! `contentKey` has been removed temporarily due to a bug.' +
                'See: https://gitlab.com/Cimpress-Technology/DocDesign/designexperience/design-stack/-/merge_requests/1003.',
        );
    }

    if ('onTransitionEnd' in props) {
        // eslint-disable-next-line no-console
        console.warn(
            '[Drawer] WARNING! `onTransitionEnd` has been removed temporarily due to a bug.' +
                'See: https://gitlab.com/Cimpress-Technology/DocDesign/designexperience/design-stack/-/merge_requests/1003.',
        );
    }

    if ('pushContent' in props) {
        // eslint-disable-next-line no-console
        console.warn(
            '[Drawer] WARNING! `pushContent` has been removed temporarily due to a bug.' +
                'See: https://gitlab.com/Cimpress-Technology/DocDesign/designexperience/design-stack/-/merge_requests/1003.',
        );
    }

    const parentRef = useRef<HTMLDivElement>(null);
    const dragRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const [shouldClose, setShouldClose] = useState(false);
    const [shouldAnimate, setShouldAnimate] = useState(true);
    const [isDraggable, setIsDraggable] = useState(false);
    const [dragStart, setDragStart] = useState(0);
    const [dragPosition, setDragPosition] = useState(() => getViewportHeight());

    const portalTarget = document.getElementById(portalTargetId) ?? document.body;

    useEffect(() => {
        const viewportHeight = getViewportHeight();
        let yPosition = isOpen ? initialPositionY : viewportHeight;
        if (isOpen && autoSizeOnOpen && contentRef.current && headerRef.current) {
            yPosition =
                viewportHeight -
                getContentHeight(contentRef.current) -
                headerRef.current.getBoundingClientRect().height;
        }
        setDragPosition(yPosition);
    }, [initialPositionY, autoSizeOnOpen, isOpen]);

    useEffect(() => {
        if (positionY !== undefined) {
            setDragPosition(positionY);
        }
    }, [positionY]);

    useClickOutside({ elementRef: parentRef, shouldAddEventListener: isOpen }, onClickOutsideCallback);

    const handleCloseDrawer = () => {
        setDragPosition(getViewportHeight());
        setShouldAnimate(true);
        setShouldClose(true);
        onClose();
    };

    const handleStartDrag = (event: PointerEvent<HTMLElement>) => {
        setIsDraggable(true);
        dragRef.current?.setPointerCapture(event.pointerId);

        if (isDraggable) return;

        const { clientY } = event;
        setDragStart(clientY);
        setShouldAnimate(false);
    };

    const handleDrag = (e: PointerEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const offset = 30;
        const { clientY } = e;
        setDragPosition(dragStart - (dragStart - clientY) - offset);
    };

    const handleStopDrag = (e: PointerEvent<HTMLElement>) => {
        setIsDraggable(false);
        dragRef.current?.releasePointerCapture(e.pointerId);

        // Transition the drawer to fullscreen when the drag position is over 33% of the viewport height
        if (dragPosition < getViewportHeight() / 3) {
            setDragPosition(0);
            setShouldAnimate(true);
        }
        // Transition the drawer to close when the drawer is less then 33% of the initial Y position
        if (getViewportHeight() - dragPosition < initialPositionY / 3) {
            handleCloseDrawer();
        }
    };

    const handleTransitionEnd = () => {
        // Wait for the transition to end before unmounting this component
        if (shouldClose) {
            setShouldClose(false);
        }
    };

    // NOTE: Using inline styling to prevent the css of the component to be generated too often by emotion.
    const drawer = (
        <section
            ref={parentRef}
            data-testid="dsc-drawer"
            className={cx('dsc-drawer', getDrawerStyle(shouldAnimate))}
            onTransitionEnd={handleTransitionEnd}
            style={{
                height: `${(getViewportHeight() - dragPosition).toFixed(2)}px`,
                cursor: `${isDraggable ? 'grabbing' : ''}`,
            }}
        >
            <section className={cx('dsc-drawer-header', optionBarStyle)} ref={headerRef}>
                <span className={cx('dsc-drawer-header--title', titleStyle)}>{title}</span>
                {!autoSizeOnOpen && ( // We don't need the drag handle if it is sized to have all content visible
                    <DragHandle
                        ref={dragRef}
                        onPointerDown={handleStartDrag}
                        onPointerMove={isDraggable ? handleDrag : undefined}
                        onPointerUp={handleStopDrag}
                    >
                        <button className={dragHandleIconStyle}></button>
                    </DragHandle>
                )}
                <Button
                    data-testid="dsc-drawer-close-button"
                    className={cx('dsc-drawer-close-button', doneButtonStyle)}
                    variant="link"
                    onClick={handleCloseDrawer}
                >
                    {closeButtonText}
                </Button>
            </section>

            <section className={cx('dsc-drawer-content', contentStyle)} ref={contentRef}>
                {children}
            </section>
        </section>
    );

    return isOpen ? createPortal(drawer, portalTarget) : null;
}
