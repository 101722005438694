export const LIMIT = 100;
export const SEARCHOFFSET = 0;

export const CONSTANT = {
    depositPhoto: 'depositPhoto',
    shutterStock: 'shutterStock',
    limit: 'limit',
    success: 'success',
    failure: 'failure',
    searchKey: 'nature',
    result: 'result',
    active: 'active',
    count: 'count',
    category: 'category',
    httpSuccess: {
        statusText: 'success',
        statusCode: 200,
    },
    httpFailure: {
        statusText: 'failure',
        statusCode: 101,
    },
    pagination: 'pagination',
};

export const DEFAULT_CONFIG = {
    providers: {
        [`${CONSTANT.depositPhoto}`]: {
            limit: LIMIT,
            pagination: {
                next: '',
                prev: '',
                offset: 0,
            },
            category: false,
            filter: {
                color: '',
                orientation: '',
                people: 'all',
            },
            downloadConfig: {
                hdImageDownloadAPI: 'https://dexter-dev.ecomm.pixartprinting.net/api/v1/getMedia',
                imageQuality: 'xl-2015',
            },
        },
    },
    keywords: CONSTANT.searchKey,
    category: '',
    language: 'en',
};
