import type { MouseEvent } from 'react';
import React, { useState } from 'react';

import { cx } from '@emotion/css';

import { useBreakpointInfo } from '@design-stack-ct/utility-react';

import { Button, cvar } from '@dexter/dex-component-library';
import { Icon, useIcons } from '@dexter/dex-icon-library';

import {
    ensembleBottomStyle,
    ensembleColorSelectedStyle,
    ensembleColorsStyle,
    ensembleColorStyle,
    ensemblesContainerStyle,
    ensembleTopStyle,
} from './EnsemblesThumbnailCss';
import { buttonStyle } from './GalleryCss';
import { ModalWrapper } from './ModalWrapper';
import { ENSEMBLE_COLOR_GAP_SIZE, ENSEMBLE_GALLERY_PREFIX, TIME_LIMIT } from '../constants';
import type { EnsemblesProps, EnsemblesThumbnailProps } from '../types';

export const EnsemblesThumbnail = ({
    iconContent,
    ensembles,
    onEnsembleClick,
    proportion = 1,
    selectedEnsembleId,
    clickedTemplateId,
    setClickedTemplateId,
    onAdd,
    setShowLoader,
    isLoaded,
    title,
    subTitle,
}: EnsemblesThumbnailProps) => {
    const { PIXART_ARROW_MAXIMIZE } = useIcons();
    const [showModal, setShowModal] = React.useState(false);
    const { isSmallDevice } = useBreakpointInfo();
    const [lastClickedTimestamp, setLastClickedTimestamp] = useState<number>();
    const [showLoaderModal, setShowLoaderModal] = useState(false);

    let visibleEnsemble = 0;

    if (proportion < 0.5) {
        visibleEnsemble = isSmallDevice ? 2 : 3;
    } else if (proportion > 2) {
        visibleEnsemble = 6;
    } else {
        visibleEnsemble = isSmallDevice ? 4 : 5;
    }

    const numberEnsemble = ensembles ? Object.keys(ensembles).length : 0;
    const stopIndex = numberEnsemble > visibleEnsemble ? visibleEnsemble - 1 : numberEnsemble;

    const handleLoader = (item: EnsemblesProps) => {
        if (selectedEnsembleId === item.ensembleId && isLoaded) {
            setShowLoader?.(false);
        } else {
            setShowLoader?.(true);
        }
    };

    const ensemblesInfo =
        ensembles &&
        Object.values(ensembles).map(
            (item, index) =>
                item &&
                index < stopIndex && (
                    <div
                        className={cx(`${ENSEMBLE_GALLERY_PREFIX}-color`, ensembleColorStyle(true), {
                            [ensembleColorSelectedStyle]: item.selected,
                        })}
                        key={item.ensembleId}
                        onClick={(event) => {
                            event.stopPropagation();
                            handleLoader?.(item);
                            onEnsembleClick?.(item);
                            if (isSmallDevice) {
                                setClickedTemplateId?.(item.ensembleId);
                            }
                        }}
                    >
                        <div style={{ backgroundColor: `#${item.colorDominance}` }}></div>
                    </div>
                ),
        );

    function onCancel(ensemble?: EnsemblesProps) {
        setShowModal(false);
        if (onEnsembleClick && ensemble && ensemble.ensembleId !== selectedEnsembleId) {
            onEnsembleClick(ensemble);
        }
    }

    const showModalHandler = () => {
        setShowModal(true);
    };

    // Code repetition from Image Thumbnail, could be refactored
    // TODO: Needs to be improved, the timestamp handling could be done in a common function and then be used to handle the two clicks.
    // TODO: An alternative method to handle double clicks on mobile devices could checked.
    const handleClick = (event: MouseEvent) => {
        if (lastClickedTimestamp && event.timeStamp - lastClickedTimestamp <= TIME_LIMIT) {
            onAdd?.(selectedEnsembleId || '', 1);
        } else {
            setLastClickedTimestamp(event.timeStamp);
        }
    };

    return (
        <div
            className={cx(
                `${ENSEMBLE_GALLERY_PREFIX}-container`,
                ensemblesContainerStyle(isSmallDevice, clickedTemplateId === selectedEnsembleId, isLoaded),
            )}
            onClick={handleClick}
        >
            <ModalWrapper
                showModal={showModal}
                onCancel={onCancel}
                ensembles={ensembles}
                proportion={proportion}
                title={title}
                subTitle={subTitle}
                selectedEnsembleId={selectedEnsembleId}
                setShowLoader={setShowLoaderModal}
                showLoader={showLoaderModal}
                onClick={handleClick}
            />
            <div className={cx(`${ENSEMBLE_GALLERY_PREFIX}-top`, ensembleTopStyle)}>
                <Button
                    className={cx(buttonStyle(isLoaded, '#ffffff', `${cvar('primaryColor')}`, '#ffffff'))}
                    onClick={() => {
                        showModalHandler();
                        setShowLoaderModal?.(true);
                    }}
                >
                    <Icon content={PIXART_ARROW_MAXIMIZE.icon} size="extra-small" />
                </Button>
            </div>
            <div className={cx(`${ENSEMBLE_GALLERY_PREFIX}-bottom`, ensembleBottomStyle)}>
                <div className={cx(`${ENSEMBLE_GALLERY_PREFIX}-colors`, ensembleColorsStyle(ENSEMBLE_COLOR_GAP_SIZE))}>
                    {ensemblesInfo}
                    {numberEnsemble > visibleEnsemble && (
                        <div
                            onClick={() => showModalHandler()}
                            className={cx(`${ENSEMBLE_GALLERY_PREFIX}-color`, ensembleColorStyle(false))}
                        >{`+${numberEnsemble - (visibleEnsemble - 1)}`}</div>
                    )}
                </div>
                {iconContent}
            </div>
        </div>
    );
};
