import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { sliderRailSize, sliderTooltipClassName } from './config';
import { cvar } from '../theme';

interface SliderHandleProps {
    disabled?: boolean;
    isActive: boolean;
    isHorizontal: boolean;
}

const activeStyles = () => css`
    cursor: grabbing;
    &:before {
        opacity: 0.5;
        transform: scale(1);
    }

    &:after {
        border: ${sliderRailSize}px solid ${cvar('primaryColor')};
    }
`;

export const SliderHandle = styled.div<SliderHandleProps>`
    width: 16px;
    height: 16px;
    position: absolute;
    transform: translate(${({ isHorizontal }) => (isHorizontal ? '-50%, 0' : '0, 50%')});
    cursor: grab;
    box-sizing: border-box;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    &:before {
        content: '';
        position: absolute;
        left: -4px;
        top: -4px;
        right: -4px;
        bottom: -4px;
        transition:
            opacity 0.2s,
            transform 0.2s;
        border-radius: 50%;
        pointer-events: none;
        background: radial-gradient(${cvar('primaryColor')}, transparent);
        transform: scale(0.5);
        opacity: 0;
    }

    &:after {
        content: '';
        pointer-events: none;
        position: absolute;
        border-radius: 50%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border: 1px solid ${cvar('primaryColor')};
        background-color: ${({ disabled }) => (disabled ? cvar('disabledLightColor') : cvar('primaryBackgroundColor'))};
        transition:
            background-color 0.2s,
            border 0.2s;
        box-shadow: ${({ disabled }) => (disabled ? 'none' : '0 0 2px 0 rgba(0, 0, 0, 0.25)')};
        background-color: ${cvar('primaryColor')};
    }

    &:focus {
        ${({ disabled }) => !disabled && activeStyles}
    }

    &:hover {
        .${sliderTooltipClassName} {
            opacity: 1;
            visibility: visible;
        }
    }

    ${({ isActive, disabled }) => isActive && !disabled && activeStyles}
`;
