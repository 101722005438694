import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import React from 'react';

import { css,cx } from '@emotion/css';

const h2Style = css`
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: #000000;
`;

export function H2({
    className,
    children,
    ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>) {
    return (
        <h2 className={cx('dsc-h2', h2Style, className)} {...props}>
            {children}
        </h2>
    );
}
