import { css } from '@emotion/css';

import { cvar } from '@dexter/dex-component-library';

export const ensembleLoadingStyle = (isOverlayNeeded?: boolean) => css`
    position: absolute;
    ${isOverlayNeeded ? `top: 50%;` : `top: 40%;`}
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const cardStyle = (isSmallDevice: boolean) => css`
    ${!isSmallDevice &&
    `
    padding: 24px;
    `}
    ${isSmallDevice &&
    `
    width: 100%;

    .dex-cancel-icon{
        padding: 0;
    }
    `}
    .dsc-card__content {
        padding: 0;
    }
`;

export const templateContentStyle = (isSmallDevice: boolean) => css`
    position: relative;
    ${isSmallDevice && `padding: 0 24px;`}
`;

export const headerStyle = css`
    display: flex;
    justify-content: flex-end;
    margin: 24px 24px 12px 24px;
    align-content: flex-start;
    flex-wrap: wrap;
`;

export const bodyStyle = (proportion: number, isSmallDevice: boolean) => css`
    position: relative;
    ${!isSmallDevice &&
    `min-width: ${proportion > 2 ? '600px' : '460px'};
    min-height: 280px;
    margin-bottom: 24px;
    padding: 30px;
    column-gap: 20px;
    `}
    display: flex;
    justify-content: center;
    background: ${cvar('greyColor')};
    border-radius: 8px;
    ${isSmallDevice &&
    `min-height: 206px;
    @media (max-width: 375px) {
        min-height: 100px;
    }
    padding: 20px;
    max-width: 290px;
    margin: 0 auto;
    margin-bottom: 22px;
    .dex-gallery-arrow {
        position: absolute;
        top: calc(50% - 12px);
        background: #fff;
        box-shadow: 0px 0px 5px #d3d3d3;
        border-radius: 50%;
        &.dex-gallery-arrow-left {
            left: -10px;
        }
        &.dex-gallery-arrow-right {
            right: -10px;
        }
        .dsc-icon-button {
            padding: 3px 5px;
            width: 32px;
            height: 32px;
        }
    }
    `}
`;

export const titleContainerStyle = css`
    margin-bottom: 24px;
    text-align: center;
`;

export const titleEnsembleStyle = (isSmallDevice: boolean) => css`
    font-size: ${isSmallDevice ? '20' : '24'}px;
    font-weight: bold;
    margin-bottom: ${isSmallDevice ? '4' : '8'}px;
`;

export const subTitleEnsembleStyle = (isSmallDevice: boolean) => css`
    font-size: ${isSmallDevice ? '12' : '16'}px;
`;

export const imagePreviewStyle = (imageWidth: string, isSmallDevice: boolean) => css`
    ${!isSmallDevice &&
    `
    max-width: ${imageWidth};
    max-height: 350px;

    `}
    ${isSmallDevice &&
    `
    max-width: 250px;
    max-height: 400px;
    @media (max-width: 375px) {
        max-width: 150px;
    }
    `}
`;

export const dexCancelIconStyle = (isSmallDevice: boolean) => css`
    ${!isSmallDevice &&
    `position: absolute;
    top: 0;
    right: 0;
    padding: 1px;`}
    > svg {
        height: 22px;
        width: 22px;
    }
`;
export const applyButtonStyle = css`
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    padding: 16px 32px;
`;

export const footerStyle = (isSmallDevice: boolean) => css`
    display: flex;
    align-items: center;
    ${!isSmallDevice &&
    `
    justify-content: space-between;
    `}
    ${isSmallDevice &&
    `
    flex-direction: column;
    justify-content: center;
    row-gap: 32px;
    margin-bottom: 24px;
    `}
`;
