import type { ReactNode } from 'react';
import React, { useContext } from 'react';

import type { PreviewConfigurationProps } from './previewContext';
import { PreviewConfigurationContext } from './previewContext';

export const PreviewConfigurationProvider = ({
    config,
    children,
}: {
    config: PreviewConfigurationProps;
    children: ReactNode | ReactNode[];
}) => <PreviewConfigurationContext.Provider value={config}> {children} </PreviewConfigurationContext.Provider>;

export const usePreviewConfiguration = () => {
    const previewConfigurationContext = useContext(PreviewConfigurationContext);

    if (!previewConfigurationContext) {
        throw new Error(' Cannot use the usePreviewConfiguration without a PreviewConfigurationContext provider');
    }

    return previewConfigurationContext;
};
