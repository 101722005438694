export enum SelectSize {
    Large = 'Large',
    Medium = 'Medium',
    Small = 'Small',
}

export function getSelectSizeInPixels(size: SelectSize) {
    switch (size) {
        case SelectSize.Large:
            return '48px';
        case SelectSize.Medium:
            return '38px';
        case SelectSize.Small:
            return '28px';
        default:
            return '38px';
    }
}

export function getOptionSizeInPixels(size: SelectSize) {
    switch (size) {
        case SelectSize.Large:
            return '48px';
        case SelectSize.Medium:
            return '38px';
        case SelectSize.Small:
            return '28px';
        default:
            return '38px';
    }
}
