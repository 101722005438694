export const SCENE = 'scene';
export const VORTEX = 'vortex';
export const PURPOSE_DESIGN = 'design';
export const PURPOSE_MERCHANDISING = 'merchandising';
export const PURPOSE_PRODUCT = 'product';
export const SUB_PURPOSE_PANEL = 'panel';
export const SUB_PURPOSE_TEMPLATE_GALLERY = 'template-gallery';
export const SUB_PURPOSE_FULL = 'full';
export const TYPE_PHOTOGRAPHIC = 'photographic';
export const SCENE_TYPE_UNDERLAY = 'underlay';
export const SCENE_TYPE_OVERLAY = 'overlay';
export const SCENE_TYPE_BUTTON = 'button';
export const SCENE_TYPE_THUMBNAIL = 'thumbnail';
export const SUB_PURPOSE_PRODUCT_TEMPLATE = 'product_template';
export const UNDERLAY_URL_KEY_NAME = 'underlayUrl';
export const OVERLAY_URL_KEY_NAME = 'overlayUrl';
export const DEXTER = 'dexter';
