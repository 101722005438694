import { uniqueId } from 'lodash';
import React, { useMemo } from 'react';

import { css, cx } from '@emotion/css';

import { CollapsibleBody } from './CollapsibleBody';
import { CollapsibleHeader } from './CollapsibleHeader';
import { CollapsibleItemProvider } from './CollapsibleItemContext';
import type { CollapsibleItemProps } from './types';
import { cvar } from '../theme';

const collapsibleItemStyle = css`
    border-bottom: 1px solid ${cvar('disabledLightColor')};

    &:first-child {
        border-top: 1px solid ${cvar('disabledLightColor')};
    }
`;

function CollapsibleItem({ children, className, id, onToggle, isOpen }: CollapsibleItemProps) {
    const collapsibleId = useMemo(() => id || uniqueId(), [id]);
    return (
        <CollapsibleItemProvider id={collapsibleId} isOpen={isOpen} onToggle={onToggle}>
            <li className={cx(className, 'dsc-collapsible-item', collapsibleItemStyle)}>{children}</li>
        </CollapsibleItemProvider>
    );
}

CollapsibleItem.Header = CollapsibleHeader;
CollapsibleItem.Body = CollapsibleBody;

export { CollapsibleItem };
