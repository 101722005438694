import { css } from '@emotion/css';

/**
 * Screen readers may skip elements with display: none, visibility: hidden, or opacity: 0, so we use this style to make it invisible to sighted users
 * while still being accessible to those using assistive devices.
 * See https://webaim.org/techniques/css/invisiblecontent/
 * Sourced from Angular Material CDK https://github.com/angular/components/blob/6b7f091ebdabb8dbb413a446afec996e73469185/src/cdk/a11y/_a11y.scss#L2
 */
export const visuallyHiddenStyle = css`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
`;
