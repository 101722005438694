import React from 'react';

import { css } from '@emotion/css';

import FusionParams from './FusionParams';

const baseStyle = css`
    background-color: #fff;
    padding: 50px;
    margin: 30px;
    height: auto;
    code {
        background: #f5f6f7;
        word-wrap: break-word;
    }

    h2 {
        font-size: 24px;
    }

    .code-block {
        display: block;
        white-space: pre-wrap;
        text-align: left;
    }

    .table-style {
        border-spacing: 0;

        td,
        th {
            padding: 10px 15px;
            border-bottom: 2px solid #606060;
        }

        tr:nth-of-type(even) {
            background-color: #eff3f6;
        }

        th {
            font-weight: bold;
        }
    }
`;

export default function PreviewDocument() {
    return (
        <div className={baseStyle}>
            <h1>Fusion Preview Documentation</h1>
            <p>
                Fusion Preview package helps to show preview with 3D in realtime. This documentation will help you to
                integrate fusion preview package easily.
            </p>
            <FusionParams />
        </div>
    );
}
