import React, { useMemo } from 'react';

import { useTextToImage } from '@dexter/image-generation-sdk';

// TODO: We should update the appropriate type here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function TextToImage(data: any) {
    const searchQuery = data.search;
    const { accessToken } = data;
    const submitCount = data.count;
    const searchData = useMemo(
        () => ({
            searchQuery,
            submitCount,
        }),
        [searchQuery, submitCount],
    );
    const { loading, result } = useTextToImage(searchData, true, accessToken);

    if (loading) {
        return <div>Loading...</div>;
    }
    return (
        <>
            {result && (
                <div>
                    <pre style={{ overflow: 'auto' }}>{JSON.stringify(result, undefined, 2)}</pre>
                </div>
            )}
        </>
    );
}
