import React from 'react';

import { css } from '@emotion/css';

import { Icon } from '@dexter/dex-icon-library';

import type { ContentTab } from './types';
import { ButtonToggle, ButtonToggleGroup } from '../buttonToggleGroup';
import { Drawer } from '../drawer';

const itemContentTabsStyle = css`
    pointer-events: auto;
    padding-bottom: 10px;

    .dsc-button-toggle-group--horizontal {
        flex: 1;
        justify-content: space-between;
        align-items: center;
    }

    .dsc-button-toggle {
        padding: 8px;
    }
`;

const parentContainer = css`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const contentContainer = css`
    flex: 1;
    overflow: hidden;
`;

export interface TabbedDrawerProps {
    tabs: ContentTab[];
    activeTabId?: string;
    showContentDrawer: boolean;
    closeButtonText?: string;
    defaultTitle?: string;
    positionY?: number;
    /** Temporarily removed due to a bug, see: https://gitlab.com/Cimpress-Technology/DocDesign/designexperience/design-stack/-/merge_requests/1003. */
    selectedLayoutGroup?: string;
    onTabSelect: (tabId?: string) => void;
    onClose: () => void;
    /** Temporarily removed due to a bug, see: https://gitlab.com/Cimpress-Technology/DocDesign/designexperience/design-stack/-/merge_requests/1003. */
    onTransitionEnd?: (endHeight: number) => void;
}

export function TabbedDrawer({
    tabs,
    activeTabId,
    showContentDrawer,
    closeButtonText = 'Close',
    defaultTitle = 'Add Content',
    positionY,
    onTabSelect,
    onClose,
    ...props
}: TabbedDrawerProps) {
    if ('selectedLayoutGroup' in props) {
        // eslint-disable-next-line no-console
        console.warn(
            '[TabbedDrawer] WARNING! `selectedLayoutGroup` has been removed temporarily due to a bug.' +
                'See: https://gitlab.com/Cimpress-Technology/DocDesign/designexperience/design-stack/-/merge_requests/1003.',
        );
    }

    if ('onTransitionEnd' in props) {
        // eslint-disable-next-line no-console
        console.warn(
            '[TabbedDrawer] WARNING! `onTransitionEnd` has been removed temporarily due to a bug.' +
                'See: https://gitlab.com/Cimpress-Technology/DocDesign/designexperience/design-stack/-/merge_requests/1003.',
        );
    }

    const selectedTab = tabs.find((tab) => tab.id === activeTabId);
    const Tab = selectedTab?.Tab;
    const tabProps = selectedTab?.tabProps;

    return (
        <Drawer
            title={selectedTab?.title || defaultTitle}
            isOpen={showContentDrawer}
            onClose={onClose}
            closeButtonText={closeButtonText}
            positionY={positionY}
        >
            <div className={parentContainer}>
                {tabs.length ? (
                    <section className={itemContentTabsStyle}>
                        <ButtonToggleGroup
                            value={activeTabId ?? undefined}
                            onChange={(value: string) => onTabSelect(value)}
                            orientation="horizontal"
                        >
                            {tabs.map((contentTab) => (
                                <ButtonToggle key={contentTab.id} id={contentTab.id} onClick={contentTab.onClick}>
                                    <Icon content={contentTab.icon} />
                                </ButtonToggle>
                            ))}
                        </ButtonToggleGroup>
                    </section>
                ) : null}
                <div className={contentContainer}>{Tab ? <Tab {...(tabProps ?? {})} /> : null}</div>
            </div>
        </Drawer>
    );
}
