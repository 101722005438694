import type { ReactNode } from 'react';
import React, { useContext } from 'react';

import { LookUpServiceContext } from './lookupServiceContext';
import type { LookupServiceManager } from '../store/LookUpServiceManager';

export const LookUpServiceProvider = ({
    lookupServiceManager,
    children,
}: {
    lookupServiceManager: LookupServiceManager;
    children: ReactNode | ReactNode[];
}) => <LookUpServiceContext.Provider value={lookupServiceManager}> {children} </LookUpServiceContext.Provider>;

export const useLookUpService = () => {
    const lookUpServiceContext = useContext(LookUpServiceContext);

    if (!lookUpServiceContext) {
        throw new Error(' Cannot use the useLookUpService without a LookUpServiceContext provider');
    }

    return lookUpServiceContext;
};
