import { css } from '@emotion/css';

import { BannerType, type BannerVariant } from './types';
import { cvar } from '../theme';

export const bannerVariants: Record<
    BannerVariant,
    { background: string; colorText: string; color: string; border: string }
> = {
    success: {
        background: cvar('backgroundColorSuccessNotify'),
        colorText: cvar('contentDrawerOpacity'),
        color: cvar('iconColorSuccessNotify'),
        border: cvar('borderColorSuccessNotify'),
    },
    info: {
        background: cvar('backgroundColorInfoNotify'),
        colorText: cvar('contentDrawerOpacity'),
        color: cvar('iconColorInfoNotify'),
        border: cvar('borderColorInfoNotify'),
    },
    warning: {
        background: cvar('backgroundColorWarningNotify'),
        colorText: cvar('contentDrawerOpacity'),
        color: cvar('iconColorWarningNotify'),
        border: cvar('borderColorWarningNotify'),
    },
    error: {
        background: cvar('backgroundColorErrorNotify'),
        colorText: cvar('contentDrawerOpacity'),
        color: cvar('iconColorErrorNotify'),
        border: cvar('borderColorErrorNotify'),
    },
};

export const iconStyle = (variant: BannerVariant, bannerType: BannerType) => css`
    ${bannerType === BannerType.notification ? `margin-bottom: 10px;` : `display:flex;`}
    color: ${bannerVariants[variant].color};
`;

export const contentStyle = css`
    font-family: ${cvar('customFontFamily')};
    font-size: 12px;
`;

export const getBannerStyle = (variant: BannerVariant, bannerType: BannerType) => css`
    background-color: ${bannerVariants[variant].background};
    color: ${bannerVariants[variant].colorText};
    border: 1px solid ${bannerVariants[variant].border};
    text-align: center;
    ${bannerType === BannerType.message
        ? `
        display: flex;
        align-items: center;
        gap: 10px;
        `
        : ` box-shadow: 3px 3px 20px #0000001a;`}
`;

export const closeButtonStyle = css`
    background: none;
    border: none;
    outline: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    position: absolute;
    width: 24px;
    top: 6px;
    right: 6px;
`;
