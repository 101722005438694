import { CONSTANT } from '../constants';
import type { CategoryProps, FinalResponse, OutputProps } from '../types';

interface CreateOutputProps {
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    extraData?: {
        category?: boolean;
    };
}

export const processDPData = ({ data, extraData }: CreateOutputProps): OutputProps => {
    //* we will separate the DP code in other file
    const finalData: FinalResponse = {
        providerId: CONSTANT.depositPhoto,
        status: CONSTANT.httpSuccess.statusText,
        statusCode: CONSTANT.httpSuccess.statusCode,
    };

    if (data?.type === CONSTANT.success) {
        const categories: CategoryProps = {};
        const count: number = data?.count;
        // TODO: Remove any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result = data?.result?.map((item: any, index: number) => {
            if (item && item?.status === CONSTANT.active && !item?.blocked) {
                if (extraData?.category && item?.tags && item?.tags?.length > 0) {
                    item.tags.map((element: string) => {
                        if (categories[`${element}`] !== undefined) {
                            categories[`${element}`].push(index);
                        } else {
                            categories[`${element}`] = [index];
                        }
                        return false;
                    });
                }
                return {
                    id: item.id || '',
                    title: item.title || '',
                    description: item.description || '',
                    status: item.status || 'inactive',
                    isFreeItem: item.isFreeItem || '',
                    height: item.height || '',
                    width: item.width || '',
                    views: item.views || 0,
                    downloads: item.downloads || 0,
                    isMobile: item.is_mobile || '',
                    url: item.thumb_max_clean || '',
                    tags: item.tags || [],
                    categories: item.categories || [],
                    originalFileSize: item.original_filesize || '',
                    originalExtension: item.original_extension || '',
                    thumbSmall: item.thumbnail || '',
                    thumbMedium: item.medium_thumbnail || '',
                    thumbLarge: item.large_thumb || '',
                    thumbExtraLarge: item.huge_thumb || '',
                    urlSmall: item.large_thumb || '',
                    urlMedium: item.thumb_huge_clean || '',
                    urlMediumWM: item.thumb_huge || '',
                    urlLarge: item.thumb_max_clean || '',
                    urlLargeWM: item.thumb_max || '',
                };
            }
            return {};
        });
        if (extraData?.category) {
            finalData[`${CONSTANT.result}`] = { images: result, categories };
        } else {
            finalData[`${CONSTANT.result}`] = { images: result };
        }
        finalData[`${CONSTANT.result}`][`${CONSTANT.count}`] = count;
        return finalData;
    }

    //* return error data
    if (data.type === CONSTANT.failure) {
        //* errorMsg : receives error message keyword from api
        //* errorcode : receives error code keyword from api
        //* converting this errorMsg and errorcode into our custom error
        const error: number = data.error.errorCode;
        const { errorMsg } = data.error;
        return {
            providerId: CONSTANT.depositPhoto,
            status: CONSTANT.httpFailure.statusText,
            statusCode: CONSTANT.httpFailure.statusCode,
            error: {
                errorCode: error,
                errorMessage: errorMsg,
            },
        };
    }

    return finalData;
};
