import React, { useCallback, useEffect, useState } from 'react';

import type { VortexAction } from '@rendering/product-preview-react';

import { BACK, EVENT_ACTION_BUTTON_CLICK, FRONT, LOOK_AT_BACK, LOOK_AT_FRONT, PREFIX_CLASS } from '../../constants';
import { usePreviewConfiguration } from '../../providers';
import type { PreviewButtonEvent } from '../../types';

// TODO: Add correct type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const localization = (key: string, object: any) => object?.[key.toLocaleLowerCase()] || key;

export const VortexPreviewActions = ({ actions }: { actions: VortexAction[] }) => {
    const { localization: locale, vortexConfiguration } = usePreviewConfiguration();
    const [activeButton, setActiveButton] = useState(FRONT);

    const handleClick = useCallback(
        (action: VortexAction, index: string) => {
            setActiveButton(index);
            action.onAnimate({
                scaleToFitGeometry: true,
                centerGeometry: true,
                animationDuration: vortexConfiguration?.onAnimate?.animationDuration,
            });

            const clickEvent = new CustomEvent(EVENT_ACTION_BUTTON_CLICK, {
                detail: { id: action.name },
            } as PreviewButtonEvent);

            document.dispatchEvent(clickEvent);
        },
        [vortexConfiguration?.onAnimate?.animationDuration],
    );

    const VortexActiveButtonHandler = (event: Event) => {
        if ((event as CustomEvent).detail.action === LOOK_AT_FRONT) {
            setActiveButton(FRONT);
        }

        // If panel switched to back
        if ((event as CustomEvent).detail.action === LOOK_AT_BACK) {
            setActiveButton(BACK);
        }
    };

    useEffect(() => {
        document.addEventListener('VortexActionEvent', VortexActiveButtonHandler);
        return () => {
            document.removeEventListener('VortexActionEvent', VortexActiveButtonHandler);
        };
    }, []);

    return (
        <>
            {actions.map((action) => {
                if (action.id === 'Unfolded') return null;

                return (
                    <button
                        className={`${PREFIX_CLASS}-action__btn ${action.id === activeButton ? 'active' : ''}`}
                        key={action.id}
                        onClick={() => handleClick(action, action.id)}
                    >
                        {localization(action.name, locale)}
                    </button>
                );
            })}
        </>
    );
};
