import React from 'react';

import { cx } from '@emotion/css';

import { useBreakpointInfo } from '@design-stack-ct/utility-react';
import type { SceneView } from '@rendering/lookup';
import type { FusionPreviewProps } from '@rendering/product-preview-react';
import { FusionPreview } from '@rendering/product-preview-react';

import { LARGE, PREFIX_CLASS, SMALL } from '../../constants';
import { useVortexOptions } from '../../hooks';
import { usePreviewConfiguration } from '../../providers';
import { useCimDoc } from '../../providers/CimDocProvider';
import type { sceneTypeProps } from '../../types';
import { DelayedLoadingSpinner } from '../common';
import { VortexModelLoading } from '../vortex/VortexModelLoading';
import { previewBoxStyle, vortexOuterContainerStyle,vortexStyle } from '../vortex/VortexPreviewCss';

interface ScenePreviewProps {
    type: sceneTypeProps;
    sceneViews: SceneView[];
    className?: string;
    activeButton?: string;
    width?: string;
    height?: string;
    handlePreviewButtonClick?: (buttonId?: string) => void;
}

export const ScenePreview = ({
    type,
    sceneViews,
    activeButton,
    width = '100%',
    height = '100%',
    handlePreviewButtonClick,
}: ScenePreviewProps) => {
    const { vortexConfiguration, previewSettings } = usePreviewConfiguration();
    const { cimDoc } = useCimDoc();
    const { user } = useVortexOptions();
    const { isSmallDevice } = useBreakpointInfo();

    const dimensionThumbnail = isSmallDevice ? '72px' : '88px';

    return (
        previewSettings?.scene &&
        sceneViews.length > 0 &&
        sceneViews.map((scene, index) => {
            const sceneContent = (
                <FusionPreview
                    key={`${type}_${scene.scene.id}_${index}`}
                    user={user}
                    cimDoc={cimDoc as FusionPreviewProps['cimDoc']}
                    view={scene}
                >
                    {({ Canvas, status }) => {
                        const loadingModel = status.loading && !status.error;
                        const classNameVortex =
                            type === SMALL
                                ? vortexStyle('100%', '100%', 'pointer', false)
                                : vortexOuterContainerStyle(width, height);
                        const classNameVortexCanvas =
                            type === SMALL
                                ? vortexStyle('100%', '100%', 'pointer', false)
                                : vortexStyle(width, height, 'default', false);

                        return (
                            <div className={cx(`${PREFIX_CLASS}-vortex`, classNameVortex)}>
                                <div className={cx(`${PREFIX_CLASS}-vortex-canvas`, classNameVortexCanvas)}>
                                    <DelayedLoadingSpinner
                                        loader={loadingModel}
                                        content={vortexConfiguration?.loader}
                                        className={`${PREFIX_CLASS}-loader`}
                                    />
                                    <Canvas style={{ height: '100%' }} />
                                </div>
                                {type === LARGE && <VortexModelLoading loading={loadingModel} />}
                            </div>
                        );
                    }}
                </FusionPreview>
            );

            if (type === SMALL) {
                return (
                    <div
                        key={`p_${scene.scene.id}_${index}`}
                        className={cx(
                            `${PREFIX_CLASS}-thumbnail`,
                            activeButton === scene.scene.id ? 'active' : '',
                            previewBoxStyle(dimensionThumbnail),
                        )}
                        onClick={() => handlePreviewButtonClick?.(scene.scene.id)}
                    >
                        {sceneContent}
                    </div>
                );
            }

            return sceneContent;
        })
    );
};
