import type { CSSProperties,ReactElement } from 'react';
import React, { Children, cloneElement,useRef, useState } from 'react';

import { css,cx } from '@emotion/css';

import { SelectSize } from './common';
import type { OptionProps } from './Option';
import { cvar } from '../theme';

const disabledStyle = css`
    background-color: ${cvar('disabledLightColor')};
    cursor: default;
    color: ${cvar('disabledDarkColor')};
    pointer-events: none;
`;

const panelStyle = css`
    background-color: white;
    color: ${cvar('primaryTextColor')};
    display: inline-block;
    font-family: ${cvar('selectFontFamily')};
    user-select: none;
    height: 100%;
    overflow-y: auto;
`;

const listStyle = css`
    outline: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

export interface ScrollSelectProps<T> {
    /**
     * Use `Option` components as the children for the `ScrollSelect`
     */
    children: ReactElement<OptionProps<T>>[] | ReactElement<OptionProps<T>>;
    onChange?: (value: T) => void;
    value?: T;
    className?: string;
    style?: CSSProperties;
    isDisabled?: boolean;
    size?: SelectSize;
}

export function ScrollSelect<T>({
    children,
    value: valueProp,
    onChange = () => {},
    className,
    style,
    isDisabled = false,
    size = SelectSize.Medium,
}: ScrollSelectProps<T>) {
    const { current: isControlled } = useRef(valueProp !== undefined);
    const [valueState, setValueState] = useState<T | null>(null);

    const value = isControlled ? valueProp : valueState;

    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setNewValue = (newValue: any) => {
        if (!isControlled) {
            setValueState(newValue);
        }

        onChange(newValue);
    };

    const createOptions = (child: ReactElement<OptionProps<T>>) => {
        const selected = child.props.value === value;

        return cloneElement(child, {
            selected,
            // TODO: Remove any
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick: (newValue: any) => {
                if (isDisabled) return;
                setNewValue(newValue);

                if (child.props.onClick) {
                    child.props.onClick(newValue);
                }
            },
            size,
        });
    };

    return (
        <div
            style={style}
            className={cx('dsc-scrollselect__panel', panelStyle, { [disabledStyle]: isDisabled }, className)}
            data-testid="scrollselect-panel"
        >
            <ul tabIndex={-1} className={listStyle}>
                {Children.map(children, createOptions)}
            </ul>
        </div>
    );
}
