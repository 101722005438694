import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { sliderRailSize } from './config';
import { cvar } from '../theme';

interface SliderRailProps {
    isHorizontal: boolean;
    disabled?: boolean;
}

const verticalSliderStyle = css`
    width: 20px;
    height: 100%;

    &:before {
        width: ${sliderRailSize}px;
        height: 100%;
    }
`;

const horizontalSliderStyle = css`
    height: 16px;
    width: 100%;

    &:before {
        height: ${sliderRailSize}px;
        width: 100%;
    }
`;

export const SliderRail = styled.div<SliderRailProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    touch-action: none;
    min-height: 16px;
    min-width: 20px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    position: relative;
    ${({ isHorizontal }) => (isHorizontal ? horizontalSliderStyle : verticalSliderStyle)}

    &:before {
        content: '';
        background-color: ${cvar('primaryBorderColor')};
        background-color: ${({ disabled }) =>
            disabled ? cvar('disabledLightColor') : cvar('selectDropdownBorderColor')};
        border-radius: 2px;
        position: absolute;
        height: ${sliderRailSize}px;
    }
`;
