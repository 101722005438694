import React from 'react';

import { css } from '@emotion/css';

import type { IconSizes } from '@dexter/dex-icon-library';
import { Icon,useIcons } from '@dexter/dex-icon-library';

import type { TextAlignmentLocalization } from './localization';
import { enUS } from './localization';
import { ButtonToggle, ButtonToggleGroup } from '../buttonToggleGroup';
import { Tooltip } from '../tooltip';
import type { IconsProp } from '../types';

interface TextAlignmentIcons extends IconsProp {
    left: {
        icon: string;
    };
    center: {
        icon: string;
    };
    right: {
        icon: string;
    };
}

interface TextAlignmentProps {
    value?: AlignmentDirection;
    onSelect: (value: AlignmentDirection) => void;
    disabled?: boolean;
    size?: IconSizes;
    icons?: TextAlignmentIcons;
    localization?: TextAlignmentLocalization;
}

export enum AlignmentDirection {
    Left = 'Left',
    Center = 'Center',
    Right = 'Right',
}

/**
 * This is an icon's component used to select the text alignment
 */
export function TextAlignment({ value, onSelect, disabled, size, icons, localization = enUS }: TextAlignmentProps) {
    const { TEXT_ALIGNMENT_LEFT, TEXT_ALIGNMENT_CENTER, TEXT_ALIGNMENT_RIGHT } = useIcons();

    return (
        <div className="dsc-textalign" data-testid="dsc-textalign">
            <ButtonToggleGroup variant="icon" value={value} onChange={onSelect}>
                <ButtonToggle
                    id={AlignmentDirection.Left}
                    disabled={disabled}
                    className={css`
                        svg {
                            height: ${size}px;
                            width: ${size}px;
                        }
                    `}
                >
                    <Tooltip content={localization.tooltips.left} placement="bottom">
                        <Icon content={icons?.left.icon ?? TEXT_ALIGNMENT_LEFT.icon} />
                    </Tooltip>
                </ButtonToggle>
                <ButtonToggle
                    id={AlignmentDirection.Center}
                    disabled={disabled}
                    className={css`
                        svg {
                            height: ${size}px;
                            width: ${size}px;
                        }
                    `}
                >
                    <Tooltip content={localization.tooltips.center} placement="bottom">
                        <Icon content={icons?.center.icon ?? TEXT_ALIGNMENT_CENTER.icon} />
                    </Tooltip>
                </ButtonToggle>
                <ButtonToggle
                    id={AlignmentDirection.Right}
                    disabled={disabled}
                    className={css`
                        svg {
                            height: ${size}px;
                            width: ${size}px;
                        }
                    `}
                >
                    <Tooltip content={localization.tooltips.right} placement="bottom">
                        <Icon content={icons?.right.icon ?? TEXT_ALIGNMENT_RIGHT.icon} />
                    </Tooltip>
                </ButtonToggle>
            </ButtonToggleGroup>
        </div>
    );
}
