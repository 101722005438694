import type { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';
import React, { useEffect,useRef } from 'react';

import { css, cx } from '@emotion/css';

import { cvar } from '../theme';

export enum CheckboxSize {
    Large = 'Large',
    Medium = 'Medium',
    Small = 'Small',
}

export interface CheckboxProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    checked: boolean;
    indeterminate?: boolean;
    onToggle: (isChecked: boolean) => void;
    label?: ReactNode | string;
    size?: CheckboxSize;
}

const getCheckboxSizeInPixel = (size: CheckboxSize) => {
    switch (size) {
        case CheckboxSize.Large:
            return 40;
        case CheckboxSize.Medium:
            return 30;
        case CheckboxSize.Small:
            return 25;
        default:
            return 30;
    }
};

const getContainerStyle = (size: CheckboxSize) => {
    const sizeInPixel = getCheckboxSizeInPixel(size);
    return css`
        display: inline-block;
        position: relative;
        height: ${sizeInPixel}px;

        /* Hide the browser's default checkbox */
        input {
            position: absolute;
            clip: rect(1px, 1px, 1px, 1px);
            padding: 0;
            border: 0;
            height: 1px;
            width: 1px;
            overflow: hidden;
        }

        /* Create a custom checkbox */
        .dsc-checkbox-checkmark {
            margin: 3px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: ${sizeInPixel - 10}px;
            width: ${sizeInPixel - 10}px;
            box-sizing: border-box;
            border: solid 1px ${cvar('primaryBorderColor')};
            border-radius: 2px;
        }

        /* On mouse-over, add a light background color */
        :hover input ~ .dsc-checkbox-checkmark {
            background-color: ${cvar('primaryHoverLightColor')};
        }

        /* show outline on focus */
        input:focus ~ .dsc-checkbox-checkmark {
            outline: dashed 1px ${cvar('primaryColor')};
            outline-offset: 2px;
        }

        /* When the checkbox is checked or indeterminate, add a primary background */
        input:checked ~ .dsc-checkbox-checkmark,
        input:indeterminate ~ .dsc-checkbox-checkmark {
            background-color: ${cvar('primaryColor')};
            border: solid 1px transparent;
        }

        /* When the checkbox is disabled */
        input[disabled] ~ .dsc-checkbox-checkmark {
            border: solid 1px ${cvar('inactiveBorderColor')};
            background-color: ${cvar('inactiveColor')};
            pointer-events: none;
            cursor: not-allowed;
        }

        /* Create the checkmark/indicator (hidden when not checked and not indeterminate) */
        .dsc-checkbox-checkmark:after {
            content: '';
            position: relative;
            display: none;
        }

        /* Show the checkmark/indicator when checked or indeterminate */
        input:checked ~ .dsc-checkbox-checkmark:after,
        input:indeterminate ~ .dsc-checkbox-checkmark:after {
            display: block;
        }

        /* Style the tick for checkmark/indicator */
        .dsc-checkbox-checkmark:after {
            ${size === CheckboxSize.Small &&
            `
                top: -1px;
                width: 4px;
                height: 8px;
            `}
            ${size === CheckboxSize.Medium &&
            `
                top: -2px;
                width: 5px;
                height: 11px;
            `}
            ${size === CheckboxSize.Large &&
            `
                top: -2px;
                width: 7px;
                height: 18px;
            `}
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }

        /* Override indicator styling for indeterminate state */
        input:indeterminate ~ .dsc-checkbox-checkmark:after {
            top: 0;
            width: 0;
            border-width: 0 2px 0 0;
            transform: rotate(90deg);
        }
    `;
};

const labelStyle = css`
    user-select: none;
    display: flex;
    align-items: center;
`;

export function Checkbox({
    checked,
    indeterminate = false,
    onToggle,
    label,
    size = CheckboxSize.Medium,
    disabled,
    className,
}: CheckboxProps) {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.indeterminate = indeterminate;
        }
    }, [indeterminate]);

    return (
        <div data-testid="dsc-checkbox" className={cx('dsc-checkbox', getContainerStyle(size), className)}>
            <label className={cx('dsc-checkbox__label', labelStyle)}>
                <input
                    ref={inputRef}
                    data-testid="dsc-checkbox-input"
                    className="dsc-checkbox-input"
                    type="checkbox"
                    checked={checked}
                    onChange={() => onToggle(!checked)}
                    disabled={disabled}
                />
                <div data-testid="dsc-checkbox-checkmark" className="dsc-checkbox-checkmark"></div>
                {label}
            </label>
        </div>
    );
}
