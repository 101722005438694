import type { PhotoStoreConfigProps } from '../types';

interface GetServiceDataProps {
    config: PhotoStoreConfigProps;
    provider: string;
    key: string;
}

interface SetServiceDataProps extends GetServiceDataProps {
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    val: any;
}

export function getProviderConfigData({ config, provider, key }: GetServiceDataProps) {
    if (config && config.providers && config.providers[provider as keyof typeof config.providers]) {
        const serviceProvider = config.providers[provider as keyof typeof config.providers];
        return serviceProvider![key as keyof typeof serviceProvider];
    }
    return false;
}

export function setProviderConfigData({ config, provider, key, val }: SetServiceDataProps) {
    const configCopy = { ...config };
    if (configCopy && configCopy.providers && configCopy.providers[provider as keyof typeof configCopy.providers]) {
        configCopy.providers[provider][key] = val;
        return configCopy;
    }
    return config;
}
