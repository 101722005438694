import { isNaN,toNumber } from 'lodash';
import type { KeyboardEvent,PointerEvent } from 'react';
import React, { useLayoutEffect,useRef, useState } from 'react';

import { cx } from '@emotion/css';

import { ARROW_DOWN, ARROW_LEFT, ARROW_RIGHT, ARROW_UP, clamp } from '@design-stack-ct/utility-core';

import {
    sliderClassName,
    sliderDraggingClassName,
    sliderHandleClassName,
    sliderMarkItemClassName,
    sliderMarkValueDataAttr,
    sliderRailClassName,
    sliderTooltipClassName,
    sliderTooltipDefaultConfig,
    sliderVerticalClassName,
} from './config';
import { SliderHandle } from './SliderHandle';
import { SliderMarks } from './SliderMarks';
import { SliderRail } from './SliderRail';
import { SliderRoot } from './SliderRoot';
import { SliderTooltip } from './SliderTooltip';
import { SliderTrack } from './SliderTrack';
import type { Orientation, SliderMarksInterface, SliderRailPosition, SliderTooltipProps } from './types';
import { SliderTooltipPosition } from './types';
import { getNewPositionFromEvent,getPosition } from './utils';
import { useElementDimensions } from '../utility/useElementDimensions';

export interface SliderProps {
    /** Adds a className to the root element of the slider */
    className?: string;
    /** Determines if the component is disabled */
    disabled?: boolean;
    /** Controls marker ticks on the slider */
    marks?: SliderMarksInterface;
    /** Determines the maximum value of the slider @default 100 */
    max?: number;
    /** Determines the minimum value of the slider @default 0 */
    min?: number;
    /** Callback that is fired when the handle has lost focus. */
    onBlur?: () => void;
    /** Fires every time the slider handle is moved */
    onChange?: (value: number) => void;
    /** Callback that is fired when the handle is released. */
    onRelease?: (value: number) => void;
    /**
     * Whether the bar sits vertically or horizontally. Valid values: 'horizontal' | 'vertical'
     * @default 'horizontal'
     */
    orientation?: Orientation;
    /** Determines the startPosition of slider */
    startPosition?: number;
    /** Determines the interval between valid slider values */
    step?: number;
    /** Determines the tabIndex for the slider handle */
    tabIndex?: number;
    /** Configuration of the tooltip */
    tooltip?: Partial<SliderTooltipProps>;
    /** The current value of the slider */
    value: number;
}

export const Slider = ({
    className,
    disabled,
    marks,
    max = 100,
    min = 0,
    onBlur = () => {},
    onChange,
    onRelease,
    orientation = 'horizontal',
    startPosition,
    step,
    tabIndex = 0,
    tooltip = sliderTooltipDefaultConfig,
    value: inputValue,
}: SliderProps) => {
    const [handlePos, setHandlePos] = useState(0);
    const [isActive, setActive] = useState(false);
    const railPosition = useRef<SliderRailPosition>({ left: 0, bottom: 0, top: 0, right: 0 });
    const railRef = useRef<HTMLDivElement>(null);
    const railDimensions = useElementDimensions(railRef);
    const internalSliderValue = useRef(inputValue);
    const isHorizontal = orientation === 'horizontal';
    const railLength = isHorizontal ? railDimensions.width : railDimensions.height;
    const initialPosition = startPosition === undefined ? min : startPosition;
    const tooltipConfig = tooltip && { ...sliderTooltipDefaultConfig, ...tooltip };
    const hasMarks = Boolean(marks && Object.keys(marks).length);

    useLayoutEffect(() => {
        if (!isActive) {
            setHandlePos(getPosition(inputValue, min, max, railLength));
        }
    }, [inputValue, max, min, railRef, isActive, orientation, railLength]);

    const getValueFromEvent = (event: PointerEvent) => {
        const newPosition = getNewPositionFromEvent(event, isHorizontal, railLength, railPosition, railRef);
        const clampedPosition = clamp(newPosition, 0, railLength);
        let newValue = (clampedPosition / railLength) * (max - min) + min;

        if (step) {
            newValue = Math.round((newValue - min) / step) * step + min;
            newValue = clamp(newValue, min, max);
        }

        return newValue;
    };

    function handleOnChange(value: number) {
        internalSliderValue.current = value;
        onChange?.(value);
    }

    function handleOnRelease() {
        onRelease?.(internalSliderValue.current);
    }

    const updatePosition = (position: number) => {
        setHandlePos(getPosition(position, min, max, railLength));

        if (position !== inputValue) {
            handleOnChange(position);
        }
    };

    const onPointerMove = (event: PointerEvent) => {
        const position = getValueFromEvent(event);
        updatePosition(position);
    };

    const onPointerUp = (event: PointerEvent) => {
        if (disabled) return;
        setActive(false);
        if (railRef.current?.releasePointerCapture) {
            railRef.current.releasePointerCapture(event.pointerId);
        }
        handleOnRelease();
    };

    const onPointerDown = (event: PointerEvent) => {
        if (isActive || disabled) {
            return;
        }
        if (railRef.current?.setPointerCapture) {
            railRef.current.setPointerCapture(event.pointerId);
        }
        setActive(true);
        const newRailPosition = railRef.current?.getBoundingClientRect() ?? railPosition.current;
        railPosition.current = newRailPosition;

        if (event.target instanceof HTMLElement && event.target.classList.contains(sliderMarkItemClassName)) {
            const dataAttr = event.target.getAttribute(sliderMarkValueDataAttr);
            const numberValue = toNumber(dataAttr);
            const position = !isNaN(numberValue) ? numberValue : getValueFromEvent(event);
            updatePosition(position);
        } else {
            const position = getValueFromEvent(event);
            updatePosition(position);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (disabled) return;
        let newPosition = handlePos;
        let newValue = inputValue;

        if ((isHorizontal && event.key === ARROW_LEFT) || (!isHorizontal && event.key === ARROW_DOWN)) {
            if (step) {
                newValue = Math.ceil((newValue - min) / step - 1) * step + min;
                newValue = clamp(newValue, min, max);
                newPosition = getPosition(newValue, min, max, railLength);
            } else {
                newPosition -= event.shiftKey ? 5 : 1;
                newPosition = clamp(newPosition, 0, railLength);
                newValue = (newPosition / railLength) * (max - min) + min;
            }
        } else if ((isHorizontal && event.key === ARROW_RIGHT) || (!isHorizontal && event.key === ARROW_UP)) {
            if (step) {
                newValue = Math.floor((newValue - min) / step + 1) * step + min;
                newValue = clamp(newValue, min, max);
                newPosition = getPosition(newValue, min, max, railLength);
            } else {
                newPosition += event.shiftKey ? 5 : 1;
                newPosition = clamp(newPosition, 0, railLength);
                newValue = (newPosition / railLength) * (max - min) + min;
            }
        }

        if (newPosition !== handlePos) {
            event.preventDefault();
            event.stopPropagation();
            setActive(true);
            setHandlePos(newPosition);
            handleOnChange(newValue);
        }
    };

    const handleKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
        if (isActive) {
            event.preventDefault();
            event.stopPropagation();
            setActive(false);
            handleOnRelease();
        }
    };

    return (
        <SliderRoot
            className={cx(
                sliderClassName,
                isActive && sliderDraggingClassName,
                !isHorizontal && sliderVerticalClassName,
                className,
            )}
            data-testid={sliderClassName}
            isHorizontal={isHorizontal}
            hasMarks={hasMarks}
            disabled={disabled}
        >
            <SliderRail
                ref={railRef}
                touch-action="none"
                onPointerDown={onPointerDown}
                onPointerUp={onPointerUp}
                isHorizontal={isHorizontal}
                disabled={disabled}
                className={sliderRailClassName}
                data-testid={sliderRailClassName}
                onPointerMove={isActive ? onPointerMove : undefined}
            >
                {marks && (
                    <SliderMarks
                        startPosition={initialPosition}
                        inputValue={inputValue}
                        marks={marks}
                        disabled={disabled}
                        isHorizontal={isHorizontal}
                        min={min}
                        max={max}
                        getMarkPosition={(position: number) => getPosition(position, min, max, railLength)}
                    />
                )}
                <SliderTrack
                    isHorizontal={isHorizontal}
                    disabled={disabled}
                    startPosition={initialPosition}
                    min={min}
                    max={max}
                    inputValue={inputValue}
                />
                <SliderHandle
                    className={sliderHandleClassName}
                    role="slider"
                    aria-valuemax={max}
                    aria-valuemin={min}
                    aria-valuenow={inputValue}
                    isActive={isActive}
                    isHorizontal={isHorizontal}
                    data-testid={sliderHandleClassName}
                    disabled={disabled}
                    tabIndex={tabIndex}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                    style={{ [isHorizontal ? 'left' : 'bottom']: Math.round(handlePos) }}
                    onBlur={onBlur}
                >
                    {tooltipConfig.isEnabled && (
                        <SliderTooltip
                            isActive={isActive}
                            className={sliderTooltipClassName}
                            position={SliderTooltipPosition[tooltipConfig.position]}
                            data-testid={sliderTooltipClassName}
                        >
                            {tooltipConfig.formatter(inputValue)}
                        </SliderTooltip>
                    )}
                </SliderHandle>
            </SliderRail>
        </SliderRoot>
    );
};
