import type { Localization } from 'localization';
import type { ReactNode } from 'react';
import React, { createContext } from 'react';
import type { EnsemblesProps } from 'types';

export interface GalleryContextProps {
    localization: Localization;
    applyModalButtonClick?: (ensembleId: string) => void;
    onSelectEnsemble?: (ensemble: EnsemblesProps) => void;
}

const defaultValue: GalleryContextProps = {
    localization: {
        pagesCountLabel: '',
        pageCountLabel: '',
        backButton: '',
        allImages: '',
        processingOverlay: '',
        applyEnsembleModalButton: '',
    },
};

export const galleryContext = createContext<GalleryContextProps>(defaultValue);

export const GalleryContextProvider = ({
    context,
    children,
}: {
    context: GalleryContextProps;
    children: ReactNode | ReactNode[];
}) => <galleryContext.Provider value={context}>{children}</galleryContext.Provider>;
