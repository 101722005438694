import React, { useMemo } from 'react';

import { css } from '@emotion/css';

import { Icon, IconSizes, useIcons } from '@dexter/dex-icon-library';

import type { TextFontStylesLocalization } from './localization';
import { enUS } from './localization';
import { ButtonToggle, ButtonToggleGroup } from '../buttonToggleGroup';
import { Tooltip } from '../tooltip';
import type { IconsProp } from '../types';

interface StyleIcons extends IconsProp {
    bold: {
        icon: string;
        name: string;
    };
    italic: {
        icon: string;
        name: string;
    };
    underline: {
        icon: string;
        name: string;
    };
    strikeout: {
        icon: string;
        name: string;
    };
    [key: string]: {
        icon: string;
        name: string;
    };
}

export interface TextFontStylesProps {
    onToggleStyle: (styleId: string, isToggled: boolean) => void;
    icons?: StyleIcons;
    activeStyles?: string[];
    disabledStyles?: string[];
    size?: IconSizes;
    localization?: TextFontStylesLocalization;
}

export function TextFontStyles({
    icons,
    size = IconSizes.Default,
    activeStyles = [],
    disabledStyles = [],
    onToggleStyle,
    localization = enUS,
}: TextFontStylesProps) {
    const { TEXT_BOLD, TEXT_ITALIC, TEXT_STRIKEOUT, TEXT_UNDERLINE } = useIcons();
    /**
     * It's unlikely this would ever change and trigger a rerender, but this default object should only change in the event the underlying
     * icons object does change.
     */
    const defaultIcons = useMemo(
        () => ({
            bold: {
                ...TEXT_BOLD,
            },
            italic: {
                ...TEXT_ITALIC,
            },
            strikeout: {
                ...TEXT_STRIKEOUT,
            },
            underline: {
                ...TEXT_UNDERLINE,
            },
        }),
        [TEXT_BOLD, TEXT_ITALIC, TEXT_STRIKEOUT, TEXT_UNDERLINE],
    ) as StyleIcons;
    const iconMap = icons || defaultIcons;

    const iconsList = Object.keys(iconMap);
    return (
        <div className="dsc-text-font-styles" data-testid="dsc-text-font-styles">
            <ButtonToggleGroup variant="icon" value={activeStyles} onChange={() => {}}>
                {iconsList.map((key) => (
                    <ButtonToggle
                        key={key}
                        id={key}
                        disabled={disabledStyles.includes(key)}
                        onClick={(id) => onToggleStyle(id, !activeStyles.includes(id))}
                        className={css`
                            svg {
                                height: ${size}px;
                                width: ${size}px;
                            }
                        `}
                    >
                        <Tooltip
                            content={localization.tooltips[key as keyof typeof localization.tooltips]}
                            placement="bottom"
                        >
                            <Icon content={iconMap[key].icon} />
                        </Tooltip>
                    </ButtonToggle>
                ))}
            </ButtonToggleGroup>
        </div>
    );
}
