export interface PhotoStoreConfigProps {
    provider: 'depositPhoto' | 'shutterStock';
    limit?: number;
    pagination?: {
        next?: string;
        prev?: string;
    };
    category?: boolean;
    enable?: boolean;
    searchApi?: string;
}

const photoStoreConfig: PhotoStoreConfigProps[] = [
    {
        provider: 'depositPhoto',
        limit: 100,
        pagination: {
            next: '',
            prev: '',
        },
        searchApi: '',
        category: false,
        enable: true,
    },
    {
        provider: 'shutterStock',
        limit: 100,
        pagination: {
            next: '',
            prev: '',
        },
        category: false,
        enable: true,
    },
];

export default photoStoreConfig;
