export interface TextAlignmentLocalization {
    tooltips: {
        left: string;
        center: string;
        right: string;
    };
}

export const enUS: TextAlignmentLocalization = {
    tooltips: {
        left: 'Left',
        center: 'Center',
        right: 'Right',
    },
};
