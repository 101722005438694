import type { ReactNode } from 'react';
import React from 'react';

import { css, cx } from '@emotion/css';

import { Icon, useIcons } from '@dexter/dex-icon-library';

import { inputClassName, inputErrorClassName, inputPrefixClassName, inputSuffixClassName } from './config';
import type { InputIcons } from './types';
import { cvar } from '../theme';
import { Tooltip } from '../tooltip';

export interface InputContainerProps {
    children?: ReactNode;
    className?: string;
    prefixLabel?: string | ReactNode;
    suffixLabel?: string | ReactNode;
    disabled?: boolean;
    validationMessage?: string;
    icons?: InputIcons;
    customBorderColor?: string;
    customBackgroundColor?: string;
    labelOverlayColor?: string;
}

function getBaseStyling(
    disabled?: boolean,
    error?: boolean,
    customBorderColor?: string,
    customBackgroundColor?: string,
) {
    let borderColor = '#8090A2';
    let backgroundColor = cvar('primaryBackgroundColor');

    if (customBorderColor || customBackgroundColor) {
        borderColor = customBorderColor || borderColor;
        backgroundColor = customBackgroundColor || backgroundColor;
    } else if (disabled) {
        borderColor = cvar('disabledDarkColor');
        backgroundColor = cvar('disabledLightColor');
    } else if (error) {
        borderColor = cvar('errorColor');
    }

    return css`
        display: inline-flex;
        height: 26px;
        width: 100%;
        box-sizing: border-box;
        align-items: baseline;
        border: 1px solid ${borderColor};
        border-radius: 8px;
        font-size: 11px;
        background-color: ${backgroundColor};

        &:hover {
            border-color: ${disabled || error ? borderColor : cvar('primaryColorLight')};
        }

        &:focus-within {
            border-color: ${cvar('primaryColor')};
        }

        &.dsc-input--error > .dsc-tooltip__trigger {
            align-self: center;
            margin: 0 8px 0 4px;
            color: ${disabled ? cvar('disabledDarkColor') : borderColor};
        }
    `;
}

function getLabelStyle(disabled?: boolean) {
    return css`
        align-self: stretch;
        display: flex;
        align-items: center;
        line-height: 15px;
        width: auto;
        color: ${disabled ? cvar('disabledDarkColor') : '#8090A2'};
        padding-left: 8px;
    `;
}

function getSuffixStyle(disabled?: boolean, error?: boolean, labelOverlayColor?: string) {
    let backgroundColor = cvar('primaryOverlayColor');

    if (labelOverlayColor) {
        backgroundColor = labelOverlayColor;
    } else if (disabled) {
        backgroundColor = cvar('disabledLightColor');
    } else if (error) {
        backgroundColor = cvar('errorOverlayColor');
    }

    return css`
        padding-right: 20px;
        background-color: ${backgroundColor};
        border-left: 1px solid ${cvar('primaryBorderColor')};
    `;
}

// TODO(#NO_ISSUE) Needs tsdoc comments to enhance the documentation and also mark the component as private since it isn't publicly exposed.

export function InputContainer({
    children,
    className,
    prefixLabel,
    suffixLabel,
    validationMessage,
    disabled,
    customBorderColor,
    customBackgroundColor,
    labelOverlayColor,
    icons,
}: InputContainerProps) {
    const { ALERT_TRIANGLE } = useIcons();
    const hasValidationError = validationMessage != null;
    const classNames = cx(
        inputClassName,
        getBaseStyling(disabled, hasValidationError, customBorderColor, customBackgroundColor),
        hasValidationError && inputErrorClassName,
        className,
    );

    return (
        <div data-testid={inputClassName} className={classNames}>
            {prefixLabel && (
                <label data-testid={inputPrefixClassName} className={cx(inputPrefixClassName, getLabelStyle(disabled))}>
                    {prefixLabel}
                </label>
            )}
            {children}
            {hasValidationError && (
                <Tooltip
                    content={validationMessage || ''}
                    open={validationMessage && !disabled ? undefined : false}
                    placement="top"
                >
                    <Icon content={icons?.toolTip?.icon ?? ALERT_TRIANGLE.icon} size="extra-small" />
                </Tooltip>
            )}
            {suffixLabel && (
                <label
                    data-testid={inputSuffixClassName}
                    className={cx(
                        inputSuffixClassName,
                        getLabelStyle(disabled),
                        getSuffixStyle(disabled, hasValidationError, labelOverlayColor),
                    )}
                >
                    {suffixLabel}
                </label>
            )}
        </div>
    );
}
