import { clamp } from 'lodash';
import React, { Fragment } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { sliderRailSize, sliderTrackClassName } from './config';
import { getPercentageFromRange } from './utils';
import { cvar } from '../theme';

interface TrackProps {
    isHorizontal: boolean;
    disabled?: boolean;
    isReversed: boolean;
}

const verticalSliderStyle = ({ isReversed }: TrackProps) => css`
    width: ${sliderRailSize}px;
    ${isReversed ? 'top' : 'bottom'}: 0px;
`;

const horizontalSliderStyle = ({ isReversed }: TrackProps) => css`
    height: ${sliderRailSize}px;
    ${isReversed ? 'right' : 'left'}: 0px;
`;

const Track = styled.div<TrackProps>`
    touch-action: none;
    pointer-events: none;
    position: absolute;
    background-color: ${({ disabled }) => (disabled ? cvar('disabledDarkColor') : cvar('primaryColor'))};
    border-radius: 2px;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    ${({ isHorizontal }) => (isHorizontal ? horizontalSliderStyle : verticalSliderStyle)}
`;

interface SliderTrackProps {
    disabled?: boolean;
    isHorizontal: boolean;
    min: number;
    max: number;
    startPosition: number;
    inputValue: number;
}

export const SliderTrack = ({ isHorizontal, min, max, startPosition, inputValue, disabled }: SliderTrackProps) => {
    const position = clamp(startPosition, min, max);
    const isMin = position === min;
    const isMax = position === max;
    const isMinOrMax = isMin || isMax;
    const totalAmount = max - min;
    const value = clamp(inputValue, min, max);

    if (isMinOrMax) {
        const isReversed = isMax;
        const sizePercentage = getPercentageFromRange(min, max, value);
        const reversedSizePercentage = 100 - sizePercentage;
        const size = isReversed ? reversedSizePercentage : sizePercentage;
        return (
            <Track
                className={sliderTrackClassName}
                disabled={disabled}
                data-testid={sliderTrackClassName}
                isHorizontal={isHorizontal}
                isReversed={isReversed}
                style={{ [isHorizontal ? 'width' : 'height']: `${Math.round(clamp(size, 0, 100))}%` }}
            />
        );
    }

    const secondHalf = totalAmount / (max - position);
    const secondTrackLength = getPercentageFromRange(position, max, value) / secondHalf;
    const firstTrackLength = secondTrackLength * -1;
    const firstTrackPosition = ((max - position) / totalAmount) * 100;
    const secondTrackPosition = ((position - min) / totalAmount) * 100;

    return (
        <Fragment>
            {value < startPosition && (
                <Track
                    className={sliderTrackClassName}
                    data-testid={sliderTrackClassName}
                    disabled={disabled}
                    isHorizontal={isHorizontal}
                    isReversed={true}
                    style={{
                        [isHorizontal ? 'width' : 'height']: `${Math.round(clamp(firstTrackLength, 0, 100))}%`,
                        [isHorizontal ? 'right' : 'top']: `${Math.round(clamp(firstTrackPosition, 0, 100))}%`,
                    }}
                />
            )}
            {value > startPosition && (
                <Track
                    className={sliderTrackClassName}
                    data-testid={sliderTrackClassName}
                    disabled={disabled}
                    isHorizontal={isHorizontal}
                    isReversed={false}
                    style={{
                        [isHorizontal ? 'width' : 'height']: `${Math.round(clamp(secondTrackLength, 0, 100))}%`,
                        [isHorizontal ? 'left' : 'bottom']: `${Math.round(clamp(secondTrackPosition, 0, 100))}%`,
                    }}
                />
            )}
        </Fragment>
    );
};
