import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';

export interface DelayedRenderProps {
    delayInMilliseconds: number;
    children: ReactNode | ReactNode[] | ((hasElapsed: boolean) => ReactNode | ReactNode[]);
}

export function DelayedRender({ children, delayInMilliseconds }: DelayedRenderProps) {
    const [hasElapsed, setHasElapsed] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setHasElapsed(true), delayInMilliseconds);
        return () => clearTimeout(timer);
    }, [delayInMilliseconds]);

    return typeof children === 'function' ? children(hasElapsed) : <>{hasElapsed && children}</>;
}
