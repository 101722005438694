import type { ReactNode } from 'react';
import React, { createContext, useContext, useState } from 'react';

import type { CollapsibleIconAlignment } from './types';

interface CollapsibleContextInterface {
    activeIds: string[];
    addActiveId: (id: string) => void;
    icon: string;
    iconAlignment: CollapsibleIconAlignment;
    removeActiveId: (id: string) => void;
    toggleActiveId: (id: string) => void;
}

export const CollapsibleContext = createContext<CollapsibleContextInterface | null>(null);

interface CollapsibleProviderProps {
    children: ReactNode;
    isAccordionMode: boolean;
    icon: string;
    iconAlignment: CollapsibleIconAlignment;
    defaultActiveIds: string[];
}

export const CollapsibleProvider = ({
    children,
    isAccordionMode,
    icon,
    iconAlignment,
    defaultActiveIds,
}: CollapsibleProviderProps) => {
    const [activeIds, setActiveIds] = useState(defaultActiveIds);

    function removeActiveId(id: string) {
        return setActiveIds((ids) => ids.filter((activeId) => activeId !== id));
    }

    function addActiveId(id: string) {
        if (!isAccordionMode) {
            return setActiveIds([id]);
        }
        return setActiveIds((ids) => [...ids, id]);
    }

    function toggleActiveId(id: string) {
        if (activeIds.includes(id)) {
            return removeActiveId(id);
        }
        return addActiveId(id);
    }

    return (
        <CollapsibleContext.Provider
            value={{
                activeIds,
                addActiveId,
                icon,
                iconAlignment,
                removeActiveId,
                toggleActiveId,
            }}
        >
            {children}
        </CollapsibleContext.Provider>
    );
};

export const useCollapsibleContext = () => {
    const context = useContext(CollapsibleContext);
    if (!context) {
        throw new Error('CollapsibleContext not defined, please use the CollapsibleProvider component');
    }
    return context;
};
