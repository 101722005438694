import type { ButtonHTMLAttributes,DetailedHTMLProps, ReactNode } from 'react';
import React from 'react';

import { css,cx } from '@emotion/css';

import { isNumber } from '@design-stack-ct/utility-core';

import { IconSizes } from '@dexter/dex-icon-library';

import { cvar } from '../../theme';

export enum HoverStyle {
    border = 'border',
    background = 'background',
    none = 'none',
}

export interface IconButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    children?: ReactNode | ReactNode[];
    size?: number | string;
    active?: boolean;
    isHoverEffectNeeded?: boolean;
    hoverStyle?: HoverStyle;
}

function getButtonStyle(size: string, isHoverEffectNeeded?: boolean, hoverStyle?: HoverStyle) {
    return css`
        background: transparent;
        ${hoverStyle === HoverStyle.border ? `padding: 0;` : `padding: 8px;`}
        font-size: 14px;
        border: 1px solid transparent;
        border-radius: 5px;
        font-weight: 600;
        cursor: pointer;
        vertical-align: middle;
        color: ${cvar('primaryColor')};

        &[disabled] {
            cursor: default;
            svg,
            div,
            span {
                color: ${cvar('inactiveColor')};
            }
        }

        svg {
            height: ${size};
            width: ${size};
            vertical-align: middle;
            color: ${cvar('primaryColor')};
        }

        @media (hover: hover) {
            &:hover:not([disabled]):not(.dsc-icon-button--active) {
                ${isHoverEffectNeeded &&
                hoverStyle === HoverStyle.background &&
                `background-color: ${cvar('primaryOverlayColor')};`}
                ${hoverStyle === HoverStyle.border && `outline:1px solid; outline-color: ${cvar('primaryColor')};`}
                svg,
                div,
                span {
                    color: ${!isHoverEffectNeeded && cvar('primaryHoverColor')};
                }
            }
        }

        &:active:not([disabled]):not(.dsc-icon-button--active) {
            svg,
            div,
            span {
                color: ${cvar('primaryActiveColor')};
            }
        }

        &.dsc-icon-button--active {
            svg,
            div,
            span {
                color: ${cvar('primaryActiveColor')};
            }
        }
    `;
}

export function IconButton({
    children,
    disabled = false,
    active = false,
    size = IconSizes.Default,
    className,
    isHoverEffectNeeded = true,
    hoverStyle = HoverStyle.background,
    ...restOfProps
}: IconButtonProps) {
    const sizeString = isNumber(size) ? `${size}px` : size;
    return (
        <button
            data-testid="dsc-icon-button"
            className={cx(
                'dsc-icon-button',
                getButtonStyle(sizeString, isHoverEffectNeeded, hoverStyle),
                { 'dsc-icon-button--active': active },
                className,
            )}
            disabled={disabled}
            type="button"
            {...restOfProps}
        >
            {children}
        </button>
    );
}
