import React from 'react';

import { cx } from '@emotion/css';

import { Icon, useIcons } from '@dexter/dex-icon-library';

import { closeButtonStyle, contentStyle, getBannerStyle, iconStyle } from './BannerCss';
import type { BannerProps, BannerVariant } from './types';
import { BannerType } from './types';

export const BannerNotificationType = 'DS-BANNER';

const BannerIcon = ({
    icon,
    variant,
    bannerType,
}: {
    icon: string;
    variant: BannerVariant;
    bannerType: BannerType;
}) => {
    if (icon === null) return null;

    return (
        <div className={iconStyle(variant, bannerType)}>
            <Icon content={icon} />
        </div>
    );
};

// TODO: we can create a seperate component that overwrites the styles of banner for messages, i.e BannerMessage
export const Banner = ({
    variant = 'info',
    icons,
    children,
    onDismiss,
    className,
    bannerType = BannerType.notification,
}: BannerProps) => {
    const { INFO_CIRCLE, CLOSE } = useIcons();

    return (
        <div className={cx('dex-banner-notification', getBannerStyle(variant, bannerType), className)}>
            <BannerIcon icon={icons?.variant.icon ?? INFO_CIRCLE.icon} variant={variant} bannerType={bannerType} />
            <div className={contentStyle}>{children}</div>
            {onDismiss && (
                <button className={cx('dex-banner__close', closeButtonStyle)} onClick={onDismiss}>
                    <Icon content={icons?.close?.icon ?? CLOSE.icon} />
                </button>
            )}
        </div>
    );
};
