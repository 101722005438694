import type { ReactElement } from 'react';
import React, { Children, cloneElement } from 'react';

import { css, cx } from '@emotion/css';

import type { ButtonToggleProps } from './ButtonToggle';
import type { ButtonToggleGroupVariant } from './variants';
import { getVariantStyle } from './variants';

export interface ButtonToggleGroupProps {
    children: ReactElement<ButtonToggleProps>[] | ReactElement<ButtonToggleProps>;
    orientation?: 'vertical' | 'horizontal';
    variant?: ButtonToggleGroupVariant;
    scrollable?: boolean;
}

const baseStyle = css`
    display: flex;
`;

const gridStyle = css`
    flex-wrap: wrap;
`;

const verticalStyle = css`
    flex-direction: column;
`;

const horizontalStyle = css`
    flex-direction: row;
`;

const scrollableStyle = css`
    width: 100%;
    height: 100%;
    flex-wrap: nowrap;
`;

function getScrollContainerStyle(isVertical: boolean) {
    return css`
        white-space: nowrap;
        ${isVertical ? 'height' : 'width'}: 100%;
        ${isVertical ? 'overflow-y' : 'overflow-x'}: auto;
        scrollbar-width: thin;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }

        &::-webkit-scrollbar-thumb {
            // custom scrollbar doesn't appear when thumb color is undefined,
            // so this is the default scrollbar color in Chrome
            background-color: #bfbfbf;
        }

        .dsc-button-toggle-group--scrollable {
            ${isVertical ? 'align-items' : 'justify-content'}: stretch;
        }
    `;
}

interface SingleSelectProps {
    value?: string;
    onChange(value: string | null): void;
}

interface MultiSelectProps {
    value: string[];
    onChange(value: string[]): void;
}

export type SingleSelectButtonToggleGroupProps = SingleSelectProps & ButtonToggleGroupProps;
export type MultiSelectBottonToggleGroupProps = MultiSelectProps & ButtonToggleGroupProps;

function isMultiSelect(selectProps: SingleSelectProps | MultiSelectProps): selectProps is MultiSelectProps {
    return Array.isArray(selectProps.value);
}

function convertToInternalSelectProps(selectProps: SingleSelectProps | MultiSelectProps) {
    let commonSelectProps: {
        onSelect: (value: string) => void;
        selectedIds: string[];
    };

    if (isMultiSelect(selectProps)) {
        const currentValue = selectProps.value;
        commonSelectProps = {
            selectedIds: currentValue,
            onSelect: (value: string) => {
                // return currently selected values, if current value includes it then remove it, else include it
                let newValue;
                if (currentValue.includes(value)) {
                    newValue = currentValue.filter((id) => id !== value);
                } else {
                    newValue = [...currentValue, value];
                }

                selectProps.onChange(newValue);
            },
        };
    } else {
        // isSingleSelect
        const currentValue = selectProps.value;
        commonSelectProps = {
            onSelect: (value: string) => {
                let newValue: string | null;
                if (currentValue && currentValue === value) {
                    newValue = null;
                } else {
                    newValue = value;
                }

                selectProps.onChange(newValue);
            },
            selectedIds: currentValue ? [currentValue] : [],
        };
    }

    return commonSelectProps;
}

export function ButtonToggleGroup({
    children,
    orientation = 'horizontal',
    variant = 'default',
    scrollable = false,
    ...selectProps
}: SingleSelectButtonToggleGroupProps | MultiSelectBottonToggleGroupProps) {
    const isVertical = orientation === 'vertical';
    const variantStyle = getVariantStyle(variant, orientation);
    const classes = cx(
        'dsc-button-toggle-group',
        `dsc-button-toggle-group--${orientation}`,
        `dsc-button-toggle-group--${variant}`,
        {
            [`dsc-button-toggle-group--scrollable`]: scrollable,
            [gridStyle]: variant === 'grid',
            [verticalStyle]: isVertical,
            [horizontalStyle]: !isVertical,
            [scrollableStyle]: scrollable,
        },
        baseStyle,
        variantStyle,
    );
    const { selectedIds, onSelect } = convertToInternalSelectProps(selectProps);

    const toggleGroup = (
        <div role="group" className={classes}>
            {Children.map(children, (child) => {
                const childProps = { ...child.props };
                const isSelected = selectedIds.includes(childProps.id);

                return cloneElement(child, {
                    isSelected,
                    ...childProps,
                    onClick: (id: string) => {
                        onSelect(id);
                        if (childProps.onClick) {
                            childProps.onClick(id);
                        }
                    },
                });
            })}
        </div>
    );

    if (scrollable) {
        return (
            <div className={cx('dsc-button-toggle-group__scroll-container', getScrollContainerStyle(isVertical))}>
                {toggleGroup}
            </div>
        );
    }

    return toggleGroup;
}
