import type { SliderTooltipProps } from './types';

export const sliderRailSize = 4;
export const sliderSpacing = 16;

export const sliderClassName = 'dsc-slider';
export const sliderRailClassName = `${sliderClassName}__rail`;
export const sliderTrackClassName = `${sliderClassName}__track`;
export const sliderHandleClassName = `${sliderClassName}__handle`;
export const sliderMarksClassName = `${sliderClassName}__marks`;
export const sliderMarkItemClassName = `${sliderClassName}__mark-item`;
export const sliderTooltipClassName = `${sliderClassName}__tooltip`;
export const sliderDraggingClassName = `${sliderClassName}--dragging`;
export const sliderVerticalClassName = `${sliderClassName}--vertical`;

export const sliderMarkValueDataAttr = 'data-dsc-slider-tickvalue';

export const sliderTooltipDefaultConfig: SliderTooltipProps = {
    isEnabled: true,
    formatter: (value: number) => `${Math.round(value)}`,
    position: 'top',
};
