export interface VariantsProps {
    skuVariables?: Record<string, string | number>;
    sampleName?: string;
}

export interface McpProduct {
    sku: string;
    name?: string;
    variants: VariantsProps[];
}
export const demoProducts: McpProduct[] = [
    {
        sku: 'CIM-QHRHVXRA',
        name: 'Cushion',
        variants: [
            {
                skuVariables: {
                    Accessories: 'Cushion Pads 40 x 40 cm',
                    'Cut Shape': 'Rectangular',
                    Format: '40 x 40 cm',
                    'Printing Layout': 'Front and back side different printing',
                    Substrate: 'Cotton Optic polyester 105gsm',
                },
                sampleName: 'Cushion 40x40 Front and Back Different',
            },
            {
                skuVariables: {
                    Accessories: 'Cushion Pads 40 x 40 cm',
                    'Cut Shape': 'Rectangular',
                    Format: '40 x 40 cm',
                    'Printing Layout': 'Front and back side same printing',
                    Substrate: 'Cotton Optic polyester 105gsm',
                },
                sampleName: 'Cushion 40x40 Front and Back Same',
            },
            {
                skuVariables: {
                    Accessories: 'Cushion Pads 40 x 40 cm',
                    'Cut Shape': 'Rectangular',
                    Format: '40 x 40 cm',
                    'Printing Layout': 'Front side only printing',
                    Substrate: 'Cotton Optic polyester 105gsm',
                },
                sampleName: 'Cushion 40x40 Front Only',
            },
            {
                skuVariables: {
                    Accessories: 'Cushion Pads 50 x 50 cm',
                    'Cut Shape': 'Rectangular',
                    Format: '50 x 50 cm',
                    'Printing Layout': 'Front and back side same printing',
                    Substrate: 'Cotton Optic polyester 105gsm',
                },
                sampleName: 'Cushion 50x50 Front and Back Same',
            },
            {
                skuVariables: {
                    Accessories: 'Cushion Pads 50 x 50 cm',
                    'Cut Shape': 'Rectangular',
                    Format: '50 x 50 cm',
                    'Printing Layout': 'Front and back side different printing',
                    Substrate: 'Cotton Optic polyester 105gsm',
                },
                sampleName: 'Cushion 50x50 Front and Back Different',
            },
            {
                skuVariables: {
                    Accessories: 'Cushion Pads 50 x 50 cm',
                    'Cut Shape': 'Rectangular',
                    Format: '50 x 50 cm',
                    'Printing Layout': 'Front side only printing',
                    Substrate: 'Cotton Optic polyester 105gsm',
                },
                sampleName: 'Cushion 50x50 Front Only',
            },
        ],
    },
    {
        sku: 'CIM-ZQZW7X53',
        name: 'Mug',
        variants: [
            {
                skuVariables: {
                    Colour: '#ffffff',
                    Material: 'Standard',
                    'Design Area': 'Wraparound',
                    'Production Time': '12',
                },
                sampleName: 'Mug White',
            },
            {
                skuVariables: {
                    Colour: '#f75f0d / #ffffff',
                    Material: 'Standard',
                    'Design Area': 'Wraparound',
                    'Production Time': '12',
                },
                sampleName: 'Mug Orange',
            },
            {
                skuVariables: {
                    Colour: '#e496a2 / #ffffff',
                    Material: 'Standard',
                    'Design Area': 'Wraparound',
                    'Production Time': '12',
                },
                sampleName: 'Mug Pink',
            },
            {
                skuVariables: {
                    Colour: '#4a4945 / #ffffff',
                    Material: 'Standard',
                    'Design Area': 'Wraparound',
                    'Production Time': '12',
                },
                sampleName: 'Mug Dark Grey',
            },
            {
                skuVariables: {
                    Colour: '#213f83 / #ffffff',
                    Material: 'Standard',
                    'Design Area': 'Wraparound',
                    'Production Time': '12',
                },
                sampleName: 'Mug Dark Blue',
            },
        ],
    },
    {
        sku: 'CIM-MN5H1K3E',
        name: 'Business Card',
        variants: [
            {
                skuVariables: {
                    Format: '85 x 55 mm',
                    Lamination: 'None',
                    'Printing Layout': 'Front and back side different printing',
                    'Printing Orientation': 'Landscape',
                    Substrate: 'Classic Demimatt 350 gsm',
                    'Rounded Corners': 'No',
                },
                sampleName: 'Business Card 85x55 Front and Back Different',
            },
            {
                skuVariables: {
                    Format: '85 x 55 mm',
                    Lamination: 'None',
                    'Printing Layout': 'Front and back side same printing',
                    'Printing Orientation': 'Landscape',
                    Substrate: 'Classic Demimatt 350 gsm',
                    'Rounded Corners': 'No',
                },
                sampleName: 'Business Card 85x55 Front and Back Same',
            },
        ],
    },
    {
        sku: 'CIM-DQD3QJNP',
        name: 'Business Card Rounded Corner',
        variants: [
            {
                skuVariables: {
                    Format: '85 x 55 mm (Rounded corners)',
                    Lamination: 'None',
                    'Printing Layout': 'Front and back side different printing',
                    'Printing Orientation': 'Landscape',
                    Substrate: 'Classic Demimatt 350 gsm',
                    'Rounded Corners': 'Yes',
                },
                sampleName: 'Business Card 85x55 Front and Back Different',
            },
            {
                skuVariables: {
                    Format: '85 x 55 mm (Rounded corners)',
                    Lamination: 'None',
                    'Printing Layout': 'Front and back side same printing',
                    'Printing Orientation': 'Landscape',
                    Substrate: 'Classic Demimatt 350 gsm',
                    'Rounded Corners': 'Yes',
                },
                sampleName: 'Business Card 85x55 Front and Back Same',
            },
        ],
    },
    {
        sku: 'CIM-CC3TTRGF',
        name: 'Folded Business Card',
        variants: [
            {
                skuVariables: {
                    'Coating Options': 'None',
                    Coating: 'None',
                    DesignExperience: 'true',
                    Format: '170 x 55 mm',
                    Grammage: '350 gsm',
                    Lamination: 'Matt double sided',
                    'Paper Type': 'Classic Demimatt',
                    Paper: 'Laminated Paper Matt front and back',
                    'Printing Layout': 'Front and back side different printing',
                    'Printing Orientation': 'Landscape',
                    'Printing White': 'None',
                },
                sampleName: 'Folded 85x55 Front and Back Different',
            },
        ],
    },
    {
        sku: 'CIM-BGYKTVTC',
        name: 'T-shirt',
        variants: [
            {
                skuVariables: {
                    'Clothing Size': 'S',
                    'Fabric Color': 'White',
                    'Production Time': '96',
                },
                sampleName: 'T-shirt White S',
            },
            {
                skuVariables: {
                    'Clothing Size': '2XL',
                    'Fabric Color': 'Bottle Green',
                    'Production Time': '96',
                },
                sampleName: 'T-shirt Bottle Green 2XL',
            },
            {
                skuVariables: {
                    'Clothing Size': 'M',
                    'Fabric Color': 'White',
                    'Production Time': '96',
                },
                sampleName: 'T-shirt White M',
            },
        ],
    },
    {
        sku: 'CIM-NVX9KVPN',
        name: 'Canvas Print',
        variants: [
            {
                skuVariables: {
                    Accessories: 'None',
                    Format: '30 x 20 cm',
                    Frame: 'None',
                    'Number of Accessories': '0',
                    'Printing Orientation': 'Landscape',
                },
                sampleName: 'Canvas Print 30x20 Landscape',
            },
            {
                skuVariables: {
                    Accessories: 'None',
                    Format: '30 x 30 cm',
                    Frame: 'None',
                    'Number of Accessories': '0',
                    'Printing Orientation': 'Landscape',
                },
                sampleName: 'Canvas Print 30x30 Landscape',
            },
            {
                skuVariables: {
                    Accessories: 'None',
                    Format: '20 x 20 cm',
                    Frame: 'None',
                    'Number of Accessories': '0',
                    'Printing Orientation': 'Landscape',
                },
                sampleName: 'Canvas Print 20x20 Landscape',
            },
            {
                skuVariables: {
                    Accessories: 'None',
                    Format: '40 x 30 cm',
                    Frame: 'None',
                    'Number of Accessories': '0',
                    'Printing Orientation': 'Landscape',
                },
                sampleName: 'Canvas Print 40x30 Landscape',
            },
        ],
    },

    {
        sku: 'CIM-7UCYD4TA',
        name: 'Banners',
        variants: [
            {
                skuVariables: {
                    'Cut Shape': 'Rectangular',
                    Fastener: 'Plastic Grommets',
                    'Fastener Count': '10',
                    'Fastener Disposition': 'Left - Top - Right - Bottom',
                    'Finished Height': '80',
                    'Finished Width': '200',
                    Format: '200 x 80 cm',
                    Panelizing: 'No',
                    'Pole Pocket': 'None',
                    'Printing Layout': 'Front side only printing',
                    'Printing Orientation': 'Landscape',
                    'Reinforced edges': 'Yes',
                    Substrate: 'Extreme Classic PVC 500gsm',
                },
                sampleName: 'Banner 200x80, Landscape, 10 Grommets, Reinforced Edges',
            },
            {
                skuVariables: {
                    'Cut Shape': 'Rectangular',
                    DesignExperience: 'true',
                    'Fastener Count Max': '80',
                    'Fastener Count Min': '4',
                    'Fastener Disposition': 'Left - Top - Right - Bottom',
                    'Fastener Edge Spacing Bottom': '7.05',
                    'Fastener Edge Spacing Left': '7.05',
                    'Fastener Edge Spacing Right': '7.05',
                    'Fastener Edge Spacing Top': '7.05',
                    'Fastener Spacing Interval': '49',
                    'Finished Height': '100',
                    'Finished Width': '300',
                    ForcedReview: 'false',
                    Format: '300 x 100 cm',
                    Panelizing: 'No',
                    'Pole Pocket': 'None',
                    'Printing Layout': 'Front side only printing',
                    'Printing Orientation': 'Landscape',
                    'Reinforced edges': 'Yes',
                    Substrate: 'Extreme Classic PVC 500gsm',
                },
                sampleName: 'Banner 300x100, Landscape, 4-80 Grommets, Reinforced Edges',
            },
            {
                skuVariables: {
                    'Cut Shape': 'Rectangular',
                    Fastener: 'Metal Grommets',
                    'Fastener Count': '36',
                    'Fastener Disposition': 'Left - Top - Right - Bottom',
                    'Finished Height': '400',
                    'Finished Width': '500',
                    Format: 'Custom',
                    Panelizing: 'Yes',
                    'Pole Pocket': 'None',
                    'Printing Layout': 'Front side only printing',
                    'Printing Orientation': 'Landscape',
                    'Reinforced edges': 'Yes',
                    Substrate: 'Extreme Classic PVC 500gsm',
                },
                sampleName: 'Banner 500x400, Landscape, 36 Grommets, Reinforced Edges',
            },
            {
                skuVariables: {
                    'Cut Shape': 'Rectangular',
                    Fastener: 'Plastic Grommets',
                    'Fastener Count': '20',
                    'Fastener Disposition': 'Left - Top - Right - Bottom',
                    'Finished Height': '200',
                    'Finished Width': '300',
                    Format: '300 x 200 cm',
                    Panelizing: 'No',
                    'Pole Pocket': 'None',
                    'Printing Layout': 'Front side only printing',
                    'Printing Orientation': 'Landscape',
                    'Reinforced edges': 'Yes',
                    Substrate: 'Extreme Classic PVC 500gsm',
                },
                sampleName: 'Banner 300x200, Landscape, 20 Grommets, Reinforced Edges',
            },
            {
                skuVariables: {
                    'Printing Orientation': 'Landscape',
                    'Format Type': '300 x 100 cm',
                    'Finished Width': '300',
                    'Finished Height': '100',
                    'Cut Shape': 'Rectangular',
                    Fastener: 'Plastic Grommets & Black Bungees',
                    'Fastener Disposition': 'Left - Top - Right - Bottom',
                    'Fastener Count': '4',
                    'Reinforced edges': 'Yes',
                    'Pole Pocket': 'None',
                    Substrate: 'Extreme Classic PVC 340gsm - 3.2',
                    'Printing Layout': 'Front side only printing',
                    Format: 'Custom',
                    ForcedReview: 'false',
                    DesignExperience: 'true',
                    Panelizing: 'No',
                    'Fastener Count Max': '80',
                    'Fastener Count Min': '4',
                    'Fastener Edge Spacing Top': '7.05',
                    'Fastener Spacing Interval': '195',
                    'Fastener Edge Spacing Left': '7.05',
                    'Fastener Edge Spacing Right': '7.05',
                    'Fastener Edge Spacing Bottom': '7.05',
                    'Production Time': '24',
                },
                sampleName: 'Banner 300x100, Landscape, Custom, Plastic Grommets & Black Bungees',
            },
        ],
    },
    {
        sku: 'CIM-QRHN2VXG',
        name: 'Packaging (Promotional Boxes)',
        variants: [
            {
                skuVariables: {
                    Coating: 'None',
                    'Cut Type': 'Laser + Die Cut',
                    'Die-Cut': 'at_ds_l80xp80xa80',
                    'Internal Cut': 'No',
                    Lamination: 'None',
                    'Nick Position': 'Bottom',
                    'Printing Layout': 'Front side only printing',
                    'Printing White': 'None',
                    Substrate: 'Technodesign 380gsm',
                },
                sampleName: 'Promotional Box 80x80x80, Nick Position Bottom',
            },
            {
                skuVariables: {
                    AssembledDepth: '2',
                    AssembledHeight: '5',
                    AssembledWidth: '2',
                    'Box Depth': '2 cm',
                    'Box Width': '2 cm',
                    Coating: 'None',
                    DesignExperience: 'true',
                    'Die-Cut': 'at_ds_l20xp20xa50',
                    Grammage: '400 gsm',
                    'Internal Cut': 'No',
                    Lamination: 'None',
                    'Paper Type': 'Classic Demimatt',
                    'Printing Layout': 'Front and back side different printing',
                    'Printing White': 'None',
                    Substrate: 'Classic Demimatt 400 gsm',
                    'Sustainability Label': 'PAP 21',
                },
                sampleName: 'Promotional Box 2x2x5, Nick Position Bottom, Front and Back Different',
            },
        ],
    },
    {
        sku: 'CIM-KZMD2E60',
        name: 'Flyers and Leaflets (Folded flyer)',
        variants: [
            {
                skuVariables: {
                    Format: 'A5 (148 x 210 mm)',
                    Fold: 'None',
                    'Printing Orientation': 'Portrait',
                    'Trim Width': '14.8',
                    'Trim Height': '21',
                    'Printing Layout': 'Front and back side different printing',
                    'Paper Type': 'Classic Demimatt',
                    Grammage: '170 gsm',
                    Lamination: 'None',
                    Substrate: 'Classic Demimatt 170 gsm',
                    'Custom panel dimension for Tab Fold': '0',
                },
                sampleName: 'Flyer A5 148x210 Front and Back Different',
            },
            {
                skuVariables: {
                    Format: 'A6 (105 x 148 mm)',
                    Fold: 'None',
                    'Printing Orientation': 'Portrait',
                    'Trim Width': '10.5',
                    'Trim Height': '14.8',
                    'Printing Layout': 'Front and back side different printing',
                    'Paper Type': 'Classic Demimatt',
                    Grammage: '170 gsm',
                    Lamination: 'None',
                    Substrate: 'Classic Demimatt 170 gsm',
                    'Custom panel dimension for Tab Fold': '0',
                },
                sampleName: 'Flyer A6 105x148 Front and Back Different',
            },
            {
                skuVariables: {
                    Format: 'A4 (210 x 297 mm)',
                    Fold: 'None',
                    'Printing Orientation': 'Portrait',
                    'Trim Width': '21',
                    'Trim Height': '29.7',
                    'Printing Layout': 'Front and back side different printing',
                    'Paper Type': 'Classic Demimatt',
                    Grammage: '170 gsm',
                    Lamination: 'None',
                    Substrate: 'Classic Demimatt 170 gsm',
                    'Custom panel dimension for Tab Fold': '0',
                },
                sampleName: 'Flyer A4 210x297 Front and Back Different',
            },
            {
                skuVariables: {
                    'Custom panel dimension for Tab Fold': '0',
                    Fold: 'Tri-fold',
                    ForcedReview: 'true',
                    Format: 'A4 (210 x 297 mm)',
                    Grammage: '170 gsm',
                    Lamination: 'None',
                    'Paper Type': 'Classic Demimatt',
                    'Printing Layout': 'Front and back side different printing',
                    'Printing Orientation': 'Landscape',
                    Substrate: 'Classic Demimatt 170 gsm',
                    'Trim Height': '21',
                    'Trim Width': '29.7',
                },
                sampleName: 'Flyer A4 210x297 Tri-fold Front and Back Different',
            },
            {
                skuVariables: {
                    'Custom panel dimension for Tab Fold': 0,
                    Fold: 'None',
                    ForcedReview: 'false',
                    Format: '100 x 210 mm',
                    Grammage: '170 gsm',
                    Lamination: 'None',
                    'Paper Type': 'Classic Demimatt',
                    'Printing Layout': 'Front and back side different printing',
                    'Printing Orientation': 'Portrait',
                    Substrate: 'Classic Demimatt 170 gsm',
                    'Trim Height': 21,
                    'Trim Width': 10,
                },
                sampleName: 'Flyer 10x21cm portrait',
            },
        ],
    },
    {
        sku: 'CIM-5WP9WUT1',
        name: 'Paper placemats (Table linen)',
        variants: [
            {
                skuVariables: {
                    Format: '420 x 297 mm',
                    Grammage: '90 gsm',
                    'Paper Type': 'Classic Uncoated',
                    'Printing Layout': 'Front side only printing',
                    Substrate: 'Classic Uncoated 90 gsm',
                },
                sampleName: 'Paper placemats 420x297 Front Only',
            },
        ],
    },
    {
        sku: 'CIM-3K9MB3NX',
        name: 'Headed Paper',
        variants: [
            {
                skuVariables: {
                    Format: 'A4 portrait',
                    Grammage: '90 gsm',
                    'Paper Type': 'Classic usomano - Brilliant White uncoated',
                    'Printing Layout': 'Front side only printing',
                    'Printing Orientation': 'Portrait',
                    Substrate: 'Classic usomano - Brilliant White uncoated 90 gsm',
                },
                sampleName: 'Headed Paper A4 Portrait Front Only',
            },
        ],
    },
    {
        sku: 'CIM-8AY66365',
        name: 'Flap Folders',
        variants: [
            {
                skuVariables: {
                    'Coating Options': 'None',
                    Coating: 'None',
                    DesignExperience: 'true',
                    'Die-Cut': 'fia001-a4',
                    'Folder spine': '0.5 cm',
                    ForcedReview: 'false',
                    Format: 'FIA001',
                    Lamination: 'None',
                    'Printing Layout': 'Front side only printing',
                    Substrate: 'Technodesign 380gsm',
                    'Sustainability Label': 'PAP 21',
                },
                sampleName: 'Flap Folder FIA001 A4',
            },
        ],
    },
    {
        sku: 'CIM-FYEWTW3P',
        name: 'Classic Roll Labels',
        variants: [
            {
                skuVariables: {
                    Coating: 'None',
                    DesignExperience: 'true',
                    ForcedReview: 'false',
                    Format: '5x5',
                    'Image Position': '0 Degrees',
                    'Label Gapping': 0.4,
                    Lamination: 'Labellife 3 anti-scratch varnish',
                    'Mirrored Printing': 'None',
                    'Printing white': 'None',
                    'Roll Size': '33 cm',
                    Sets: 1,
                    'Sticker Cut Shape': 'Circle',
                    Substrate: 'Polypropylene deluxe white',
                    'Sustainability Label': 'PP 5',
                    'Trim Height': 5,
                    'Trim Width': 5,
                },
                sampleName: 'Roll Label 5x5 Circle',
            },
        ],
    },
    {
        sku: 'CIM-MQPVVA88',
        name: 'Standard Tags',
        variants: [
            {
                skuVariables: {
                    Coating: 'None',
                    DesignExperience: 'true',
                    ForcedReview: 'false',
                    Format: 'Circle 65 mm',
                    Grammage: '350 gsm',
                    Lamination: 'None',
                    'Paper Type': 'Classic Demimatt',
                    'Printing Layout': 'Front and back side different printing',
                    'Printing Orientation': 'Landscape',
                    Substrate: 'Classic Demimatt 350 gsm',
                    'Sustainability Label': 'PAP 21',
                },
                sampleName: 'Circle 65mm Front and Back Different',
            },
        ],
    },
    {
        sku: 'CIM-4DA91B5U',
        name: 'Rigid Media All Materials',
        variants: [
            {
                skuVariables: {
                    Accessories: 'None',
                    'Cane Orientation': 'None',
                    'Corner Finishing Radius': 1,
                    'Cut Shape': 'Rectangular',
                    DesignExperience: 'true',
                    'Fastener Count Max': 0,
                    'Fastener Count Min': 0,
                    'Fastener Count': 0,
                    'Fastener Disposition': 'None',
                    'Fastener Spacing Interval': 10,
                    Fastener: 'None',
                    ForcedReview: 'false',
                    Format: '50 x 70 cm',
                    'Gift Wrapping': 'None',
                    'Internal Cuts': 'No',
                    'Number of Internal Cuts': '0',
                    Panelizing: 'No',
                    'Printing Layout': 'Front side only printing',
                    'Printing Orientation': 'Portrait',
                    'Printing White': 'None',
                    Substrate: 'Foamex 3mm',
                    'Trim Height': 70,
                    'Trim Width': 50,
                    Varnish: 'None',
                },
                sampleName: 'Rigid Media 50x70 Portrait',
            },
        ],
    },
    {
        sku: 'CIM-84XNKF6Q',
        name: 'Classic Roll-Ups',
        variants: [
            {
                skuVariables: {
                    Assembly: 'Yes',
                    DesignExperience: 'true',
                    Model: 'Roll-Up 85',
                    'Printing Layout': 'Front side only printing',
                    Structure: 'Roll-Up Classic-mono 85',
                    Substrate: 'EcoFlat Grey Back 398 gsm',
                    'VIP workflow': 'Updated',
                },
                sampleName: 'Roll Up 85x200',
            },
        ],
    },
    {
        sku: 'CIM-HMBFWF58',
        name: 'Tote Bags',
        variants: [
            {
                skuVariables: {
                    'Cut Shape': 'Rectangular',
                    Format: '35 x 40 cm',
                    'Printing Layout': 'Front and back side same printing',
                    Substrate: '100% Polyester Fabric 235gsm',
                },
                sampleName: 'Tote Bag 35x40 Front and Back Same',
            },
        ],
    },
    {
        sku: 'CIM-X9UZTX52',
        name: 'sachhe-custom',
        variants: [
            {
                skuVariables: {
                    Accessories: 'string 94 cm',
                    'Cut Shape': 'Rectangular',
                    Format: '23 x 31 cm',
                    'Printing Layout': 'Front and back side different printing',
                    Substrate: '100% Polyester Fabric 235gsm',
                },
                sampleName: 'Sachet 23x31 Front and Back Different',
            },
        ],
    },
    {
        sku: 'CIM-RPX5MPPN',
        name: 'Shopper bag',
        variants: [
            {
                skuVariables: {
                    Accessories: 'Handles White',
                    'Cut Shape': 'Rectangular',
                    Format: '34 x 39 cm',
                    'Printing Layout': 'Front and back side different printing',
                    Substrate: 'Canvas Light 195gsm',
                },
                sampleName: 'Shopper Bag 34x39 Front and Back Different',
            },
        ],
    },
    {
        sku: 'CIM-8YWJMDZQ',
        name: 'Flip lid promotional boxes',
        variants: [
            {
                skuVariables: {
                    AssembledDepth: '7',
                    AssembledHeight: '4',
                    AssembledWidth: '8',
                    'Box Depth': '7 cm',
                    'Box Width': '8 cm',
                    Coating: 'None',
                    DesignExperience: 'true',
                    'Die-Cut': 'cof_l80xp70xa40',
                    'Die-Cut type': 'cof_l80xp70xa40',
                    ForcedReview: 'false',
                    Grammage: '380 gsm',
                    'Internal Cut': 'No',
                    Lamination: 'None',
                    'Paper Type': 'SBS Paperboard',
                    'Printing Layout': 'Front side only printing',
                    'Printing White': 'None',
                    Substrate: 'Technodesign 380gsm',
                    'Sustainability Label': 'PAP 21',
                },
                sampleName: 'Flip Lid Promotional Box 8x7x4, Front Side Only',
            },
        ],
    },
    {
        sku: 'CIM-A09XTT2T',
        name: 'Doypacks',
        variants: [
            {
                skuVariables: {
                    'Bottom Customization': 'None',
                    'Bottom Dimensions': '30+30 mm',
                    DesignExperience: 'true',
                    'Feature Hole Type': 'Yes',
                    'Feature-Closure': 'Yes',
                    'Feature-Hole': 'Yes',
                    Format: '100ml 90x160mm',
                    Lamination: 'None',
                    'Paper Type': 'Multilayer transparent film',
                    'Printing White': 'None',
                    'Printing White Type': 'None',
                    Substrate: 'Multilayer transparent film',
                    'Sustainability Label': 'C/LD-PE 93',
                },
                sampleName: 'Doypack 100ml 90x160mm, No Bottom Customization',
            },
            {
                skuVariables: {
                    'Bottom Customization': 'Yes',
                    'Bottom Dimensions': '30+30 mm',
                    DesignExperience: 'true',
                    'Feature Hole Type': 'None',
                    'Feature-Closure': 'Yes',
                    'Feature-Hole': 'None',
                    Format: '100ml 90x160mm',
                    Lamination: 'None',
                    'Paper Type': 'Multilayer transparent film',
                    'Printing White': 'None',
                    'Printing White Type': 'None',
                    Substrate: 'Multilayer transparent film',
                    'Sustainability Label': 'C/LD-PE 93',
                },
                sampleName: 'Doypack 100ml 90x160mm, Bottom Customization',
            },
        ],
    },
    {
        sku: 'CIM-9B2RHQXC',
        name: 'Desk display',
        variants: [
            {
                skuVariables: {
                    Accessory: 'None',
                    Finish: 'None',
                    Model: 'A4 Singleface',
                    'Model Type': 'A4 Singleface',
                    'Number of accessories': 'None',
                    'Number of frame': '1',
                    Orientation: 'Portrait',
                    Size: '21x29.7x11 cm',
                    Substrate: 'Foamex 3mm',
                    'Sustainability Label': 'None',
                },
                sampleName: 'Desk Display A4 Single Face',
            },
            {
                skuVariables: {
                    Accessory: 'None',
                    Finish: 'None',
                    'Model Type': 'A4 doubleface',
                    Model: 'A4 doubleface',
                    'Number of accessories': 'None',
                    'Number of frame': '2',
                    Orientation: 'Portrait',
                    Size: '21x29.7x11 cm',
                    Substrate: 'Foamex 3mm',
                    'Sustainability Label': 'None',
                },
                sampleName: 'Desk Display A4 Double Face',
            },
        ],
    },
    {
        sku: 'CIM-9B2RHQXC',
        name: 'Totem',
        variants: [
            {
                skuVariables: {
                    Accessory: 'Double-sided tape',
                    Finish: 'None',
                    Model: 'Totem 3 Medium Correx',
                    'Model Type': 'Totem 3 Medium Correx',
                    'Number of accessories': '5',
                    'Number of frame': '1',
                    Orientation: 'Portrait',
                    Size: '45x200x50 cm',
                    Substrate: 'Correx 3.5mm',
                },
                sampleName: 'Totem 3 Medium Correx',
            },
            {
                skuVariables: {
                    Accessory: 'None',
                    Finish: 'None',
                    'Model Type': 'Floor Display',
                    Model: 'Floor Display',
                    'Number of accessories': 'None',
                    'Number of frame': '1',
                    Orientation: 'Portrait',
                    Size: '70x100x35 cm',
                    Substrate: 'Doublewall E+E Flute 2.3mm',
                },
                sampleName: 'Floor Display',
            },
        ],
    },
    {
        sku: 'CIM-8TZP6JCT',
        name: 'Flags Display',
        variants: [
            {
                skuVariables: {
                    'Expected File Height': '4160',
                    'Expected File Width': '1180',
                    Model: 'Flag display 110 x 410 cm',
                    Structure: 'Flag display',
                    Substrate: 'Windproof Outdoor Fabric 115 gsm',
                    'Support Base': 'Outdoor Water Base XL',
                },
                sampleName: 'Flag display 110 x 410 cm',
            },
            {
                skuVariables: {
                    'Expected File Height': '3862',
                    'Expected File Width': '1005',
                    Model: 'Banderole display M',
                    Structure: 'Structure Flag M',
                    Substrate: 'Windproof Outdoor Fabric 115 gsm',
                    'Support Base': 'X-Shape Base',
                },
                sampleName: 'Banderole display M, 3862x1005 mm',
            },
            {
                skuVariables: {
                    'Expected File Height': '2385',
                    'Expected File Width': '825',
                    Model: 'Drop display XS',
                    Structure: 'Structure Flag XS',
                    Substrate: 'Windproof Outdoor Fabric 115 gsm',
                    'Support Base': 'X-Shape Base with weight',
                },
                sampleName: 'Drop display XS, 2385x825 mm',
            },
            {
                skuVariables: {
                    'Expected File Height': '2385',
                    'Expected File Width': '825',
                    Model: 'Beach flag XS',
                    Structure: 'Structure Flag XS',
                    Substrate: 'Windproof Outdoor Fabric 115 gsm',
                    'Support Base': 'Round Base',
                },
                sampleName: 'Beach flag XS, 2385x825 mm',
            },
        ],
    },
    {
        sku: 'CIM-N4XT9TYF',
        name: 'Stickers',
        variants: [
            {
                skuVariables: {
                    Coating: 'None',
                    'Cutting method': 'Die Cut',
                    DesignExperience: 'true',
                    ForcedReview: 'false',
                    Format: 'None',
                    Lamination: 'Labellife 3 anti-scratch varnish',
                    'Mirrored Printing': 'None',
                    'Number of stickers for Sheet': 0,
                    'Printing white': 'None',
                    Sets: 1,
                    Size: '7x5',
                    'Sticker Cut Shape Type': 'Rectangle Die-Cut',
                    'Sticker Cut Shape': 'Rectangle',
                    'Sticker Cut Type': 'Die-Cut Sticker',
                    Substrate: 'Polypropylene white',
                    'Sustainability Label': 'PP 5',
                    'Trim Height': 5,
                    'Trim Width': 7,
                },
                sampleName: '7x5 cm ',
            },
        ],
    },
    {
        sku: 'CIM-BH15D811',
        name: 'Flat Bags',
        variants: [
            {
                skuVariables: {
                    Activation: 'True',
                    Format: 'M - 25x10x36',
                    Grammage: '70 gsm',
                    Handles: 'Paper Havana',
                    Model: 'M Havana',
                    'Paper Type': 'Kraft paper',
                    'Printing Color': 'CMYK',
                    'Printing Layout': 'Front side only printing',
                    'Printing Method': 'Digital Printing',
                    Substrate: 'Havana 70gr',
                    'Type of Handles': 'Flat',
                },
                sampleName: 'Flat Bag M Havana Front Only',
            },
        ],
    },
];
