import styled from '@emotion/styled';

import { sliderSpacing } from './config';

interface SliderRootProps {
    disabled?: boolean;
    isHorizontal: boolean;
    hasMarks: boolean;
}

export const SliderRoot = styled.div<SliderRootProps>`
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
    padding: ${({ isHorizontal, hasMarks }) => {
        if (!hasMarks) return `${sliderSpacing / 2}px 0 ${sliderSpacing}px 0`;
        return isHorizontal
            ? `${sliderSpacing / 2}px ${sliderSpacing}px 26px ${sliderSpacing}px`
            : `${sliderSpacing}px 26px ${sliderSpacing}px ${sliderSpacing / 2}px`;
    }};
`;
