import { clamp, toNumber } from 'lodash';
import type { MutableRefObject, PointerEvent, RefObject } from 'react';

import type { SliderMarksInterface, SliderRailPosition } from './types';

export function getNewPositionFromEvent(
    { clientX, clientY }: PointerEvent,
    isHorizontal: boolean,
    railLength: number,
    railPosition: MutableRefObject<SliderRailPosition>,
    railRef: RefObject<HTMLDivElement>,
) {
    const newPosition = isHorizontal
        ? clientX - (railPosition.current.left - (railRef.current?.scrollLeft ?? 0))
        : railPosition.current.top + railLength - clientY - (railRef.current?.scrollTop ?? 0);

    return newPosition;
}

export function getPosition(value: number, min: number, max: number, railLength: number): number {
    const percentage = clamp((value - min) / (max - min), 0, 1);
    return percentage * railLength;
}

export function getPercentageFromRange(min: number, max: number, current: number) {
    const distance = max - min;
    const displacement = current - min;
    return (displacement / distance) * 100;
}

interface SliderMark {
    position: number;
    key: number;
    value: string;
}

export function convertMarksMapToArray(marks: SliderMarksInterface, getMarkPosition: (pos: number) => number) {
    return Object.entries(marks)
        .flatMap<SliderMark>(([key, value]) => {
            const numericKey = toNumber(key);
            if (Number.isNaN(numericKey)) return [];
            return [
                {
                    key: numericKey,
                    position: getMarkPosition(numericKey),
                    value,
                },
            ];
        })
        .sort((a, b) => a.key - b.key);
}
