import React from 'react';

import { cx } from '@emotion/css';

import { useBreakpointInfo } from '@design-stack-ct/utility-react';

import { Icon, IconSizes, useIcons } from '@dexter/dex-icon-library';

import {
    bodyStyle,
    buttonContainerStyle,
    cardStyle,
    dexCommonModalCancelIconStyle,
    footerStyle,
    headerStyle,
    mainHeadingStyle,
    subHeadingStyle,
} from './CommonModalCss';
import { DEX_CUSTOM_MODAL, DEX_MODAL_CLASS } from './CONSTANTS';
import { IconButton } from '../buttons';
import { Card } from '../card';
import { Modal } from '../dialogs';

interface CommonModalProps {
    modalOpen: boolean;
    closeModal: (isClose?: boolean) => void;
    header?: {
        headerMainTitleKey?: string;
        headerSubTitleKey?: string;
    };
    bodyContent: React.ReactNode;
    footerContent: React.ReactNode;
    footer?: { applyButton?: React.ReactNode };
    className?: string;
}

const CommonModal = ({
    modalOpen,
    closeModal,
    bodyContent,
    footerContent,
    header,
    footer,
    className,
}: CommonModalProps) => {
    const icons = useIcons();
    const { isSmallDevice } = useBreakpointInfo();

    return (
        <Modal isShown={modalOpen} onCancel={closeModal}>
            <Card className={cx(DEX_MODAL_CLASS, className, cardStyle(isSmallDevice))}>
                <header className={cx(`${DEX_CUSTOM_MODAL}-header-style`, headerStyle)}>
                    <IconButton
                        className={cx(`${DEX_CUSTOM_MODAL}-cancel-icon`, dexCommonModalCancelIconStyle)}
                        size={IconSizes.Small}
                        onClick={() => closeModal(false)}
                        isHoverEffectNeeded={false}
                    >
                        <Icon content={icons.CLOSE_CIRCLE.icon} />
                    </IconButton>

                    {header?.headerMainTitleKey && (
                        <div className={cx(`${DEX_CUSTOM_MODAL}-main-heading`, mainHeadingStyle)}>
                            {header.headerMainTitleKey}
                        </div>
                    )}
                    {header?.headerSubTitleKey && (
                        <div className={cx(`${DEX_CUSTOM_MODAL}-sub-heading`, subHeadingStyle)}>
                            {header.headerSubTitleKey}
                        </div>
                    )}
                </header>

                {bodyContent && (
                    <div className={cx(`${DEX_CUSTOM_MODAL}-body-style`, bodyStyle(isSmallDevice))}>{bodyContent}</div>
                )}
                {footerContent && (
                    <footer className={cx(`${DEX_CUSTOM_MODAL}-footer-style`, footerStyle)}>
                        {footerContent}
                        {footer?.applyButton && (
                            <div
                                className={cx(
                                    `${DEX_CUSTOM_MODAL}-footer-div-style`,
                                    buttonContainerStyle(isSmallDevice),
                                )}
                            >
                                {footer.applyButton}
                            </div>
                        )}
                    </footer>
                )}
            </Card>
        </Modal>
    );
};

export { CommonModal as CustomModal };
