import type { ReactNode } from 'react';
import React, { useEffect, useRef } from 'react';
import { useDragLayer, useDrop } from 'react-dnd';

import { css,cx } from '@emotion/css';

import type { draggableItemTypes } from './draggableItemTypes';
import type { DragData, DragItem } from './types';
import { cvar } from '../theme';

const dropTargetStyle = css`
    position: relative;
    display: inline-block;
    vertical-align: top; /* work around to keep from resizing parent slightly. See https://stackoverflow.com/a/27536461/2809054 */
`;

const dropOverlayStyle = css`
    pointer-events: none;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: ${cvar('primaryColor')};
    opacity: 0.2;
    z-index: 1;
`;

function getAcceptOverlayStyle(outlineStyle: 'solid' | 'dashed' | 'dotted') {
    return css`
        pointer-events: none;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        outline: 2px ${outlineStyle} ${cvar('primaryColor')};
        z-index: 1;
    `;
}

interface DropTargetProps {
    children?: ReactNode | ReactNode[];
    itemTypes: draggableItemTypes[];
    onDrop: (
        draggableId: string,
        dropLocation?: { x: number; y: number },
        draggableType?: draggableItemTypes,
        dragData?: DragData,
    ) => void;
    useDropOverlay?: boolean;
    useAcceptOverlay?: boolean;
    onIsOver?: (isOver: boolean) => void;
    className?: string;
    /**
     * If false, will not fire onDrop if a child (e.g. nested) drop target has already handled the drop.
     *
     * @default false
     */
    greedy?: boolean;
    outlineStyle?: 'solid' | 'dashed' | 'dotted';
}

export function DropTarget({
    children,
    onDrop,
    onIsOver,
    useDropOverlay = true,
    useAcceptOverlay = true,
    outlineStyle = 'solid',
    itemTypes = [],
    className,
    greedy = false,
}: DropTargetProps) {
    const dropContainerRef = useRef<HTMLDivElement | null>(null);
    const { clientOffset } = useDragLayer((monitor) => ({
        clientOffset: monitor.getClientOffset(),
    }));

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: itemTypes,
        drop: (item: DragItem, monitor) => {
            if (!monitor.didDrop() || greedy) {
                const dropContainerBoundingRect = dropContainerRef.current?.getBoundingClientRect();
                const dropLocation: { x: number; y: number } = { x: 0, y: 0 };
                if (clientOffset && dropContainerBoundingRect) {
                    dropLocation.x = clientOffset.x - dropContainerBoundingRect.x;
                    dropLocation.y = clientOffset.y - dropContainerBoundingRect.y;
                }

                // TODO(#39): change the first argument to be item instead of item.itemID
                onDrop(item.itemID, dropLocation, item.type, item.dragData);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true }),
            canDrop: monitor.canDrop(),
        }),
    });

    useEffect(() => {
        if (!onIsOver) return;
        onIsOver(isOver);
    }, [onIsOver, isOver]);

    return (
        <div
            ref={(element) => {
                dropContainerRef.current = element;
                drop(element);
            }}
            className={cx('dsc-drop-target', dropTargetStyle, className)}
            // promote self over sibling elements on drag so that nothing interferes with mouse interactions
            style={{ zIndex: canDrop ? 1 : 0 }}
        >
            {canDrop && useAcceptOverlay && (
                <div className={cx('dsc-drop-target__accept-overlay', getAcceptOverlayStyle(outlineStyle))} />
            )}
            {children}
            {isOver && useDropOverlay && <div className={cx('dsc-drop-target__drop-overlay', dropOverlayStyle)} />}
        </div>
    );
}
