import { PURPOSE_PRODUCT, SUB_PURPOSE_FULL } from '../store';
import { PreviewPosition, type PreviewSettings, type SceneConfiguration,type TenantConfiguration } from '../types';

export const defaultPreviewSettings: PreviewSettings = {
    vortex: true,
    scene: true,
    position: PreviewPosition.BOTTOM,
};

export const defaultTenantConfiguration: TenantConfiguration = {
    id: 'pixart',
    type: 'merchants',
};

export const defaultVortexConfiguration = {
    vortexActions: true,
    loader: 'Loading...',
    dimensions: {
        width: '400px',
        height: '400px',
    },
    error: {
        showErrorMessage: true,
        errorMessage: 'Unable to load 3D preview',
    },
    onAnimate: {
        animationDuration: 3,
    },
};

export const defaultSceneConfiguration: SceneConfiguration = {
    purpose: PURPOSE_PRODUCT,
    subPurpose: SUB_PURPOSE_FULL,
};
