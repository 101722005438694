import axios from 'axios';

import type { DpDownloadConfig } from '../types';

export const downloadHDImage = async (downloadConfig: DpDownloadConfig, imageId: string, authToken: string) => {
    const api = downloadConfig.hdImageDownloadAPI;
    const { imageQuality } = downloadConfig;
    const result = await axios({
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${authToken}`,
        },
        method: 'GET',
        url: `${api}/${imageId}/quality/${imageQuality}`,
    });

    if (result && result.data) {
        return result.data;
    }
    return {};
};
