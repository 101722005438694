import React from 'react';

import { cx } from '@emotion/css';

import { getLabelStyle,getSpinnerBaseStyle } from './SpinnerCss';

export type LoaderType = 'solid-circle' | 'dotted-circle';
export interface SpinnerProps {
    label?: string;
    size: string;
    thickness?: number;
    type?: LoaderType; // default is dotted-circle
    className?: string;
}

export function Spinner({ label, size, thickness = 16, type = 'dotted-circle', className }: SpinnerProps) {
    return (
        <div
            className={cx('dsc-spinner', getSpinnerBaseStyle(size, thickness, type), className)}
            data-testid="dsc-spinner"
        >
            {label && <span className={cx('dsc-spinner__label', getLabelStyle(size))}>{label}</span>}
        </div>
    );
}
