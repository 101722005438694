import type { Asset, Images, InfoProps, LoadingState, MultiPageAsset, PercentProgress } from '../types';

export const getInfoFromImage = (image: Images) => {
    let size = (image.fileSizeBytes || 0) / (1024 * 1024);
    let readableSize = '';
    const fileName = image.fileName || '';
    const uploadDate = image.uploadDate || '';
    if (size < 1) {
        size = (image.fileSizeBytes || 0) / 1024;
        if (size > 0) {
            readableSize = `${size.toFixed(2).toString()} KB`;
        }
    } else {
        readableSize = `${size.toFixed(2).toString()} MB`;
    }

    let info: InfoProps;
    if (fileName && readableSize && uploadDate) {
        info = [fileName, readableSize, uploadDate];
    }
    return info;
};

export function isMultiPageAsset(asset: Asset | undefined): asset is MultiPageAsset {
    return !!asset && (asset as MultiPageAsset).pages?.length >= 1;
}

export function isPercentProgress(value: LoadingState): value is PercentProgress {
    return value !== null && !!Number.isInteger((value as PercentProgress).percentage);
}
