import { css } from '@emotion/css';

import { cvar } from '@dexter/dex-component-library';

import { ENSEMBLE_GALLERY_PREFIX } from '../constants';

export const ensemblesContainerStyle = (isSmallDevice: boolean, isClicked: boolean, isLoaded?: boolean) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    opacity: 0;

    ${isClicked &&
    isSmallDevice &&
    `cursor: default;
    opacity: 1;
    .${ENSEMBLE_GALLERY_PREFIX}-color {
        cursor: pointer;
    }`}

    ${!isSmallDevice &&
    `&:hover {
            opacity: 1;
            cursor: default;
            .${ENSEMBLE_GALLERY_PREFIX}-color {
                cursor: pointer;
            }
        }`}
        ${isLoaded ? `display:block` : `display:none`}
`;

export const ensembleTopStyle = css`
    position: absolute;
    top: 2px;
    right: 2px;
`;

export const ensembleBottomStyle = css`
    background: white;
    position: absolute;
    bottom: 0;
    height: 30px;
    width: 100%;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
`;

export const ensembleColorsStyle = (gapSize: number) => css`
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 6px;
    column-gap: ${gapSize}px;
`;

export const ensembleColorStyle = (outlineOnHover?: boolean) => css`
    width: 20px;
    height: 20px;
    padding: 1px;
    outline: 1px solid transparent;
    border-radius: 5px;
    font-size: 10px;
    font-weight: bold;
    line-height: 20px;
    cursor: pointer;
    ${outlineOnHover &&
    ` :hover {
        outline: 1px solid ${cvar('primaryColor')};
    }`};
    color: ${cvar('primaryColor')};
    div {
        border-radius: 5px;
        width: 100%;
        height: 100%;
        border: 1px solid #9bb1b1;
    }
`;

export const ensembleColorSelectedStyle = css`
    outline-color: ${cvar('primaryColor')};
`;
