import { useEffect, useState } from 'react';

import { useSearch } from '@dexter/deposit-photo-sdk';

import { CONSTANT, LIMIT, SEARCHOFFSET } from '../constants';
import { getProviderConfigData,isProviderAvailable, processDPData } from '../helpers';
import type { PhotoStoreConfigProps } from '../types';

export function useDepositPhotoData(config: PhotoStoreConfigProps, authToken: string, dPAjaxEnable = true) {
    let configLimit = LIMIT;
    let configOffset = SEARCHOFFSET;
    let configCategory = false;
    const [data, setData] = useState<object>({});
    const isDp: boolean = dPAjaxEnable && isProviderAvailable(config, CONSTANT.depositPhoto);

    // get search limit from config for DP
    if (isDp) {
        configLimit = getProviderConfigData({
            config,
            provider: CONSTANT.depositPhoto,
            key: CONSTANT.limit,
        });
        const configPagination = getProviderConfigData({
            config,
            provider: CONSTANT.depositPhoto,
            key: CONSTANT.pagination,
        });
        if (configPagination?.offset) {
            configOffset = configPagination.offset;
        }
        configCategory = getProviderConfigData({ config, provider: CONSTANT.depositPhoto, key: CONSTANT.category });
    }

    const filter = getProviderConfigData({
        config,
        provider: CONSTANT.depositPhoto,
        key: 'filter',
    });

    // get deposit photo search data from API
    const { loading, result, error, searchWithSameConfig } = useSearch({
        api: config.providers?.depositPhoto?.searchApi,
        authToken,
        searchQuery: config.keywords,
        searchLimit: configLimit,
        enable: isDp,
        searchOffset: configOffset,
        filter,
        language: config.language,
    });

    useEffect(() => {
        // create and process customized result response
        if (result && result.type === CONSTANT.success) {
            const dpData = processDPData({ data: result, extraData: { category: configCategory } });
            setData(dpData);
        } else if (error.type === CONSTANT.failure) {
            // create and process error response
            const dpData = processDPData({ data: error });
            setData(dpData);
        }
    }, [result, error, configCategory]);

    return {
        loading,
        result: data,
        searchWithSameConfig,
    };
}
