import type { ReactNode } from 'react';
import React, { useRef } from 'react';

import { css, cx } from '@emotion/css';

import { useClickOutside } from '@design-stack-ct/utility-react';

export type SidebarPosition = 'left' | 'right';

const getSidebarStyle = (width?: number) => css`
    position: absolute;
    width: ${width ? `${width}px` : 'max-content'};
    transition: transform 0.3s ease;

    > .dsc-sidebar__content {
        height: 100%;
    }
`;

function getSidebarPositionStyle(position: SidebarPosition, opened: boolean) {
    if (position === 'left') {
        return css`
            top: 0;
            left: 0px;
            transform: ${opened ? 'translateX(0%)' : 'translateX(-100%)'};
        `;
    }

    return css`
        top: 0;
        right: 0px;
        transform: ${opened ? 'translateX(0%)' : 'translateX(100%)'};
    `;
}

export interface SidebarProps {
    children: ReactNode | ReactNode[];
    isOpen: boolean;
    position?: SidebarPosition;
    className?: string;
    onClose?: () => void;
    width?: number;
}

export function Sidebar({ children, isOpen, position = 'left', className, onClose = () => {}, width }: SidebarProps) {
    const sidebarRef = useRef<HTMLElement>(null);

    useClickOutside(
        { elementRef: sidebarRef, shouldAddEventListener: isOpen },
        () => {
            onClose();
        },
        [onClose],
    );

    return (
        <section
            ref={sidebarRef}
            data-testid="dsc-sidebar"
            className={cx(
                'dsc-sidebar',
                `dsc-sidebar--${isOpen ? 'open' : 'closed'}`,
                `dsc-sidebar--${position === 'left' ? 'left' : 'right'}`,
                getSidebarStyle(width),
                getSidebarPositionStyle(position, isOpen),
                className,
            )}
        >
            <aside className="dsc-sidebar__content">{children}</aside>
        </section>
    );
}
