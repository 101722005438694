import type { CimDoc } from '@design-stack-ct/cdif-types';

export interface ProductConfig {
    sku: string;
    attributes: Record<string, string>;
    cimDoc?: CimDoc;
}

export const PRODUCT: ProductConfig[] = [
    {
        sku: 'CIM-QHRHVXRA',
        attributes: {
            Accessories: 'Cushion Pads 40 x 40 cm',
            'Cut Shape': 'Rectangular',
            Format: '40 x 40 cm',
            'Printing Layout': 'Front and back side different printing',
            Substrate: 'Cotton Optic polyester 105gsm',
        },
        cimDoc: {
            version: '2',
            deleted: false,
            revision: 0,
            owner: 'Krishnakant.Gaud@pixartprinting.com',
            tenant: 'account:sUwJnco1V7RtqbL6W8DuQ8',
            document: {
                panels: [
                    {
                        id: 'b7bfd844-19a5-4d39-be4f-58428e94df32',
                        name: 'Side 1',
                        width: '450mm',
                        height: '447mm',
                        decorationTechnology: 'digital',
                    },
                    {
                        id: '4f9e3216-5a66-477b-8327-08a6e3aacff5',
                        name: 'Side 2',
                        width: '450mm',
                        height: '447mm',
                        decorationTechnology: 'digital',
                    },
                ],
            },
            metadata: {},
            fontRepositoryUrl: 'https://fonts.documents.cimpress.io/prod',
        },
    },
    {
        sku: 'CIM-QRHN2VXG',
        attributes: {
            Coating: 'None',
            'Cut Type': 'Laser + Die Cut',
            'Die-Cut': 'at_ds_l80xp80xa80',
            'Internal Cut': 'No',
            Lamination: 'None',
            'Nick Position': 'Bottom',
            'Printing Layout': 'Front side only printing',
            'Printing White': 'None',
            Substrate: 'Technodesign 380gsm',
        },
    },
    {
        sku: 'CIM-8AY66365',
        attributes: {
            'Coating Options': 'None',
            Coating: 'None',
            DesignExperience: 'true',
            'Die-Cut': 'fia001-a4',
            'Folder spine': '0.5 cm',
            ForcedReview: 'false',
            Format: 'FIA001',
            Lamination: 'None',
            'Printing Layout': 'Front side only printing',
            Substrate: 'Technodesign 380gsm',
            'Sustainability Label': 'PAP 21',
        },
        cimDoc: {
            version: '2',
            deleted: false,
            document: {
                panels: [
                    {
                        id: '574d04e0-a023-474f-a5a9-cca358483d6e',
                        name: 's983f8d19-46f5-4e22-b068-8ca3afb2811e:v2..fb7a5a9d-1217-4d63-a086-d6724cc1e32b',
                        width: '509.0710000000069mm',
                        height: '380.64700000000516mm',
                        decorationTechnology: 'offsetOrDigital',
                    },
                ],
            },
            metadata: {},
            fontRepositoryUrl:
                'https://fonts.documents.cimpress.io/v1/repositories/1ac2eaf9-00ab-4546-b903-83a6e9a5b5f9/published',
        },
    },
    {
        sku: 'CIM-9B2RHQXC',
        attributes: {
            Accessory: 'None',
            Finish: 'None',
            'Model Type': 'A5 vertical pocket 100mm',
            Model: 'A5 vertical pocket 100mm',
            'Number of accessories': 'None',
            'Number of frame': '1',
            Orientation: 'Portrait',
            Size: '14.8x21x12 cm',
            Substrate: 'Cardboard E Flute 1.4mm',
            'Sustainability Label': 'PAP 21',
        },
        cimDoc: {
            version: '2',
            deleted: false,
            document: {
                panels: [
                    {
                        id: '61b26c0e-8c1f-4bca-a3f1-b3c1577e54a8',
                        name: 's65b9be06-7ded-426a-810e-7256077994f1:v2..e19dc473-8a1c-42ba-90a7-c12b3460c5fe',
                        width: '357.7400000000049mm',
                        height: '411.9400000000056mm',
                        decorationTechnology: 'offsetOrDigital',
                    },
                ],
            },
            metadata: {},
            fontRepositoryUrl:
                'https://fonts.documents.cimpress.io/v1/repositories/1ac2eaf9-00ab-4546-b903-83a6e9a5b5f9/published',
        },
    },
    {
        sku: 'CIM-A09XTT2T',
        attributes: {
            'Bottom Customization': 'Yes',
            'Bottom Dimensions': '30+30 mm',
            DesignExperience: 'true',
            'Feature-Closure': 'Yes',
            'Feature-Hole': 'None',
            'Feature Hole Type': 'None',
            Format: '100ml 90x160mm',
            Lamination: 'None',
            'Paper Type': 'Multilayer transparent film',
            'Printing White Type': 'None',
            'Printing White': 'None',
            Substrate: 'Multilayer transparent film',
            'Sustainability Label': 'C/LD-PE 93',
        },
    },
];
