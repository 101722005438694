import type { ReactNode } from 'react';
import React from 'react';

import { css,cx } from '@emotion/css';

import { Modal } from './Modal';
import { cvar } from '../theme';

export interface DialogProps {
    isShown: boolean;
    children: ReactNode | ReactNode[];
    onCancel?: () => void;
}

const dialogStyle = css`
    background-color: #ffffff;
    border: 1px solid ${cvar('primaryBorderColor')};
    border-radius: 8px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
`;

export function Dialog({ isShown, children, onCancel }: DialogProps) {
    return (
        <Modal isShown={isShown} onCancel={onCancel}>
            <div role="dialog" aria-modal="true" className={cx('dsc-dialog', dialogStyle)}>
                {children}
            </div>
        </Modal>
    );
}
