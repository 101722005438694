import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { App } from './App';
import { basePath, redirectRoute } from './appSettings';
import { AuthProvider } from './AuthProvider';
import FusionPreview from './demo/fusion-preview/FusionPreview';
import { GalleryParams } from './demo/gallery/GalleryParams';
import PhotoStoreForm from './demo/photo-store/PhotoStoreForm';
import TextToImageForm from './demo/text-to-image/TextToImageForm';
import FusionPreviewDocument from './docs/fusion-preview/FusionPreviewDocument';

import './index.css';

const router = createBrowserRouter([
    {
        path: basePath,
        element: <App />,
        children: [
            {
                path: 'docs',
                children: [
                    {
                        path: 'fusion-preview',
                        element: <FusionPreviewDocument />,
                    },
                ],
            },
            {
                path: 'demo',
                children: [
                    {
                        path: 'photo-store',
                        element: <PhotoStoreForm />,
                    },
                    {
                        path: 'gallery',
                        element: <GalleryParams />,
                    },
                    {
                        path: 'fusion-preview',
                        element: <FusionPreview />,
                    },
                    {
                        path: 'text-to-image',
                        element: <TextToImageForm />,
                    },
                ],
            },
        ],
    },
]);

// V18
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <AuthProvider redirectRoute={redirectRoute}>
        <RouterProvider router={router} />
    </AuthProvider>,
);
