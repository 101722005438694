import React, { useMemo, useRef } from 'react';

import { css,cx } from '@emotion/css';

import { useCollapsibleItemContext } from './CollapsibleItemContext';
import type { CollapsibleBodyProps } from './types';
import { mediaQueries } from '../theme';
import { useElementDimensions } from '../utility/useElementDimensions';

const collapsibleBody = css`
    transition: max-height 0.25s ease-in-out;
    overflow: hidden;
    padding-bottom: 6px;
    margin-top: -6px;
`;

const collapsibleBodyContent = css`
    padding: 0 16px 12px 16px;
    transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;

    ${mediaQueries.small} {
        padding: 0 10px 8px 10px;
    }
`;

interface CollapsibleContentProps extends CollapsibleBodyProps {}

function CollapsibleContent({ children, className }: CollapsibleContentProps) {
    const { isOpen, id } = useCollapsibleItemContext();
    const contentRef = useRef<HTMLDivElement>(null);
    const dimensions = useElementDimensions(contentRef);

    const maxHeight = useMemo(() => {
        if (!isOpen) return 0;
        return Math.round(dimensions.height);
    }, [dimensions, isOpen]);

    return (
        <div className={cx(className, 'dsc-collapsible-content', collapsibleBody)} style={{ maxHeight }}>
            <div
                data-testid={`dsc-collapsible-content--${id}`}
                className={collapsibleBodyContent}
                ref={contentRef}
                style={{ opacity: isOpen ? 1 : 0, visibility: isOpen ? 'visible' : 'hidden' }}
            >
                {children}
            </div>
        </div>
    );
}

export const CollapsibleBody = ({ children, className }: CollapsibleBodyProps) => {
    // makes sure that `useElementDimensions` won't throw an error in a server enviroment
    if (typeof window === 'undefined') return null;
    return <CollapsibleContent className={className}>{children}</CollapsibleContent>;
};
