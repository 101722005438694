import type { CSSProperties } from 'react';
import React from 'react';

import { css, cx } from '@emotion/css';

import { cvar, mediaQueries } from '../theme';

export interface DividerProps {
    orientation: 'horizontal' | 'vertical';
    variant?: 'solid' | 'dashed';
    style?: CSSProperties;
}

const dividerStyle = css`
    border-bottom: 1px solid ${cvar('primaryBorderColor')};
    margin-top: 6px;
    height: 1px;
    width: 100%;
`;

const dividerStyleVertical = css`
    border-right: 1px solid ${cvar('primaryBorderColor')};
    margin: 0 8px;
    height: 90%;
    width: 1px;
    ${mediaQueries.small} {
        margin: 0 3px;
    }
`;

export function Divider({ orientation, variant = 'solid', style }: DividerProps) {
    const userStyle: CSSProperties =
        orientation === 'horizontal'
            ? { borderBottomStyle: variant, ...style }
            : { borderRightStyle: variant, ...style };

    return (
        <div
            className={cx(
                'dsc-divider',
                { [dividerStyle]: orientation === 'horizontal' },
                { [dividerStyleVertical]: orientation === 'vertical' },
            )}
            style={userStyle}
        ></div>
    );
}
