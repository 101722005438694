import { clamp } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { cx } from '@emotion/css';

import {
    inputSliderClassName,
    inputSliderContainerClassName,
    inputSliderInputClassName,
    inputSliderLabelClassName,
    inputSliderSliderClassName,
} from './config';
import { InputSliderContainer } from './InputSliderContainer';
import { InputSliderNumberInput } from './InputSliderInput';
import { InputSliderLabel } from './InputSliderLabel';
import { InputSliderRoot } from './InputSliderRoot';
import { Slider } from '../slider';

export interface InputSliderProps {
    /** ClassName that will be added to the root element */
    className?: string;
    /** Boolean that determines if the component is disabled */
    disabled?: boolean;
    /** Id that is used on the input and for the label */
    id?: string;
    /** Label to display */
    label: string;
    /** Maximum allowed value for the component. Defaults to 100 */
    max?: number;
    /** Minimum allowed value for the component. Defaults to 0 */
    min?: number;
    /** Callback when the value changes */
    onChange: (value: number) => void;
    /** Maximum allowed value for the component's slider. Defaults to and is capped by <code>max</code> */
    sliderMax?: number;
    /** Minimum allowed value for the component's slider. Defaults to and is capped by <code>min</code> */
    sliderMin?: number;
    /** Determines the startPosition of slider */
    sliderStartPosition?: number;
    /** Slider step, i.e. the minimal value increment when slider is being moved. */
    step?: number;
    /** Value of the InputSlider component. This value will be capped within <code>min</code> and <code>max</code> range. */
    value: number;
}

/**
 * A compound Input + Slider component.
 * It allows to change a value using both an input field and a slider.
 * The value ranges for the component itself and the slider can be controlled separately.
 *
 * The range of the slider values can never exceed the range of the component, but the range of the component can be well outside the range of the slider.
 *
 * For example, the component can have a range of 0-500, while the slider can have a range of 100-400; in this case, any value between 0 and 500 can be entered in the input box,
 * but using the slider will set the value between 100 and 400. When a value outside the slider range is provided, the slider visually 'jumps' to the repsective side.
 */
export const InputSlider = ({
    className,
    disabled,
    id,
    label,
    max = 100,
    min = 0,
    onChange,
    sliderMax = max,
    sliderMin = min,
    sliderStartPosition = sliderMin,
    step = 1,
    value,
}: InputSliderProps) => {
    const [actualMin, actualMax] = [Math.min(min, max), Math.max(min, max)];
    const [actualSliderMin, actualSliderMax] = [
        clamp(sliderMin, actualMin, actualMax),
        clamp(sliderMax, actualMin, actualMax),
    ];

    const [sliderDisplayValue, setSliderDisplayValue] = useState(value);
    const [internalValue, setInternalValue] = useState(clamp(value, actualMin, actualMax));

    const storeState = useCallback(
        (val: number, actualValue?: number) => {
            const inputValue = actualValue ?? clamp(val, actualMin, actualMax);
            const sliderValue = clamp(inputValue, actualSliderMin, actualSliderMax);

            setSliderDisplayValue(sliderValue);
            setInternalValue(inputValue);
        },
        [actualMax, actualMin, actualSliderMax, actualSliderMin],
    );

    useEffect(() => {
        if (value !== internalValue) {
            storeState(value);
        }
    }, [internalValue, storeState, value]);

    const onValueChange = (val: number) => {
        const actualValue = clamp(val, actualMin, actualMax);
        storeState(val, actualValue);
        onChange(actualValue);
    };

    return (
        <InputSliderRoot className={cx(inputSliderClassName, className)} data-testid={inputSliderClassName}>
            <InputSliderContainer className={inputSliderContainerClassName}>
                <InputSliderLabel
                    className={inputSliderLabelClassName}
                    data-testid={inputSliderLabelClassName}
                    htmlFor={id}
                >
                    {label}
                </InputSliderLabel>
                <InputSliderNumberInput
                    id={id}
                    disabled={disabled}
                    onChange={onValueChange}
                    min={min}
                    max={max}
                    value={internalValue}
                    className={inputSliderInputClassName}
                />
            </InputSliderContainer>
            <Slider
                className={inputSliderSliderClassName}
                min={sliderMin}
                max={sliderMax}
                startPosition={sliderStartPosition}
                value={sliderDisplayValue}
                disabled={disabled}
                step={step}
                tooltip={{ isEnabled: false }}
                onChange={onValueChange}
            />
        </InputSliderRoot>
    );
};
