import type { DetailedHTMLProps, MenuHTMLAttributes } from 'react';
import React from 'react';

import { css, cx } from '@emotion/css';

import { cvar } from '../theme';

export interface MenuBarProps extends DetailedHTMLProps<MenuHTMLAttributes<HTMLMenuElement>, HTMLMenuElement> {
    /** For determining the orientation @default Orientation.HORIZONTAL */
    orientation?: 'vertical' | 'horizontal';
}

const baseStyle = css`
    display: inline-flex;
    padding: 10px;
    border-radius: 25px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
    pointer-events: auto;
`;

const verticalStyle = css`
    flex-direction: column;
    width: 30px;
`;

const horizontalStyle = css`
    flex-direction: row;
    height: 30px;
`;

/**
 * This is a simple styled MenuBar
 */
export function MenuBar({ children, orientation, className, ...restOfProps }: MenuBarProps) {
    const isVertical = orientation === 'vertical';
    return (
        <menu
            className={cx(
                'dsc-menu-bar',
                baseStyle,
                css`
                    background-color: ${cvar('secondaryBackgroundColor')};
                    border: 1px solid ${cvar('primaryBorderColor')};
                `,
                {
                    'dsc-menu-bar--vertical': isVertical,
                    [verticalStyle]: isVertical,
                    [horizontalStyle]: !isVertical,
                },
                className,
            )}
            {...restOfProps}
        >
            {children}
        </menu>
    );
}
