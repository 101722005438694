import React, { createContext, useContext } from 'react';

import { useCollapsibleContext } from './CollapsibleContext';
import type { CollapsibleItemProps } from './types';

interface CollapsibleItemContextInterface {
    isOpen: boolean;
    toggleIsOpen: () => void;
    id: string;
}

export const CollapsibleItemContext = createContext<CollapsibleItemContextInterface | null>(null);

interface CollapsibleProviderProps extends CollapsibleItemProps {
    id: string;
}

export const CollapsibleItemProvider = ({ children, id, isOpen, onToggle }: CollapsibleProviderProps) => {
    const { activeIds, toggleActiveId } = useCollapsibleContext();

    const hasExternalState = isOpen !== undefined;

    function handleOnToggle() {
        if (!hasExternalState) {
            toggleActiveId(id);
        }
        return onToggle && onToggle(id);
    }

    return (
        <CollapsibleItemContext.Provider
            value={{
                isOpen: hasExternalState ? isOpen : activeIds.includes(id),
                toggleIsOpen: () => handleOnToggle(),
                id,
            }}
        >
            {children}
        </CollapsibleItemContext.Provider>
    );
};

export const useCollapsibleItemContext = () => {
    const context = useContext(CollapsibleItemContext);
    if (!context) {
        throw new Error('CollapsibleItemContext not defined, please use the CollapsibleItemProvider component');
    }
    return context;
};
