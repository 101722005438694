import type { ReactNode } from 'react';
import React from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { css, cx } from '@emotion/css';

import { cvar } from '../theme';

const baseStyle = css`
    width: 100%;
    height: 100%;
`;

const dropOverlayStyle = css`
    pointer-events: none;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
`;

const overlayLabelStyle = css`
    .dsc-file-drop-target-overlay__label-header {
        font-size: 30px;
        font-weight: 600;
    }

    .dsc-file-drop-target-overlay__label-subheader {
        font-size: 15px;
    }
`;

export interface FileDropTargetProps {
    children: ReactNode | ReactNode[];
    acceptFileTypes: string[];
    onDrop: (files: File[]) => void;
    dropOverlayLabel: string;
    supportedFileTypesLabel: string;
    onDropUnsupportedFile?: () => void;
    className?: string;
    id?: string;
}

export function FileDropTarget({
    children,
    acceptFileTypes,
    onDrop,
    dropOverlayLabel,
    supportedFileTypesLabel,
    onDropUnsupportedFile = () => {},
    className,
    id,
}: FileDropTargetProps) {
    const areDropFilesValid = (files: File[]) => {
        for (let i = 0; i < files.length; i++) {
            if (acceptFileTypes.indexOf(files[i].type) === -1) {
                return false;
            }
        }

        return true;
    };

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop(_, monitor) {
            if (monitor) {
                const { files } = monitor.getItem();
                if (files.length) {
                    if (areDropFilesValid(files)) {
                        onDrop(files);
                    } else {
                        onDropUnsupportedFile();
                    }
                }
            }
        },
        canDrop(item) {
            return typeof (Object.getOwnPropertyDescriptor(item, 'files') || {}).get === 'undefined';
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const isActive = canDrop && isOver;

    return (
        <div ref={drop} className={cx('dsc-file-drop-target', baseStyle, className)} id={id}>
            {isActive && (
                <div
                    className={cx(
                        'dsc-file-drop-target-overlay',
                        dropOverlayStyle,
                        css`
                            border: dashed ${cvar('primaryBorderColor')} 2px;
                        `,
                    )}
                >
                    <div
                        className={cx(
                            'dsc-file-drop-target-overlay__label',
                            overlayLabelStyle,
                            css`
                                color: ${cvar('primaryColor')};
                            `,
                        )}
                    >
                        <div className="dsc-file-drop-target-overlay__label-header">{dropOverlayLabel}</div>
                        <div className="dsc-file-drop-target-overlay__label-sub-header">{supportedFileTypesLabel}</div>
                    </div>
                </div>
            )}
            {children}
        </div>
    );
}
