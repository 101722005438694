import React, { useMemo } from 'react';

import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { sliderMarkItemClassName, sliderMarksClassName, sliderMarkValueDataAttr, sliderRailSize } from './config';
import type { SliderMarksInterface } from './types';
import { convertMarksMapToArray } from './utils';
import { cvar } from '../theme';

const SliderMarkList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
`;

interface SliderMarkListItemProps {
    isHorizontal: boolean;
    isActive: boolean;
    disabled?: boolean;
    onlyShowTick: boolean;
    isStartMark: boolean;
    isEndMark: boolean;
}

function getMarkIndicatorColor({ isActive, disabled }: SliderMarkListItemProps) {
    if (disabled) {
        return isActive ? cvar('primaryBorderColor') : cvar('disabledLightColor');
    }
    return isActive ? cvar('primaryColor') : cvar('primaryBorderColor');
}

const spacingThreshold = 2;

// styles to make sure the marks don't overflow the available width of the slider
const startMark = css`
    transform: translate(calc(0% - ${spacingThreshold}px), 0);
    &:before {
        left: ${spacingThreshold}px;
    }
`;

const lastMark = css`
    transform: translate(calc(-100% + ${spacingThreshold}px), 0);
    &:before {
        left: calc(100% - ${spacingThreshold}px);
    }
`;

const SliderMarkListItem = styled.li<SliderMarkListItemProps>`
    position: absolute;
    transform: translate(${({ isHorizontal }) => (isHorizontal ? '-50%, 0' : '0, 50%')});
    font-size: 12px;
    top: ${({ isHorizontal }) => (isHorizontal ? '22px' : 'auto')};
    left: ${({ isHorizontal }) => (isHorizontal ? 'auto' : '20px')};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    user-select: none;
    min-width: 20px;
    min-height: 20px;
    pointer-events: ${({ onlyShowTick }) => (onlyShowTick ? 'none' : 'auto')};

    &:before {
        content: '';
        width: ${({ isHorizontal }) => (isHorizontal ? 2 : sliderRailSize * 4)}px;
        height: ${({ isHorizontal }) => (isHorizontal ? sliderRailSize * 4 : 2)}px;
        border-radius: 2px;
        position: absolute;
        pointer-events: none;
        top: ${({ isHorizontal }) => (isHorizontal ? '-16px' : 'auto')};
        left: ${({ isHorizontal }) => (isHorizontal ? 'auto' : '-14px')};
        transform: translate(${({ isHorizontal }) => (isHorizontal ? '-50%, 0' : '0, 50%')});
        background-color: ${getMarkIndicatorColor};
    }

    ${({ isHorizontal, isStartMark }) => isHorizontal && isStartMark && startMark}
    ${({ isHorizontal, isEndMark }) => isHorizontal && isEndMark && lastMark}
`;

interface SliderMarksProps {
    marks: SliderMarksInterface;
    isHorizontal: boolean;
    getMarkPosition: (initialPos: number) => number;
    inputValue: number;
    startPosition: number;
    min: number;
    max: number;
    disabled?: boolean;
}

function determineIfMarkIsActive(
    inputValue: number,
    markValue: number,
    min: number,
    max: number,
    startPosition: number,
) {
    if (min === startPosition) {
        return inputValue >= markValue;
    }
    if (max === startPosition) {
        return inputValue <= markValue;
    }
    if (markValue === startPosition) {
        return true;
    }
    if (markValue > startPosition) {
        return inputValue >= markValue;
    }
    return inputValue <= markValue;
}

export const SliderMarks = ({
    marks,
    isHorizontal,
    getMarkPosition,
    min,
    max,
    inputValue,
    startPosition,
    disabled,
}: SliderMarksProps) => {
    const sliderMarks = useMemo(() => convertMarksMapToArray(marks, getMarkPosition), [marks, getMarkPosition]);

    if (!sliderMarks.length) return null;

    return (
        <SliderMarkList className={sliderMarksClassName} data-testid={sliderMarksClassName}>
            {sliderMarks.map(({ position, value, key }) => {
                const isActive = determineIfMarkIsActive(inputValue, Number(key), min, max, startPosition);
                return (
                    <SliderMarkListItem
                        {...{ [sliderMarkValueDataAttr]: key }}
                        className={cx(sliderMarkItemClassName, isActive && `${sliderMarkItemClassName}--active`)}
                        data-testid={sliderMarkItemClassName}
                        disabled={disabled}
                        isActive={isActive}
                        onlyShowTick={!value}
                        isStartMark={key === min}
                        isEndMark={key === max}
                        key={`${key}-${value}`}
                        style={{ [isHorizontal ? 'left' : 'bottom']: position }}
                        isHorizontal={isHorizontal}
                    >
                        {value}
                    </SliderMarkListItem>
                );
            })}
        </SliderMarkList>
    );
};
