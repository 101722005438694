import { useMemo } from 'react';

import type { FusionPreviewProps } from '@rendering/product-preview-react';

import { defaultTenantConfiguration } from '../configuration/configuration';
import { usePreviewConfiguration } from '../providers';

interface GetVortexOptionsReturn {
    user: FusionPreviewProps['user'];
    renderOptions: FusionPreviewProps['renderOptions'];
}

export const useVortexOptions = (): GetVortexOptionsReturn => {
    const { tenantConfiguration } = usePreviewConfiguration();

    const tenantConfig = useMemo<{
        user: FusionPreviewProps['user'];
        tenant: { id: string; type: string };
    }>(
        () => ({
            user: {
                referer: tenantConfiguration?.id || defaultTenantConfiguration.id,
                tenant: tenantConfiguration || defaultTenantConfiguration,
            },
            tenant: tenantConfiguration || defaultTenantConfiguration,
        }),
        [tenantConfiguration],
    );

    const renderOptions = useMemo<FusionPreviewProps['renderOptions']>(
        () => ({
            vortex: 'interactive',
            cameraViews: true,
            animations: true,
        }),
        [],
    );

    return {
        user: tenantConfig.user,
        renderOptions,
    };
};
