import { hex2rgb } from '@design-stack-ct/utility-core';

import { defaultTheme } from './theme';
import type { Theme } from './types';

export function cvar(key: keyof Theme, opacity?: number) {
    if (opacity) {
        const rgb = hex2rgb(defaultTheme[key]);
        return `var(--dsc-${key}, rgba(${rgb.r},${rgb.g},${rgb.b},${opacity}))`;
    }
    return `var(--dsc-${key}, ${defaultTheme[key]})`;
}
