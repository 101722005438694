import React from 'react';

import type { IconRecord } from '@dexter/dex-icon-library';
import { Icon, IconSizes } from '@dexter/dex-icon-library';

import { IconButton } from '../buttons';

interface ArrowIconProps {
    icon: IconRecord;
    onClick: () => void;
    className?: string;
    size?: string;
}

export const ArrowIcon = ({ icon, onClick, className, size }: Readonly<ArrowIconProps>) => (
    <div className={className} onClick={onClick}>
        <IconButton size={size || IconSizes.ExtraSmall}>
            <Icon content={icon.icon} />
        </IconButton>
    </div>
);
