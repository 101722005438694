import { useEffect, useState } from 'react';

import { useDepositPhotoData } from './useDepositPhotoData';
import { DEFAULT_CONFIG } from '../constants';
import { isEmptyObject } from '../helpers';
import type { PhotoStoreConfigProps, ResultOutputProps } from '../types';

export function usePhotoStores(config: PhotoStoreConfigProps, authToken: string, dPAjaxEnable = true) {
    // TODO: Assign specific type too depositPhoto
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [finalData, setFinalData] = useState<ResultOutputProps | { depositPhoto: any }>({});

    // get Data from Deposit Photo
    const { result, loading, searchWithSameConfig } = useDepositPhotoData(
        { ...DEFAULT_CONFIG, ...config },
        authToken,
        dPAjaxEnable,
    );

    useEffect(() => {
        if (!isEmptyObject(result)) {
            // set DP data
            setFinalData({
                depositPhoto: result,
            });
        }
    }, [result]);

    return { result: finalData, loading, searchWithSameConfig };
}
