import type { Dispatch, SetStateAction } from 'react';
import React, { useCallback, useEffect,useState } from 'react';

import { cx } from '@emotion/css';

import { useBreakpointInfo } from '@design-stack-ct/utility-react';

import { Draggable, DraggableItem, IconButton, Spinner, useDraggingState } from '@dexter/dex-component-library';
import { Icon,useIcons } from '@dexter/dex-icon-library';

import {
    backContainerStyle,
    containerElementStyle,
    contentWrapperStyle,
    ellipsisStyle,
    iconStyle,
    imageContainerStyle,
    processingTextStyle,
} from './GalleryCss';
import { ImageThumbnail } from './ImageThumbnail';
import { getInfoFromImage, isMultiPageAsset, isPercentProgress } from '../helpers/utils';
import type { Localization } from '../localization';
import { enUS } from '../localization';
import type { GalleryType, Images, MultiPageAsset, ThumbnailButtonConfig } from '../types';

interface ImageContentProps {
    images: Images[];
    scrollContainerRef: React.RefObject<HTMLDivElement>;
    backAllAssetRef: React.RefObject<HTMLDivElement>;
    imagesLoaded: Dispatch<SetStateAction<boolean>>;
    onRemove: (param: string) => void;
    onAdd: (param: string, pageNumber: number) => void;
    icons: ThumbnailButtonConfig[];
    draggableItemType: DraggableItem;
    showInfo: boolean;
    localization: Localization;
    type: GalleryType;
    setViewAll?: (param: boolean) => void;
    setSelectedPdfAsset?: Dispatch<SetStateAction<MultiPageAsset | null>>;
    showPdfPagesInViewAll?: boolean;
    containerHeight?: number;
    setClickedAssetId?: (isAssetTypeMultiPage: string) => void;
    clickedAssetId?: string | undefined;
}

export const ImageContent = ({
    images,
    scrollContainerRef,
    backAllAssetRef,
    imagesLoaded,
    onRemove,
    onAdd,
    icons,
    draggableItemType,
    showInfo,
    localization = enUS,
    type,
    setViewAll,
    setSelectedPdfAsset,
    showPdfPagesInViewAll,
    containerHeight,
    setClickedAssetId,
    clickedAssetId,
}: ImageContentProps) => {
    const [selectedAsset, setSelectedAsset] = useState<MultiPageAsset | null>(null);
    const [objectToCount, setObjectToCount] = useState(0);
    const { allImages, pagesCountLabel, pageCountLabel, processingOverlay } = localization;
    const { isSmallDevice } = useBreakpointInfo();
    const isDraggable = !isSmallDevice;
    const selectedIds: [] = [];
    const isDragEnabled = true;
    const { setIsDragging } = useDraggingState();

    let numberMultiPages = 0;
    const { FOLDER, PIXART_DELETE_EMPTY, PIXART_ADD, ARROW_LEFT } = useIcons();
    const [loadedImages, setLoadedImages] = useState<number[]>([]);

    const showAssetPages = (assetId: string) => {
        const asset = images.find((image) => image.id === assetId);
        if (isMultiPageAsset(asset)) {
            setSelectedAsset(asset);
        }
    };
    const showAllAssets = () => {
        setSelectedAsset(null);
        imagesLoaded(false);
    };

    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const extractedIcon = icons.find((icon: any) => icon.position === 'BOTTOM_LEFT');
    const customCss = extractedIcon ? extractedIcon?.customCss : {};

    const iconsMultiPageList: ThumbnailButtonConfig[] = [
        {
            icon: PIXART_DELETE_EMPTY,
            position: 'BOTTOM_LEFT',
            action: onRemove,
            customCss,
        },
    ];
    const iconsMultiPageItem: ThumbnailButtonConfig[] = [
        {
            icon: PIXART_ADD,
            position: 'BOTTOM_RIGHT',
            action: onAdd,
        },
    ];
    const backIcon = { icon: ARROW_LEFT };
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const after = (count: number, f: any) => {
        let noOfCalls = 0;
        // TODO: Remove any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (...rest: any) => {
            noOfCalls += 1;
            if (count - numberMultiPages === noOfCalls) {
                f(...rest);
            }
        };
    };
    const onLoad = after(objectToCount, () => {
        imagesLoaded(true);
    });

    const handleImageLoad = useCallback((index: number) => {
        setLoadedImages((prevLoadedImages) => [...prevLoadedImages, index]);
    }, []);

    useEffect(() => {
        if (objectToCount < 0 && !selectedAsset) {
            imagesLoaded(true);
        }
    }, [objectToCount, imagesLoaded, selectedAsset]);
    const numberElement = document.getElementsByClassName('dexcore-containerElement').length;

    useEffect(() => {
        let objectCount = 0;
        if (selectedAsset) {
            objectCount = selectedAsset.pages.length;
        } else if (numberElement > 0) {
            objectCount = images.length - numberElement;
        } else {
            objectCount = images.length;
        }
        setObjectToCount(objectCount);
    }, [images.length, numberElement, selectedAsset]);

    if (Array.isArray(selectedAsset?.pages)) {
        return (
            <>
                <div ref={backAllAssetRef} className={cx('dex-gallery-back-container', backContainerStyle)}>
                    <IconButton onClick={showAllAssets} size="12px">
                        <Icon content={backIcon.icon.icon} />
                    </IconButton>{' '}
                    {allImages}
                </div>
                <article
                    className={cx('dex-gallery-image-container', imageContainerStyle(type === 'vertical'))}
                    ref={scrollContainerRef}
                >
                    {selectedAsset?.pages.map((image: Images) => {
                        const key = `${image.id}_${image.pageNumber}`;
                        let info;
                        if (showInfo) {
                            info = getInfoFromImage(image);
                        }
                        return (
                            <div
                                className={cx(
                                    'dex-gallery-containerElement',
                                    containerElementStyle(type === 'vertical'),
                                )}
                                key={key}
                            >
                                <img
                                    alt={image.fileName}
                                    title={image.fileName}
                                    onLoad={onLoad}
                                    src={image.thumbnailUrl}
                                />
                                {icons && (
                                    <ImageThumbnail
                                        id={image.id}
                                        pageNumber={image.pageNumber || 1}
                                        icons={iconsMultiPageItem}
                                        info={info}
                                    />
                                )}
                            </div>
                        );
                    })}
                </article>
            </>
        );
    }

    return (
        <article
            className={cx('dex-gallery-image-container', imageContainerStyle(type === 'vertical'))}
            ref={scrollContainerRef}
        >
            {!selectedAsset &&
                images &&
                images.map((image: Images, index: number) => {
                    const { loadingState, draggableId } = image;
                    const isLoading = isPercentProgress(loadingState) || loadingState === 'processing';
                    const dragData = {
                        assetId: image.id,
                        pageNumber: image.pageNumber,
                        selectedIds,
                    };
                    const hasDraggableContent = isDraggable && draggableId && DraggableItem.Image;
                    if (isLoading) {
                        return (
                            <div
                                key={image.id}
                                className={cx('dex-gallery-loadingElement', containerElementStyle(type === 'vertical'))}
                            >
                                <Spinner
                                    size="38px"
                                    thickness={3}
                                    label={isPercentProgress(loadingState) ? `${loadingState.percentage}%` : ''}
                                />
                                {loadingState === 'processing' && (
                                    <span className={processingTextStyle}>{processingOverlay}</span>
                                )}
                            </div>
                        );
                    }

                    if (image.previewUrl) {
                        let info;
                        if (showInfo) {
                            info = getInfoFromImage(image);
                        }
                        const imageContent = (
                            <>
                                {!loadedImages.includes(index) && (
                                    <div
                                        className="dex-gallery-image-loader"
                                        style={{
                                            width: loadedImages.includes(index) ? 'auto' : containerHeight,
                                        }}
                                    >
                                        <Spinner size="38px" thickness={3} />
                                    </div>
                                )}
                                <img
                                    alt={image.fileName}
                                    title={image.fileName}
                                    onLoad={() => handleImageLoad(index)}
                                    src={image.thumbnailUrl}
                                    className="dex-gallery-image-loader"
                                    style={{
                                        width: loadedImages.includes(index) ? 'auto' : containerHeight,
                                    }}
                                />

                                {icons && (
                                    <ImageThumbnail
                                        id={image.id}
                                        pageNumber={1}
                                        icons={icons}
                                        info={info}
                                        onAdd={onAdd}
                                        setClickedAssetId={setClickedAssetId}
                                        clickedAssetId={clickedAssetId}
                                        isMultiPageAsset={isMultiPageAsset(image)}
                                    />
                                )}
                            </>
                        );
                        let contentElement = (
                            <div
                                className={cx(
                                    'dex-gallery-containerElement',
                                    containerElementStyle(type === 'vertical'),
                                )}
                                key={image.id}
                            >
                                {hasDraggableContent && (
                                    <Draggable
                                        dragContentType={draggableItemType}
                                        dragData={dragData}
                                        id={draggableId}
                                        enabled={isDragEnabled}
                                        className={contentWrapperStyle}
                                        onIsDragging={(isDragging) => setIsDragging(isDragging)}
                                    >
                                        {imageContent}
                                    </Draggable>
                                )}
                                {!hasDraggableContent && imageContent}
                            </div>
                        );

                        if (isMultiPageAsset(image)) {
                            const isOnePage: boolean = image.pages.length === 1;
                            if (isOnePage) {
                                const imageContentPdf = (
                                    <>
                                        <img
                                            alt={image.fileName}
                                            title={image.fileName}
                                            onLoad={onLoad}
                                            src={image.pages[0].thumbnailUrl}
                                        />
                                        {icons && (
                                            <ImageThumbnail
                                                id={image.id}
                                                pageNumber={1}
                                                icons={icons}
                                                info={info}
                                                onAdd={onAdd}
                                                isMultiPageAsset={isMultiPageAsset(image)}
                                                setClickedAssetId={setClickedAssetId}
                                                clickedAssetId={clickedAssetId}
                                            />
                                        )}
                                    </>
                                );
                                contentElement = (
                                    <div
                                        className={cx(
                                            'dex-gallery-containerElement',
                                            containerElementStyle(type === 'vertical'),
                                        )}
                                        key={image.id}
                                    >
                                        {hasDraggableContent && (
                                            <Draggable
                                                dragContentType={DraggableItem.Image}
                                                dragData={dragData}
                                                id={draggableId}
                                                enabled={isDragEnabled}
                                                className={contentWrapperStyle}
                                                onIsDragging={(isDragging) => setIsDragging(isDragging)}
                                            >
                                                {imageContentPdf}
                                            </Draggable>
                                        )}
                                        {!hasDraggableContent && imageContentPdf}
                                    </div>
                                );
                            } else {
                                numberMultiPages += 1;
                                contentElement = (
                                    <div
                                        className={cx(
                                            'dex-gallery-containerElement',
                                            containerElementStyle(type === 'vertical'),
                                        )}
                                        key={image.id}
                                    >
                                        <div className="dex-gallery-folder">
                                            <Icon content={FOLDER.icon} size="medium" className={iconStyle} />
                                            <div className={ellipsisStyle}>{image.fileName}</div>
                                            <div className={ellipsisStyle}>
                                                ({image.pages.length}{' '}
                                                {image.pages.length === 1 ? pageCountLabel : pagesCountLabel})
                                            </div>
                                            {icons && (
                                                <ImageThumbnail
                                                    id={image.id}
                                                    icons={iconsMultiPageList}
                                                    info={info}
                                                    isMultiPageAsset={isMultiPageAsset(image)}
                                                    setClickedAssetId={setClickedAssetId}
                                                    clickedAssetId={clickedAssetId}
                                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                                    onAdd={(param: string, pageNumber: number) => {
                                                        showAssetPages(image.id);
                                                        if (
                                                            showPdfPagesInViewAll &&
                                                            setViewAll !== undefined &&
                                                            setSelectedPdfAsset !== undefined
                                                        ) {
                                                            setViewAll(true);
                                                            setSelectedPdfAsset(image);
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                        }

                        return contentElement;
                    }
                    return <div key={image.id}></div>;
                })}
        </article>
    );
};
