import React, { useState } from 'react';

import { css } from '@emotion/css';

import { Button, Input } from '@dexter/dex-component-library';

import TextToImage from './TextToImage';

const mainDivStyle = css`
    display: flex;
    margin: 50px;
    grid-gap: 40px;
    justify-content: space-between;
    flex-direction: column;
`;
const TextToImageFormRoot = css`
    margin: 30px;
    width: 400px;
`;

const rowStyle = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .dsc-select,
    .dsc-input {
        width: 275px;
    }
    textarea {
        width: 268px;
    }
`;

export default function TextToImageForm() {
    const [search, setSearch] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [finalFormData, setFinalFormData] = useState({});
    const [count, setCount] = useState(0);

    const setSearchData = (value: string) => {
        setShowPreview(false);
        setSearch(value);
    };
    const setAccessTokenData = (value: string) => {
        setShowPreview(false);
        setAccessToken(value);
    };

    const handleSearch = () => {
        if (search.trim() === '' || accessToken.trim() === '') {
            return;
        }
        setCount(count + 1);
        setShowPreview(true);
        setFinalFormData({ search, accessToken, count });
    };

    return (
        <div className={mainDivStyle}>
            <section className={TextToImageFormRoot}>
                <h1>Text to Image Form</h1>
                <div className={rowStyle}>
                    <Input
                        key="search"
                        type="text"
                        value={search}
                        onChange={(value: string) => setSearchData(value)}
                        placeholder="An evening in Paris"
                        disabled={false}
                    />
                </div>
                <br />
                <div className={rowStyle}>
                    <Input
                        key="accessToken"
                        type="text"
                        value={accessToken}
                        onChange={(value: string) => setAccessTokenData(value)}
                        placeholder="JWT Token"
                        disabled={false}
                    />
                </div>
                <Button style={{ marginTop: '10px' }} onClick={handleSearch}>
                    Search
                </Button>
            </section>
            {showPreview && <TextToImage {...finalFormData} />}
        </div>
    );
}
