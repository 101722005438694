import FocusTrap from 'focus-trap-react';
import escapeRegExp from 'lodash/escapeRegExp';
import type { CSSProperties, KeyboardEvent, ReactElement, ReactNode } from 'react';
import React, { Children, cloneElement, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Manager, Popper, Reference } from 'react-popper';

import { css, cx } from '@emotion/css';

import { ENTER } from '@design-stack-ct/utility-core';
import { useClickOutside } from '@design-stack-ct/utility-react';

import { Icon, useIcons } from '@dexter/dex-icon-library';

import { getSelectSizeInPixels, SelectSize } from './common';
import type { OptionProps } from './Option';
import { Option } from './Option';
import { popperModifiers } from './popperModifiers';
import type { InputProps } from '../input';
import { Input } from '../input';
import { portalTargetId } from '../portalTarget';
import { cvar } from '../theme';
import type { IconsProp } from '../types';

function defaultFilterOptions<T>(option: ReactElement<OptionProps<T>>, searchTerm: string) {
    const searchRegex = new RegExp(escapeRegExp(searchTerm), 'i');

    return searchRegex.test(String(option.props.value));
}

function defaultMatchWith<T>(value: T, searchTerm: string) {
    return String(value).toLocaleLowerCase() === searchTerm.toLowerCase();
}

function defaultCreateOption(searchTerm: string): { value: string; displayValue: ReactNode } {
    return {
        value: searchTerm,
        displayValue: searchTerm,
    };
}

function defaultDisplayWith<T>(value: T): ReactNode {
    return String(value);
}

function getWrapperStyle(size: SelectSize) {
    return css`
        display: inline-flex;
        align-items: center;
        width: 150px;
        height: ${getSelectSizeInPixels(size)};
        font-family: ${cvar('selectFontFamily')};
        border: 1px solid #8090a2;
        border-radius: 2px;
        padding: 0 8px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;

        svg {
            margin-left: 8px;
            color: ${cvar('primaryColor')};
        }

        &:hover {
            border-color: #57dbc1;
        }

        &:focus {
            outline: none;
            border-color: #008f7b;
        }

        &.dsc-select--disabled,
        &.dsc-select--disabled:focus {
            border-color: #c4cdd6;
            background-color: #f8f9fa;
            cursor: default;
            color: #8090a2;
            pointer-events: none;

            svg {
                color: ${cvar('disabledDarkColor')};
            }
        }
    `;
}

const panelStyle = css`
    box-sizing: border-box;
    background-color: ${cvar('primaryBackgroundColor')};
    font-family: ${cvar('selectFontFamily')};
    border: 1px solid #c4cdd6;
    border-radius: 2px;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
    user-select: none;
    max-height: 400px;
    overflow-y: auto;
    z-index: 3; // dropdown panel needs to be above Modal
`;

const searchStyle = css`
    display: flex;
    justify-content: center;
    padding: 16px 0;
    background-color: ${cvar('primaryBackgroundColor')};
    position: sticky;
    top: 0;

    .dsc-input {
        max-width: calc(100% - 32px);
    }

    .dsc-input__input {
        max-width: 100%;
    }
`;

const valueStyle = css`
    flex: 1;
    line-height: 15px;
    font-size: 11px;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const listStyle = css`
    outline: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const placeholderStyle = css`
    color: #8090a2;
`;

function isFilterConfig<T>(f: boolean | SelectFilterConfig<T> | undefined): f is SelectFilterConfig<T> {
    // Good enough?
    return typeof f === 'object';
}

export interface SelectFilterConfig<T> {
    allowNewOption?: boolean;
    inputProps?: InputProps;
    filterOptions?: (option: ReactElement<OptionProps<T>>, searchTerm: string) => boolean;
    matchWith?: (value: T, searchTerm: string) => boolean;
    displayWith?: (value: T) => ReactNode;
    createOption?: (searchTerm: string) => { value: T; displayValue: ReactNode } | false;
}

interface SelectIcons extends IconsProp {
    selectPanel?: {
        icon: string;
    };
    /**
     * This property does not take any effect
     * as it is only available on input validation errors
     * which we do not pass to the filterInput
     */
    toolTip?: {
        icon: string;
    };
    option?: {
        icon: string;
    };
}

export interface SelectProps<T> {
    /**
     * Use `Option` components as the children for the `Select`
     */
    children: ReactElement<OptionProps<T>>[] | ReactElement<OptionProps<T>>;
    onChange?: (value: T) => void;
    value?: T;
    className?: string;
    selectPanelClassName?: string;
    style?: CSSProperties;
    placeholder?: string;
    isDisabled?: boolean;
    size?: SelectSize;
    filterInput?: boolean | SelectFilterConfig<T>;
    icons?: SelectIcons;
    shouldScrollToSelected?: boolean;
}

export function Select<T>({
    children,
    value: valueProp,
    onChange = () => {},
    className,
    selectPanelClassName,
    placeholder,
    style,
    isDisabled = false,
    size = SelectSize.Medium,
    filterInput,
    icons = {},
    shouldScrollToSelected = true,
}: SelectProps<T>) {
    const { ARROW_DOWN } = useIcons();
    let { option } = icons;
    const { toolTip } = icons;
    const { current: isControlled } = useRef(valueProp !== undefined);
    const [valueState, setValueState] = useState<T | null>(null);
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const listRef = useRef<HTMLUListElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const panelRef = useRef<HTMLDivElement>(null);

    const value = isControlled ? valueProp : valueState;
    let displayValue: ReactNode;

    const closePanel = () => {
        setOpen(false);
        setSearchTerm(undefined);
    };

    const setNewValue = (newValue: T) => {
        if (!isControlled) {
            setValueState(newValue);
        }

        onChange(newValue);
    };

    useClickOutside(
        { elementRef: [wrapperRef, panelRef], shouldAddEventListener: open },
        () => {
            if (open) {
                closePanel();
            }
        },
        [open],
    );

    let filterConfig: SelectFilterConfig<T> = {};

    if (isFilterConfig(filterInput)) {
        filterConfig = filterInput;
    }

    const filterOptions = filterConfig.filterOptions ?? defaultFilterOptions;
    const matchWith = filterConfig.matchWith ?? defaultMatchWith;
    const createOption = filterConfig.createOption ?? defaultCreateOption;
    const displayWith = filterConfig.displayWith ?? defaultDisplayWith;

    let valueExistsInOptions = false;
    let searchTermMatchesOption = false;

    const createOptions = (child: ReactElement<OptionProps<T>>, index: number) => {
        const selected = child.props.value === value;

        if (selected) {
            valueExistsInOptions = true;
        }

        if (value !== null && value !== undefined && selected) {
            displayValue = child.props.children;
        }

        if (searchTerm !== undefined && matchWith(child.props.value, searchTerm)) {
            searchTermMatchesOption = true;
        }

        option = option || child.props.icons?.option;

        return cloneElement(child, {
            selected,
            tabIndex: index + 1,
            scrollIntoView: shouldScrollToSelected,
            onClick: (newValue: T) => {
                setNewValue(newValue);

                if (child.props.onClick) {
                    child.props.onClick(newValue);
                }

                closePanel();
            },
            size,
            icons: { option },
        });
    };

    let options = Children.map(children, createOptions);

    let searchTermMatchesNewOption = false;

    if (value && !valueExistsInOptions) {
        displayValue = displayWith(value);
        if (searchTerm) {
            searchTermMatchesNewOption = matchWith(value, searchTerm);
        }
    }

    if (filterInput && searchTerm) {
        options = options.filter((opt) => filterOptions(opt, searchTerm));
    }

    if (searchTerm && filterConfig.allowNewOption && !searchTermMatchesOption && !searchTermMatchesNewOption) {
        const customOption = createOption(searchTerm);
        if (customOption) {
            options.push(
                <Option
                    key={String(customOption.value)}
                    value={customOption.value as T}
                    selected={false}
                    size={size}
                    onClick={(newValue: T) => {
                        setNewValue(newValue);
                        closePanel();
                    }}
                    icons={{ option }}
                    scrollIntoView={shouldScrollToSelected}
                >
                    {customOption.displayValue}
                </Option>,
            );
        }
    }

    const handleInputChange = (inputValue: string) => {
        setSearchTerm(inputValue);
        filterConfig?.inputProps?.onChange && filterConfig?.inputProps?.onChange(inputValue);
    };

    const handleInputKeyDown = (event: KeyboardEvent) => {
        if (filterInput && event.key === ENTER && searchTerm) {
            // First try to find an exact match
            const exactMatch = options.find((o) => matchWith(o.props.value, searchTerm));
            event.preventDefault();
            if (exactMatch) {
                setNewValue(exactMatch.props.value);
                closePanel();
            } else if (options.length) {
                // If no exact match exists, assume that the first option in the list is the one the user wants
                setNewValue(options[0].props.value);
                closePanel();
            }
        }

        filterConfig?.inputProps?.onKeyDown &&
            filterConfig?.inputProps?.onKeyDown(event as KeyboardEvent<HTMLInputElement>);
    };

    const portalTarget = document.getElementById(portalTargetId) ?? document.body;

    return (
        <div
            ref={wrapperRef}
            className={cx(
                'dsc-select__wrapper',
                css`
                    display: inline-block;
                `,
            )}
        >
            <Manager>
                <Reference>
                    {({ ref }) => (
                        // TODO (#8) Accessibility improvements for `Select` and `Option` component
                        <div
                            ref={ref}
                            className={cx(
                                'dsc-select',
                                getWrapperStyle(size),
                                css`
                                    border-color: ${open ? '#008F7B' : '#8090A2'};
                                `,
                                { 'dsc-select--disabled': isDisabled },
                                className,
                            )}
                            tabIndex={0}
                            onClick={() => {
                                if (open) {
                                    closePanel();
                                } else {
                                    setOpen(true);
                                }
                            }}
                            style={style}
                            role="listbox"
                        >
                            <span className={cx('dsc-select__value', valueStyle)}>
                                {displayValue ?? (
                                    <span className={cx('dsc-select__placeholder', placeholderStyle)}>
                                        {placeholder ?? '\u00A0'}
                                    </span>
                                )}
                            </span>
                            <Icon content={icons?.selectPanel?.icon ?? ARROW_DOWN.icon} size="extra-small" />
                        </div>
                    )}
                </Reference>
                {createPortal(
                    <Popper placement="bottom-start" innerRef={panelRef} modifiers={popperModifiers}>
                        {({ ref, style: popperStyle, placement }) =>
                            open && (
                                <FocusTrap focusTrapOptions={{ initialFocus: false }}>
                                    <div
                                        ref={ref}
                                        style={{ ...popperStyle, minWidth: wrapperRef.current?.clientWidth }}
                                        data-placement={placement}
                                        className={cx(
                                            'dsc-select__panel',
                                            panelStyle,
                                            css`
                                                color: ${cvar('primaryTextColor')};
                                                font-family: ${cvar('selectFontFamily')};
                                            `,
                                            selectPanelClassName,
                                        )}
                                        data-testid="select-panel"
                                    >
                                        {filterInput && (
                                            <div className={cx('dsc-select__search', searchStyle)}>
                                                <Input
                                                    {...filterConfig.inputProps}
                                                    // Force `type=text` here; number, date, etc types don't make sense for a search input
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    onKeyDown={handleInputKeyDown}
                                                    icons={{ toolTip }}
                                                />
                                            </div>
                                        )}
                                        <ul tabIndex={-1} className={listStyle} ref={listRef}>
                                            {options}
                                        </ul>
                                    </div>
                                </FocusTrap>
                            )
                        }
                    </Popper>,
                    portalTarget,
                )}
            </Manager>
        </div>
    );
}
