import React, { useContext, useReducer, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import { cx } from '@emotion/css';

import { useBreakpointInfo } from '@design-stack-ct/utility-react';

import { Button, Card, IconButton, Modal, Spinner } from '@dexter/dex-component-library';
import { Icon, IconSizes, useIcons } from '@dexter/dex-icon-library';

import ArrowIcon from './ArrowIcon';
import { ensembleColorSelectedStyle, ensembleColorsStyle, ensembleColorStyle } from './EnsemblesThumbnailCss';
import {
    applyButtonStyle,
    bodyStyle,
    cardStyle,
    dexCancelIconStyle,
    ensembleLoadingStyle,
    footerStyle,
    headerStyle,
    imagePreviewStyle,
    subTitleEnsembleStyle,
    templateContentStyle,
    titleContainerStyle,
    titleEnsembleStyle,
} from './ModalWrapperCss';
import { ENSEMBLE_GALLERY_PREFIX } from '../constants';
import { galleryContext } from '../provider';
import type { EnsemblesProps, ModalWrapperProps } from '../types';

export const NEXT = 'NEXT';
export const PREV = 'PREV';

type Direction = typeof PREV | typeof NEXT;
interface CarouselState {
    pos: number;
    sliding: boolean;
    dir: Direction;
}

type CarouselAction = { type: Direction; numItems: number } | { type: 'stopSliding' };

const reducer = (state: CarouselState, action: CarouselAction): CarouselState => {
    switch (action.type) {
        case PREV: {
            const newPos = state.pos === 0 ? 0 : state.pos - 1;

            return {
                ...state,
                dir: PREV,
                pos: newPos,
            };
        }
        case NEXT: {
            const newPos = state.pos === action.numItems - 1 ? action.numItems - 1 : state.pos + 1;

            return {
                ...state,
                dir: NEXT,
                pos: newPos,
            };
        }
        case 'stopSliding': {
            return { ...state, sliding: false };
        }
        default:
            return state;
    }
};

const getInitialState: CarouselState = { pos: 0, sliding: false, dir: NEXT };

export const ModalWrapper = ({
    showModal,
    onCancel,
    ensembles,
    selectedEnsembleId,
    proportion = 1,
    showLoader,
    setShowLoader,
    onClick,
    title,
    subTitle,
}: ModalWrapperProps) => {
    const { CLOSE_CIRCLE, ARROW_LEFT, ARROW_RIGHT } = useIcons();
    const { localization, applyModalButtonClick } = useContext(galleryContext);
    const [selectedEnsemble, setSelectedEnsemble] = useState(
        ensembles?.find((item) => item.ensembleId === selectedEnsembleId),
    );
    const bodyRef = useRef<HTMLDivElement>(null);
    const [showArrowLeft, setShowArrowLeft] = useState(false);
    const [showArrowRight, setShowArrowRight] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const { isSmallDevice } = useBreakpointInfo();

    const totalPreviews = selectedEnsemble?.designUrls?.length || 0;
    const showAllPreview = !isSmallDevice || (isSmallDevice && totalPreviews === 1);

    const [state, dispatch] = useReducer(reducer, getInitialState);

    const setArrowVisibility = (pos: number) => {
        if (pos === 0) {
            setShowArrowLeft(false);
            setShowArrowRight(true);
        } else {
            setShowArrowLeft(true);
            setShowArrowRight(false);
        }
    };

    const slide = (dir: Direction) => {
        if ((dir === PREV && state.pos !== 0) || (dir === NEXT && state.pos !== totalPreviews - 1)) {
            setShowLoader?.(true);
        }
        setArrowVisibility(reducer(state, { type: dir, numItems: totalPreviews }).pos);
        dispatch({ type: dir, numItems: totalPreviews });
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => slide(NEXT),
        onSwipedRight: () => slide(PREV),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true,
    });

    let loadedPreview = 0;

    let imageSize = '0';
    if (proportion < 0.5) {
        imageSize = '200px';
    } else if (proportion > 2) {
        imageSize = '100%';
    } else {
        imageSize = '350px';
    }
    const hasTitleOrSubTitle = title !== '' || subTitle !== '';

    const onClickEnsemble = (ensemble: EnsemblesProps) => {
        setSelectedEnsemble(ensembles?.find((item) => item.ensembleId === ensemble.ensembleId));
    };

    const onClickApplyButtonHandler = () => {
        if (applyModalButtonClick && selectedEnsemble && selectedEnsemble?.ensembleId) {
            const idValue = JSON.stringify({
                ensembleId: selectedEnsemble.ensembleId,
                ensembleLineId: '',
                colorDominance: selectedEnsemble.colorDominance,
                scenePreviewDefaultUrl: selectedEnsemble.scenePreviewDefaultUrl,
                defaultTemplateToken: selectedEnsemble.defaultTemplateToken,
            });
            applyModalButtonClick(idValue);
        }
    };

    const handleLoader = (item: EnsemblesProps) => {
        if (selectedEnsemble?.ensembleId === item.ensembleId && isLoaded) {
            setShowLoader?.(false);
        } else {
            setShowLoader?.(true);
            setIsLoaded(false);
        }
    };

    const increaseLoadedPreview = () => {
        loadedPreview += 1;
        if (bodyRef.current?.clientWidth && bodyRef.current?.clientHeight && loadedPreview === totalPreviews) {
            const images = bodyRef.current.getElementsByTagName('img');
            // 30px is the padding of the card, 20px is the gap between the images
            let newWidth = 30 + 30 + 20;
            for (const item of images) {
                newWidth += item.clientWidth;
            }
            bodyRef.current?.style.setProperty('width', `${newWidth}px`);
            bodyRef.current?.style.setProperty('height', `${bodyRef.current?.clientHeight}px`);
            setShowLoader?.(false);
            setIsLoaded(true);
        }
    };

    const ensembleThumbnail = selectedEnsemble?.designUrls.map((item) => (
        <img
            key={item.templateToken}
            alt=""
            className={cx(`${ENSEMBLE_GALLERY_PREFIX}-modal-preview`, imagePreviewStyle(imageSize, isSmallDevice))}
            onLoad={() => increaseLoadedPreview()}
            src={item.scenePreviewUrl}
            onClick={onClick}
        />
    ));

    const iconButtonContent = (
        <IconButton
            className={cx('dex-cancel-icon', dexCancelIconStyle(isSmallDevice))}
            size={IconSizes.Small}
            onClick={() => onCancel(selectedEnsemble)}
            isHoverEffectNeeded={false}
        >
            <Icon content={CLOSE_CIRCLE.icon} />
        </IconButton>
    );

    const modalContent = (
        <Modal isShown={showModal} onCancel={() => onCancel(selectedEnsemble)}>
            <Card className={cardStyle(isSmallDevice)}>
                {isSmallDevice && <header className={headerStyle}>{iconButtonContent}</header>}
                <div className={cx('dex-template-cnt', templateContentStyle(isSmallDevice))}>
                    {hasTitleOrSubTitle && (
                        <div className={cx(`${ENSEMBLE_GALLERY_PREFIX}-modal-title-cnt`, titleContainerStyle)}>
                            {title !== '' && (
                                <div
                                    className={cx(
                                        `${ENSEMBLE_GALLERY_PREFIX}-modal-title`,
                                        titleEnsembleStyle(isSmallDevice),
                                    )}
                                >
                                    {title}
                                </div>
                            )}
                            {subTitle !== '' && (
                                <div
                                    className={cx(
                                        `${ENSEMBLE_GALLERY_PREFIX}-modal-subtitle`,
                                        subTitleEnsembleStyle(isSmallDevice),
                                    )}
                                >
                                    {subTitle}
                                </div>
                            )}
                        </div>
                    )}
                    {!isSmallDevice && iconButtonContent}
                    <div ref={bodyRef} className={bodyStyle(proportion, isSmallDevice)}>
                        {showLoader && (
                            <div className={ensembleLoadingStyle(false)}>
                                <Spinner size="38px" thickness={3} />
                            </div>
                        )}
                        {selectedEnsemble?.designUrls && showAllPreview && ensembleThumbnail}
                        {selectedEnsemble?.designUrls && !showAllPreview && (
                            <div {...handlers}>
                                {showArrowLeft && (
                                    <ArrowIcon
                                        className="dex-gallery-arrow dex-gallery-arrow-left"
                                        icon={ARROW_LEFT}
                                        onClick={() => {
                                            slide(PREV);
                                            setShowLoader?.(true);
                                        }}
                                    />
                                )}
                                <img
                                    className={cx(
                                        `${ENSEMBLE_GALLERY_PREFIX}-modal-preview`,
                                        imagePreviewStyle(imageSize, isSmallDevice),
                                    )}
                                    key={selectedEnsemble.designUrls[state.pos].scenePreviewUrl}
                                    alt=""
                                    onLoad={() => {
                                        setShowLoader?.(false);
                                        setIsLoaded(true);
                                    }}
                                    src={selectedEnsemble.designUrls[state.pos].scenePreviewUrl}
                                    onClick={onClick}
                                />
                                {showArrowRight && (
                                    <ArrowIcon
                                        className="dex-gallery-arrow dex-gallery-arrow-right"
                                        icon={ARROW_RIGHT}
                                        onClick={() => {
                                            slide(NEXT);
                                            setShowLoader?.(true);
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <footer className={footerStyle(isSmallDevice)}>
                        <div className={cx(`${ENSEMBLE_GALLERY_PREFIX}-modal-colors`, ensembleColorsStyle(12))}>
                            {ensembles?.map((item) => (
                                <div
                                    className={cx(`${ENSEMBLE_GALLERY_PREFIX}-modal-color`, ensembleColorStyle(true), {
                                        [ensembleColorSelectedStyle]: item.ensembleId === selectedEnsemble?.ensembleId,
                                    })}
                                    key={item.ensembleId}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleLoader(item);
                                        onClickEnsemble(item);
                                    }}
                                >
                                    <div style={{ backgroundColor: `#${item.colorDominance}` }}></div>
                                </div>
                            ))}
                        </div>
                        <Button
                            className={cx(`${ENSEMBLE_GALLERY_PREFIX}-modal-apply`, applyButtonStyle)}
                            onClick={() => onClickApplyButtonHandler()}
                        >
                            {localization.applyEnsembleModalButton}
                        </Button>
                    </footer>
                </div>
            </Card>
        </Modal>
    );

    return (
        <div
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            {modalContent}
        </div>
    );
};
