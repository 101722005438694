import React from 'react';
import { createPortal } from 'react-dom';

import { css, cx } from '@emotion/css';

export const portalTargetId = 'dsc-portal-target';

const portalTargetStyle = css`
    position: relative;
`;

export interface PortalTargetProps {
    className?: string;
}

export function PortalTarget({ className }: PortalTargetProps) {
    const portalTarget = (
        <div
            id={portalTargetId}
            className={cx('dsc-portal-target', portalTargetStyle, className)}
            data-testid="dsc-portal-target"
        />
    );

    return createPortal(portalTarget, document.body);
}
