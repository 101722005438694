import React from 'react';

// TODO: fix the types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function GalleryChild({ item }: any) {
    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                margin: '0 auto',
                width: '440px',
                height: '650px',
                overflow: 'scroll',
                border: '1px solid #bfbfbf',
            }}
        >
            {item.depositPhoto.result.images.map(
                // TODO: Remove any
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (image: any) => (
                    <div key={image.id}>
                        {/* <span>{i.title}</span> */}
                        <img width="110" height="110" src={image.thumbSmall} alt="" />
                    </div>
                ),
            )}
        </div>
    );
}
