import type { Localization } from '@dexter/gallery';

export const frFR: Localization = {
    pagesCountLabel: 'pages',
    pageCountLabel: 'page',
    backButton: 'Back',
    allImages: 'Toutes les images',
    processingOverlay: '',
    applyEnsembleModalButton: 'Choisir ce design',
};
