import type { Dispatch, SetStateAction } from 'react';
import React, { useEffect, useId, useState } from 'react';

import { cx } from '@emotion/css';

import { IconButton, Spinner } from '@dexter/dex-component-library';
import { Icon,useIcons } from '@dexter/dex-icon-library';

import {
    backContainerStyle,
    elementFixedStyle,
    ellipsisStyle,
    iconStyle,
    imageContainerStyle,
    processingTextStyle,
} from './GalleryCss';
import { ImageThumbnail } from './ImageThumbnail';
import { getInfoFromImage, isMultiPageAsset, isPercentProgress } from '../helpers/utils';
import type { Localization } from '../localization';
import { enUS } from '../localization';
import type { Images, InfoProps, MultiPageAsset, ThumbnailButtonConfig } from '../types';

interface ImageProps {
    images: Images[];
    scrollContainerRef: React.RefObject<HTMLDivElement>;
    backAllAssetRef: React.RefObject<HTMLDivElement>;
    imagesLoaded: Dispatch<SetStateAction<boolean>>;
    onRemove: (param: string) => void;
    onAdd: (param: string, pageNumber: number) => void;
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icons: any;
    showInfo: boolean;
    localization: Localization;
    clickedAssetId?: string;
    setClickedAssetId?: (assetId: string) => void;
}

const renderImage = (
    image: Images,
    pageNumber: number,
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icons: any,
    info: InfoProps,
    key: string,
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onAdd: any,
    clickedAssetId?: string,
    setClickedAssetId?: (assetId: string) => void,
) => (
    <div className={cx('dex-gallery-containerElement', elementFixedStyle)} key={key}>
        <div
            className="dex-gallery-image"
            style={{
                backgroundImage: image.previewUrl ? `url('${image.previewUrl}')` : undefined,
            }}
        >
            {icons && (
                <ImageThumbnail
                    id={image.id}
                    pageNumber={pageNumber}
                    icons={icons}
                    info={info}
                    onAdd={onAdd}
                    setClickedAssetId={setClickedAssetId}
                    clickedAssetId={clickedAssetId}
                />
            )}
        </div>
    </div>
);

export const ImageContentFixed = ({
    images,
    scrollContainerRef,
    backAllAssetRef,
    imagesLoaded,
    icons,
    onRemove,
    onAdd,
    showInfo,
    localization = enUS,
    setClickedAssetId,
    clickedAssetId,
}: ImageProps) => {
    const [selectedAsset, setSelectedAsset] = useState<MultiPageAsset | null>(null);
    const [objectToCount, setObjectToCount] = useState(0);
    const { allImages, pagesCountLabel, pageCountLabel, processingOverlay } = localization;
    const idLoading = useId();
    const { FOLDER, ARROW_LEFT, PIXART_ADD, PIXART_DELETE_EMPTY } = useIcons();
    const iconsMultiPageItem: ThumbnailButtonConfig[] = [
        {
            icon: PIXART_ADD,
            position: 'BOTTOM_RIGHT',
            action: onAdd,
        },
    ];
    const iconsMultiPageList: ThumbnailButtonConfig[] = [
        {
            icon: PIXART_DELETE_EMPTY,
            position: 'BOTTOM_LEFT',
            action: onRemove,
        },
    ];
    const backIcon = { icon: ARROW_LEFT };
    const showAssetPages = (assetId: string) => {
        const asset = images.find((image) => image.id === assetId);
        if (isMultiPageAsset(asset)) {
            setSelectedAsset(asset);
            imagesLoaded(true);
        }
    };
    const showAllAssets = () => {
        setSelectedAsset(null);
        imagesLoaded(false);
    };
    useEffect(() => {
        if (objectToCount < 0 && !selectedAsset) {
            imagesLoaded(true);
        }
    }, [objectToCount, imagesLoaded, selectedAsset]);
    const numberElement = document.getElementsByClassName('dexcore-containerElement').length;
    useEffect(() => {
        let objectCount = 0;
        if (selectedAsset) {
            objectCount = selectedAsset.pages.length;
        } else if (numberElement > 0) {
            objectCount = images.length - numberElement;
        } else {
            objectCount = images.length;
        }
        setObjectToCount(objectCount);
    }, [images.length, numberElement, selectedAsset]);

    if (Array.isArray(selectedAsset?.pages)) {
        return (
            <>
                <div ref={backAllAssetRef} className={cx('dex-gallery-back-container', backContainerStyle)}>
                    <IconButton onClick={showAllAssets} size="12px">
                        <Icon content={backIcon.icon.icon} />
                    </IconButton>{' '}
                    {allImages}
                </div>
                <article className={cx('dex-gallery-image l', imageContainerStyle())} ref={scrollContainerRef}>
                    {selectedAsset?.pages.map((image: Images) => {
                        const key = `${image.id}_${image.pageNumber}`;
                        let info;
                        if (showInfo) {
                            info = getInfoFromImage(image);
                        }
                        return renderImage(
                            image,
                            image.pageNumber || 1,
                            iconsMultiPageItem,
                            info,
                            key,
                            onAdd,
                            clickedAssetId,
                            setClickedAssetId,
                        );
                    })}
                </article>
            </>
        );
    }

    return (
        <article className={cx('dex-gallery-image m', imageContainerStyle())} ref={scrollContainerRef}>
            {images?.map((image: Images) => {
                const { loadingState } = image;
                const isLoading = isPercentProgress(loadingState) || loadingState === 'processing';
                if (isLoading) {
                    return (
                        <div key={idLoading} className={cx('dex-gallery-loadingElement', elementFixedStyle)}>
                            <Spinner
                                size="38px"
                                thickness={3}
                                label={isPercentProgress(loadingState) ? `${loadingState.percentage}%` : ''}
                            />
                            {loadingState === 'processing' && (
                                <span className={processingTextStyle}>{processingOverlay}</span>
                            )}
                        </div>
                    );
                }
                if (image.previewUrl) {
                    let info;
                    if (showInfo) {
                        info = getInfoFromImage(image);
                    }
                    let contentElement = renderImage(
                        image,
                        1,
                        icons,
                        info,
                        image.id,
                        onAdd,
                        clickedAssetId,
                        setClickedAssetId,
                    );
                    if (isMultiPageAsset(image)) {
                        contentElement = (
                            <div
                                className={cx('dex-gallery-containerElement', elementFixedStyle)}
                                onClick={() => showAssetPages(image.id)}
                                key={image.id}
                            >
                                <div className="dex-gallery-folder">
                                    <Icon content={FOLDER.icon} size="medium" className={iconStyle} />
                                    <div className={ellipsisStyle}>{image.fileName}</div>
                                    <div className={ellipsisStyle}>
                                        ({image.pages.length}{' '}
                                        {image.pages.length === 1 ? pageCountLabel : pagesCountLabel})
                                    </div>
                                    {icons && (
                                        <ImageThumbnail
                                            id={image.id}
                                            icons={iconsMultiPageList}
                                            info={info}
                                            onAdd={onAdd}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    }

                    return contentElement;
                }
                return <div key={image.id}></div>;
            })}
        </article>
    );
};
