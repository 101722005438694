import type { Localization } from '@dexter/gallery';

export const itIT: Localization = {
    pagesCountLabel: 'Pagine',
    pageCountLabel: 'Pagina',
    backButton: 'Indietro',
    allImages: 'Tutte le immagini',
    processingOverlay: 'Processando...',
    applyEnsembleModalButton: 'Scegli questo design',
};
