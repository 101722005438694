import type { ReactNode } from 'react';
import React, { Fragment, useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { Preview } from 'react-dnd-multi-backend';

import { css, cx } from '@emotion/css';

// import type { ElementDimensions } from '@design-stack-ct/utility-react';
import { useEventCallback } from '@design-stack-ct/utility-react';

import type { draggableItemTypes } from './draggableItemTypes';
import type { DragItem } from './types';
import type { ElementDimensions } from '../utility/useElementDimensions';
import { useElementDimensions } from '../utility/useElementDimensions';

interface DraggableProps {
    children: ReactNode | ReactNode[];
    id: string;
    dragContentType: draggableItemTypes;
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dragData?: Record<string, any>;
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    touchDragStyle?: Record<string, any>;
    enabled?: boolean;
    onIsDragging?: (isDragging: boolean) => void;
    className?: string;
}

const getDraggableImageStyle = (enabled: boolean) => css`
    cursor: ${enabled ? 'pointer' : 'default'};
    transform: translateZ(0); /* prevents other elements from being captured on a drag ghost screenshot */
`;

const dragStyle = css`
    opacity: 0.5;
    cursor: grab;
`;

/*
 * This preview will only be used in browsers that do not support HTML5 Drag and Drop.
 *
 * Preview clipping will happen on iOS < 14.3
 * https://bugs.webkit.org/show_bug.cgi?id=160953
 */
const generatePreview = (
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dragItem: any,
    draggableDimensions: ElementDimensions,
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    touchDragStyle?: Record<string, any>,
) => (
    <div
        style={{
            ...dragItem.style,
            ...touchDragStyle,
            width: `${draggableDimensions.width}px`,
            height: `${draggableDimensions.height}px`,
        }}
    >
        {dragItem.item.element}
    </div>
);

export function Draggable({
    children,
    id,
    dragContentType,
    dragData,
    enabled = true,
    onIsDragging,
    touchDragStyle,
    className,
    ...props
}: DraggableProps) {
    const draggableRef = useRef<HTMLDivElement | null>(null);
    const draggableDimensions = useElementDimensions(draggableRef);

    const [{ isDragging }, connectDragSource, connectDragPreview] = useDrag({
        item: { type: dragContentType, itemID: id, element: children, dragData } as DragItem,
        // TODO: Remove any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        collect: (monitor: any) => ({
            isDragging: !!monitor.isDragging(),
        }),
        canDrag: () => enabled,
    });

    const handleOnIsDragging = useEventCallback(
        (_isDragging: boolean) => {
            if (!onIsDragging) return;
            onIsDragging(_isDragging);
        },
        [onIsDragging],
    );

    useEffect(() => {
        handleOnIsDragging(isDragging);
    }, [handleOnIsDragging, isDragging]);

    return (
        <Fragment>
            <div
                ref={(ref) => {
                    draggableRef.current = ref;
                    connectDragSource(ref);
                    connectDragPreview(ref, { captureDraggingState: true });
                }}
                className={cx(
                    'dsc-draggable',
                    getDraggableImageStyle(enabled),
                    {
                        [dragStyle]: isDragging,
                        'dsc-draggable--dragging': isDragging,
                    },
                    className,
                )}
                {...props}
            >
                {children}
            </div>
            <Preview generator={(dragItem) => generatePreview(dragItem, draggableDimensions, touchDragStyle)} />
        </Fragment>
    );
}
