import type { ImgHTMLAttributes, MouseEvent } from 'react';
import React, { useContext, useState } from 'react';
import type { PhotoLayout } from 'react-photo-album';

import { useBreakpointInfo } from '@design-stack-ct/utility-react';

import { Spinner } from '@dexter/dex-component-library';

import { ImageThumbnail } from './ImageThumbnail';
import { ensembleLoadingStyle } from './ModalWrapperCss';
import { isMultiPageAsset } from '../helpers/utils';
import { galleryContext } from '../provider';
import type { Asset, EnsemblesProps, ThumbnailButtonConfig } from '../types';

interface ImageThumbnailWrapperProps {
    imageProps: ImgHTMLAttributes<HTMLImageElement>;
    imageType?: string;
    info?: string[];
    icons?: ThumbnailButtonConfig[];
    images: Asset[];
    pageNumber?: number;
    proportion?: number;
    addIconObject: ThumbnailButtonConfig[];
    asset?: Asset;
    photo: {
        src: string;
        width: number;
        height: number;
        key: string;
        ensembles?: EnsemblesProps[];
        title?: string;
        subTitle?: string;
    };
    photoLayout: PhotoLayout;
    onAdd?: (param: string, pageNumber: number) => void;
    isMultiPageAsset?: boolean;
    setClickedAssetId?: (isAssetTypeMultiPage: string) => void;
    clickedAssetId?: string;
    setClickedPageNumber?: (pageNumber: number) => void;
    clickedPageNumber?: number;
    clickedTemplateId?: string;
    setClickedTemplateId?: (id: string) => void;
    showThumbnailLoader?: boolean;
    titleName?: string;
    isOverlayNeeded?: boolean;
}

export const ImageThumbnailWrapper = ({
    imageProps,
    imageType,
    info,
    icons,
    images,
    pageNumber,
    proportion = 1,
    addIconObject,
    asset,
    photo,
    photoLayout,
    onAdd,
    setClickedAssetId,
    clickedAssetId,
    setClickedPageNumber,
    clickedPageNumber,
    setClickedTemplateId,
    clickedTemplateId,
    showThumbnailLoader = true,
    isOverlayNeeded = true,
}: ImageThumbnailWrapperProps) => {
    const { alt, style, src, ...restImageProps } = imageProps;
    const [sourceImg, setSourceImg] = useState(src);
    const { onSelectEnsemble } = useContext(galleryContext);
    const { ensembles, title, subTitle } = photo;
    const { isSmallDevice } = useBreakpointInfo();
    // To show the loader on the grey area
    const [showLoader, setShowLoader] = useState(showThumbnailLoader);
    const [isLoaded, setIsLoaded] = useState(false);

    const onEnsembleClick = (ensemble: EnsemblesProps) => {
        setSourceImg(ensemble.scenePreviewDefaultUrl);
        if (onSelectEnsemble) {
            onSelectEnsemble(ensemble);
        }
    };

    const handleClick = (event: MouseEvent) => {
        const element = event.currentTarget as Element;
        const elementTarget = element?.lastChild;
        const elementValue = elementTarget as HTMLElement;
        if (elementValue)
            elementValue.style.opacity =
                elementValue.style.opacity === '0' || elementValue.style.opacity === '' ? '1' : '0';
        if (clickedTemplateId) {
            const value = document.getElementById(clickedTemplateId || '')?.lastElementChild as HTMLElement;
            if (value) {
                value.style.opacity = '0';
            }
        }
    };

    const content = (
        <>
            <div className={ensembleLoadingStyle(isOverlayNeeded)}>
                {showLoader && <Spinner size="38px" thickness={3} />}
            </div>
            <img
                alt={alt}
                style={{ ...style, width: '100%', padding: 0, borderRadius: '5px' }}
                onLoad={() => {
                    setShowLoader(false);
                    setIsLoaded(true);
                }}
                src={sourceImg || src}
                {...restImageProps}
            />
            {imageType !== 'multiPage' && icons && (
                <ImageThumbnail
                    id={images[photoLayout.index].id}
                    pageNumber={1}
                    icons={icons}
                    info={info}
                    title={title}
                    subTitle={subTitle}
                    proportion={proportion}
                    ensembles={ensembles}
                    onEnsembleClick={onEnsembleClick}
                    onAdd={onAdd}
                    setClickedAssetId={setClickedAssetId}
                    clickedAssetId={clickedAssetId}
                    clickedTemplateId={clickedTemplateId}
                    setClickedTemplateId={setClickedTemplateId}
                    setShowLoader={setShowLoader}
                    isLoaded={isLoaded}
                    isOverlayNeeded={isOverlayNeeded}
                />
            )}

            {imageType === 'multiPage' && icons && isMultiPageAsset(asset) && (
                <ImageThumbnail
                    {...{ id: asset.id, pageNumber, icons: addIconObject }}
                    onAdd={onAdd}
                    setClickedAssetId={setClickedAssetId}
                    setClickedPageNumber={setClickedPageNumber}
                    clickedPageNumber={clickedPageNumber}
                />
            )}
        </>
    );

    if (ensembles)
        return (
            <div
                onClick={(event) => {
                    event.preventDefault();
                    if (isSmallDevice) {
                        handleClick(event);
                        if (clickedTemplateId !== images[photoLayout.index].id) {
                            setClickedTemplateId?.(images[photoLayout.index].id);
                        } else {
                            setClickedTemplateId?.('');
                        }
                    }
                }}
                id={asset?.id}
            >
                {content}
            </div>
        );

    return content;
};
