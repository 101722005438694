export const HORIZONTAL = 'horizontal';
export const VERTICAL = 'vertical';
export const DYNAMIC = 'dynamic';
export const FIXED = 'fixed';
export const GALLERY_CONTAINER_HEIGHT_DESKTOP = 100;
export const GALLERY_CONTAINER_HEIGHT_MOBILE = 80;
export const TIME_LIMIT = 500;
export const ENSEMBLE_COLOR_GAP_SIZE = 8;
export const ENSEMBLE_GALLERY_PREFIX = 'dex-gallery-ensemble';
export const MIN_GALLERY_PHOTO = 1;
export const MAX_GALLERY_PHOTO = 2;
