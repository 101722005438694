import axios from 'axios';
import { useEffect, useRef,useState } from 'react';

import {
    ERROR_CODE_101,
    ERROR_STATUS,
    GENERAL_ERROR_MSG,
    MAX_NUMBER_OF_TRY,
    PRODUCTION,
    REQUESTED,
    SUCCESS_CODE_200,
    SUCCESS_STATUS,
    TEXT_TO_IMAGE_DEV_API,
    TEXT_TO_IMAGE_PRD_API,
} from '../CONSTANT';
import { getEnvironment,getImageIdFromImageUrl } from '../helpers/commonHelpers';
import type { ImageResultProps, ImageResultUrlProp, ResultProps, SearchDataProps } from '../types';

const checkImageAvailability = async (image: ImageResultUrlProp, currentCount: number): Promise<boolean> => {
    try {
        const headResponse = await fetch(image.preview, { method: 'HEAD' });

        if (headResponse.status === 200) {
            return true; // Image is available
        }

        if (currentCount <= MAX_NUMBER_OF_TRY) {
            // Retry after a 1 second delay
            await new Promise((resolve) => {
                setTimeout(resolve, 1000);
            });

            return checkImageAvailability(image, currentCount + 1);
        }

        return false;
    } catch (error) {
        // Retry after a 1 second delay
        await new Promise((resolve, reject) => {
            reject(new Error('Max number to try exhausted or some other error occurred'));
        });

        return false;
    }
};

export const useTextToImage = (searchData: SearchDataProps, callTextToImageService = true, accessToken = '') => {
    const [result, setResult] = useState<ResultProps | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const etaIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const url = getEnvironment() !== PRODUCTION ? TEXT_TO_IMAGE_DEV_API : TEXT_TO_IMAGE_PRD_API;

    /**
     * TODO : We need to revisit the cancel logic and implement it correctly in axios. for the time being we remove the logic
     */

    useEffect(() => {
        if (callTextToImageService) {
            // Perform the POST request to get initial processing data
            setLoading(true);
            setResult({
                status: REQUESTED,
            });

            // Create a cancel token source
            const cancel = axios.CancelToken.source();

            axios({
                method: 'POST',
                url,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: accessToken,
                },
                data: JSON.stringify({
                    prompt: searchData.searchQuery,
                    format: searchData.format,
                    samples: searchData.noOfSampleImages,
                }),
                cancelToken: cancel.token,
            })
                // TODO: Remove any
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((postResponse: any) => {
                    if (postResponse?.status === 201 && postResponse?.data) {
                        let remainingEta = Math.round(postResponse.data.data.eta) || 0;

                        etaIntervalRef.current = setInterval(() => {
                            if (remainingEta <= 0) {
                                clearInterval(etaIntervalRef.current!); // stop decrementing when eta is zero

                                const imageResults: ImageResultUrlProp[] = postResponse?.data?.data?.images || [];

                                // Using promise to do a check for all the images parallel
                                const promises: Promise<ImageResultUrlProp | null>[] = imageResults.map(
                                    async (image) => {
                                        const available = await checkImageAvailability(image, 1);
                                        return available ? image : null;
                                    },
                                );

                                // Using promise all to resolve the results
                                /**
                                 * With Promise.all, we are failing fast because even if one request fails we will not get the result
                                 * For the moment we are going ahead with this approach as we are waiting for 4 images anyways and not showing a single image when it is ready
                                 * To be more accurate and show the images that were being loaded successfully, we need to change the loader display logic anyways
                                 * TODO: Show the images as and when they are ready and try to use the Promise.allSettled
                                 * We can look for other alternative ways as well
                                 */
                                Promise.all(promises)
                                    .then((results) => {
                                        const filteredResults = results.filter((image) => image !== null);

                                        setLoading(false);

                                        if (filteredResults.length <= 0) {
                                            setResult({
                                                statusCode: ERROR_CODE_101,
                                                status: ERROR_STATUS,
                                                errorMsg: GENERAL_ERROR_MSG,
                                                data: [],
                                            });
                                        } else {
                                            const newTransformedData: ImageResultProps[] = filteredResults.map(
                                                (image: ImageResultUrlProp | null) => ({
                                                    id: getImageIdFromImageUrl(image?.original),
                                                    original: image?.original,
                                                    preview: image?.preview,
                                                }),
                                            );

                                            setResult({
                                                statusCode: SUCCESS_CODE_200,
                                                status: SUCCESS_STATUS,
                                                errorMsg: '',
                                                data: newTransformedData || [],
                                            });
                                        }
                                    })
                                    .catch((reason) => {
                                        // eslint-disable-next-line no-console
                                        console.error(reason);

                                        setLoading(false);
                                        setResult({
                                            statusCode: ERROR_CODE_101,
                                            status: ERROR_STATUS,
                                            errorMsg: GENERAL_ERROR_MSG,
                                            data: [],
                                        });
                                    });
                            } else {
                                remainingEta -= 1; // decrement eta by 1 second
                            }
                        }, 1000); // Update eta every 1 second, just to match with actual scenario
                    } else {
                        // We need to throw this error otherwise the loader will load indefinitely
                        throw new Error('There maybe some issue with response data');
                    }
                })
                // TODO: Remove any
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((error: any) => {
                    // General error message from api
                    if (axios.isCancel(error)) {
                        // Request was canceled
                        setLoading(false);
                        setResult({
                            statusCode: SUCCESS_CODE_200,
                            status: SUCCESS_STATUS,
                            errorMsg: '',
                            data: [],
                        });
                    } else {
                        setLoading(false);
                        setResult({
                            statusCode: ERROR_CODE_101,
                            status: ERROR_STATUS,
                            errorMsg: GENERAL_ERROR_MSG,
                            data: [],
                        });
                    }
                });
            return () => cancel.cancel();
        }
        return () => {};
    }, [searchData, callTextToImageService, url, accessToken]);

    return { loading, result };
};
