import React, { useState } from 'react';

import { css } from '@emotion/css';

import { Button, Checkbox, CheckboxSize, H2, Input } from '@dexter/dex-component-library';
import type { PhotoStoreConfigProps as PsConfigProps } from '@dexter/photo-store';

import type { PhotoStoreConfigProps } from './config/photoStoreConfig';
import photoStoreConfig from './config/photoStoreConfig';
import { transformData } from './helpers';
import PhotoStore from './PhotoStore';

const PhotoStoreFormRoot = css`
    margin: 30px;
    width: 400px;
`;

const rowStyle = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .dsc-select,
    .dsc-input {
        width: 275px;
    }
    textarea {
        width: 268px;
    }
`;

export default function PhotoStoreForm() {
    const [photoStore, setPhotoStore] = useState<PhotoStoreConfigProps[]>(photoStoreConfig);
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [finalFormData, setFinalFormData] = useState<PsConfigProps>({});

    const setSearchData = (value: string) => {
        setSearch(value);
    };
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleDataChange = (value: any, data: PhotoStoreConfigProps, key: string) => {
        const updatedData = photoStore.map((item) => {
            if (data.provider === item.provider) {
                const finalObj = { ...data };
                if (key === 'category' || key === 'enable') {
                    finalObj[key] = !data[key];
                }
                if (key === 'limit') {
                    finalObj[key] = value ? parseInt(value, 10) : 0;
                }
                return finalObj;
            }
            return item;
        });
        setPhotoStore(updatedData);
    };

    const handleSearch = () => {
        const finalData = transformData(photoStore);
        finalData.keywords = search;
        setFinalFormData(finalData);
        setShowPreview(true);
    };

    return (
        <div style={{ display: 'flex', margin: '50px', gridGap: '200px', justifyContent: 'space-between' }}>
            <section className={PhotoStoreFormRoot}>
                <h1>Photo Store Form</h1>
                <div className={rowStyle}>
                    <span>Search</span>
                    <Input
                        key="search"
                        type="text"
                        value={search}
                        onChange={(value: string) => setSearchData(value)}
                        placeholder="ex: nature"
                        disabled={false}
                    />
                </div>
                {photoStore.map((item: PhotoStoreConfigProps, index) => (
                    <div key={`line-${index}`} style={{ display: 'flex', gridGap: '5px', flexDirection: 'column' }}>
                        <H2>{item.provider}</H2>
                        {/* <div className={rowStyle}>
                            Source:
                            <Select
                                size={SelectSize.Small}
                                value={photoStore[index].provider}
                                isDisabled={!photoStore[index].enable}
                                onChange={(value) => handleDataChange(value, photoStore[index].provider)}
                            >
                                {photoStore.map((variant, i) => (
                                    <Option key={i} value={variant.provider}>
                                        {variant.provider}
                                    </Option>
                                ))}
                            </Select>
                        </div> */}
                        <div className={rowStyle}>
                            <span>Search Limit</span>
                            <Input
                                key={`input-${index}`}
                                type="text"
                                value={photoStore[index].limit}
                                onChange={(value: string) => handleDataChange(value, photoStore[index], 'limit')}
                                placeholder="default: 100"
                                disabled={!photoStore[index].enable}
                            />
                        </div>
                        <div className={rowStyle}>
                            <Checkbox
                                key={`checkbox-${index}`}
                                size={CheckboxSize.Small}
                                checked={photoStore[index].category || false}
                                onToggle={() => handleDataChange('', photoStore[index], 'category')}
                                label="category"
                            />
                        </div>
                        <div className={rowStyle}>
                            <Checkbox
                                key={`checkbox-${index}`}
                                size={CheckboxSize.Small}
                                checked={photoStore[index].enable || false}
                                onToggle={() => handleDataChange('', photoStore[index], 'enable')}
                                label="enable"
                            />
                        </div>
                    </div>
                ))}
                <Button style={{ marginTop: '10px' }} onClick={handleSearch}>
                    Search
                </Button>
            </section>
            {showPreview && <PhotoStore {...finalFormData} />}
        </div>
    );
}
