import React, { useCallback } from 'react';

import { cx } from '@emotion/css';

import { useBreakpointInfo } from '@design-stack-ct/utility-react';
import type { VortexView } from '@rendering/lookup';
import type { FusionPreviewProps, VortexAction } from '@rendering/product-preview-react';
import { FusionPreview } from '@rendering/product-preview-react';

import { Icon, useIcons } from '@dexter/dex-icon-library';

import { VortexActionsHandler } from './VortexActionsHandler';
import { VortexError } from './VortexError';
import { VortexModelLoading } from './VortexModelLoading';
import { VortexPreviewActions } from './VortexPreviewActions';
import {
    actionStyle,
    preview360,
    previewBoxStyle,
    vortex3DStyle,
    vortexOuterContainerStyle,
    vortexStyle,
} from './VortexPreviewCss';
import { BACK, FRONT, PREFIX_CLASS, SMALL } from '../../constants';
import { useVortexOptions } from '../../hooks/useVortexOptions';
import { useCimDoc, usePreviewConfiguration } from '../../providers';
import type { sceneTypeProps } from '../../types';
import { DelayedLoadingSpinner } from '../common';

interface VortexPreviewProps {
    type: sceneTypeProps;
    vortexViews: VortexView[];
    activeButton?: string;
    width?: string;
    height?: string;
    handlePreviewButtonClick?: (buttonId?: string) => void;
}

export const VortexPreview = ({
    type,
    vortexViews,
    activeButton,
    width = '100%',
    height = '100%',
    handlePreviewButtonClick,
}: VortexPreviewProps) => {
    const { user, renderOptions } = useVortexOptions();
    const { PIXART_360 } = useIcons();
    const { vortexConfiguration, previewSettings } = usePreviewConfiguration();
    const { isSmallDevice } = useBreakpointInfo();
    const { cimDoc } = useCimDoc();

    const dimensionThumbnail = isSmallDevice ? '72px' : '88px';

    const changeActionsOrder = useCallback((actions: VortexAction[]) => {
        const backIndex = actions.findIndex((item) => item.id === BACK);

        if (backIndex !== -1) {
            const backObject = actions.splice(backIndex, 1)[0];
            const frontIndex = actions.findIndex((item) => item.id === FRONT);

            if (frontIndex !== -1) {
                actions.splice(frontIndex + 1, 0, backObject);
            }
        }

        return actions;
    }, []);

    if (previewSettings?.vortex && vortexViews.length > 0 && type === SMALL) {
        return (
            <div
                className={cx(
                    `${PREFIX_CLASS}-thumbnail`,
                    `${PREFIX_CLASS}-thumbnail-360`,
                    activeButton === '360' ? 'active' : '',
                    previewBoxStyle(dimensionThumbnail),
                    preview360,
                )}
                onClick={() => handlePreviewButtonClick?.('360')}
            >
                <Icon size="extra-large" content={PIXART_360.icon} />
            </div>
        );
    }

    return (
        previewSettings?.vortex &&
        vortexViews.length > 0 && (
            <FusionPreview
                user={user}
                cimDoc={cimDoc as FusionPreviewProps['cimDoc']}
                key={`${type}_360`}
                view={vortexViews[0]}
                renderOptions={renderOptions}
            >
                {({ Canvas, renderActions, vortexContext, status }) => {
                    if (status.error) {
                        return (
                            <VortexError
                                showErrorMessage={vortexConfiguration?.error.showErrorMessage}
                                errorMessage={vortexConfiguration?.error.errorMessage}
                                errorStyleWidth={width}
                                errorStyleHeight={height}
                            />
                        );
                    }
                    const loadingModel = status.loading && !status.error;

                    return (
                        <div className={cx(`${PREFIX_CLASS}-vortex`, vortexOuterContainerStyle(width, height))}>
                            <div
                                className={cx(
                                    `${PREFIX_CLASS}-vortex-canvas`,
                                    vortexStyle(width, height, 'grab', true),
                                    vortex3DStyle(vortexConfiguration?.vortexActions ? 40 : 0),
                                )}
                            >
                                <DelayedLoadingSpinner
                                    loader={loadingModel}
                                    content={vortexConfiguration?.loader}
                                    className={`${PREFIX_CLASS}-loader`}
                                />
                                <Canvas key="3dCanvas" />
                            </div>
                            {vortexConfiguration?.vortexActions && (
                                <div className={cx(`${PREFIX_CLASS}-action`, actionStyle(width))}>
                                    {renderActions &&
                                        renderActions(({ actions }) => {
                                            changeActionsOrder(actions);
                                            return <VortexPreviewActions actions={actions} />;
                                        })}
                                </div>
                            )}
                            <VortexActionsHandler preview={vortexContext?.preview} />
                            <VortexModelLoading loading={loadingModel} />
                        </div>
                    );
                }}
            </FusionPreview>
        )
    );
};
