import type { CSSProperties,ReactNode } from 'react';
import React, { useEffect,useRef } from 'react';

import { css,cx } from '@emotion/css';

import { Icon,useIcons } from '@dexter/dex-icon-library';

import { getOptionSizeInPixels, SelectSize } from './common';
import { cvar } from '../theme';
import type { IconsProp } from '../types';

const getOptionStyle = (size: SelectSize) => css`
    display: flex;
    align-items: center;
    height: ${getOptionSizeInPixels(size)};
    min-width: fit-content;
    line-height: 20px;
    font-size: 14px;
    font-family: ${cvar('selectFontFamily')};
    user-select: none;
    padding: 0 16px;
    cursor: pointer;

    &:hover,
    &:focus {
        background: ${cvar('primaryOverlayColor')};
    }

    > span {
        flex: 1;
    }

    > svg {
        margin-left: 8px;
        color: ${cvar('primaryColor')};
    }
`;

interface OptionIcons extends IconsProp {
    option?: {
        icon: string;
    };
}

export interface OptionProps<T> {
    children?: ReactNode;
    selected?: boolean;
    value: T;
    onClick?: (value: T) => void;
    className?: string;
    style?: CSSProperties;
    size?: SelectSize;
    icons?: OptionIcons;
    tabIndex?: number;
    scrollIntoView?: boolean;
}

export function Option<T>({
    children,
    selected,
    onClick = () => {},
    value,
    className,
    style,
    size = SelectSize.Medium,
    icons,
    tabIndex,
    scrollIntoView = false,
}: OptionProps<T>) {
    const optionRef = useRef<HTMLLIElement>(null);
    const { CHECK } = useIcons();

    useEffect(() => {
        if (!selected || !scrollIntoView) return;
        optionRef.current?.scrollIntoView({
            block: 'nearest',
            inline: 'center',
        });
    }, [selected, scrollIntoView, optionRef]);

    return (
        // TODO (#8) Accessibility improvements for `Select` and `Option` component
        <li
            role="option"
            tabIndex={tabIndex || 0} // focus trap in Select dropdown needs tabbable element(s)
            aria-selected={selected}
            onClick={() => onClick(value)}
            className={cx('dsc-option', getOptionStyle(size), className)}
            style={style}
            ref={optionRef}
        >
            <span>{children || '\u00A0'}</span>
            {selected && <Icon content={icons?.option?.icon ?? CHECK.icon} size="extra-small" />}
        </li>
    );
}
