import type { Localization } from '@dexter/gallery';

export const enUS: Localization = {
    pagesCountLabel: 'pages',
    pageCountLabel: 'page',
    backButton: 'Back',
    allImages: 'All images',
    processingOverlay: 'Processing...',
    applyEnsembleModalButton: 'Choose this design',
};
