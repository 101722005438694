/*
 * NOTE: ENV Variables are filtered to only those with prefix 'REACT_APP' by Create React App
 * More info: https://create-react-app.dev/docs/adding-custom-environment-variables/
 */

// env variable for knowing create-react-app build mode
export const isDevEnv = process.env.NODE_ENV === 'development';

// env variable for determining hosted environment of app during build
export const isReviewEnv = process.env.REACT_APP_HOSTED_SPA_ENV === 'review';

// app setting to determine whether env is public or not
export const isInternalEnv = isDevEnv || isReviewEnv;

export const basePath = process.env.REACT_APP_BASEPATH || '/';

export const redirectRoute = isDevEnv ? basePath : '/showcase/version/3.0.0/';
