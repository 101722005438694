import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { cx } from '@emotion/css';

import { useBreakpointInfo } from '@design-stack-ct/utility-react';
import type { SceneView, VortexView } from '@rendering/lookup';

import { ArrowIcon } from '@dexter/dex-component-library';
import { useIcons } from '@dexter/dex-icon-library';

import { ScenePreview } from './scene';
import { VortexPreview } from './vortex';
import {
    arrowBottomStyle,
    arrowLeftStyle,
    arrowRightStyle,
    arrowStyle,
    arrowTopStyle,
    itemContainerStyle,
    previewBoxContainerStyle,
    thumbnailContainerStyle,
} from './vortex/VortexPreviewCss';
import { defaultVortexConfiguration } from '../configuration';
import { EVENT_PREVIEW_BUTTON_CLICK, PREFIX_CLASS } from '../constants';
import { useLookUpService, usePreviewConfiguration } from '../providers';
import { SCENE } from '../store';
import type { PreviewButtonEvent } from '../types';
import { PreviewPosition, sceneTypeProps } from '../types';

interface ArrowsState {
    left: boolean;
    right: boolean;
    top: boolean;
    bottom: boolean;
}

export const Preview = () => {
    const elements: string[] = [];
    const { isSmallDevice } = useBreakpointInfo();
    const containerRef = useRef<HTMLDivElement>(null);
    const thumbnailRef = useRef<HTMLDivElement>(null);
    const [arrows, setArrows] = useState<ArrowsState>({
        left: false,
        right: false,
        top: false,
        bottom: false,
    });
    const { ARROW_LEFT, ARROW_RIGHT, ARROW_UP, ARROW_DOWN } = useIcons();
    const { getFilteredViewData } = useLookUpService();
    const { vortexConfiguration, previewSettings, sceneConfiguration } = usePreviewConfiguration();
    const vortexViews = getFilteredViewData('vortex') as VortexView[];
    const sceneViews: SceneView[] = getFilteredViewData(
        SCENE,
        sceneConfiguration?.purpose,
        sceneConfiguration?.subPurpose,
        sceneConfiguration?.tags || [],
    ) as SceneView[];

    const vortexEnabled = previewSettings?.vortex;
    const sceneEnabled = previewSettings?.scene;
    const previewPosition = previewSettings?.position;

    if (vortexEnabled && vortexViews.length > 0) {
        elements.push('360');
    }

    sceneViews.forEach((scene) => scene.scene.id && elements.push(scene.scene.id));

    const [activeButton, setActiveButton] = useState<string | undefined>(elements[0]);
    const footerElements = (vortexEnabled ? vortexViews.length : 0) + (sceneEnabled ? sceneViews.length : 0);

    const handlePreviewButtonClick = (buttonId?: string) => {
        const sectionContainer = containerRef.current;

        if (!sectionContainer) return;

        if (!buttonId) return;

        const containerHeight = sectionContainer.clientHeight || 0;
        const newScrollPosition = elements.indexOf(buttonId) * containerHeight;
        sectionContainer.scrollTo({
            top: newScrollPosition,
        });

        setActiveButton(buttonId);

        const clickEvent = new CustomEvent(EVENT_PREVIEW_BUTTON_CLICK, {
            detail: { id: buttonId },
        } as PreviewButtonEvent);

        document.dispatchEvent(clickEvent);
    };

    // TODO move to new dex-utility package
    const handleArrowClick = useCallback(
        (arrowType: string) => {
            const sectionContainer = thumbnailRef.current;

            if (!sectionContainer) return;

            const containerWidth = sectionContainer.clientWidth || 0;
            const containerHeight = sectionContainer.clientHeight || 0;
            const imagesPosition = sectionContainer.scrollLeft || 0;
            const imagesPositionY = sectionContainer.scrollTop || 0;

            if (arrowType === 'left' && imagesPosition >= 0) {
                // Move images to the left
                sectionContainer.scrollBy({
                    left: -containerWidth,
                    behavior: 'smooth',
                });
            } else if (
                arrowType === 'right' &&
                imagesPosition + containerWidth <= (sectionContainer.scrollWidth || 0)
            ) {
                // Move images to the right
                sectionContainer.scrollBy({
                    left: containerWidth,
                    behavior: 'smooth',
                });
            } else if (arrowType === 'top' && imagesPositionY >= 0) {
                // Move images to the top
                sectionContainer.scrollBy({
                    top: -containerHeight,
                    behavior: 'smooth',
                });
            } else if (
                arrowType === 'bottom' &&
                imagesPositionY + containerHeight <= (sectionContainer.scrollHeight || 0)
            ) {
                // Move images to the bottom
                sectionContainer.scrollBy({
                    top: containerHeight,
                    behavior: 'smooth',
                });
            }
        },
        [thumbnailRef],
    );

    const hasFooter = footerElements > 1;
    const hasScroll = footerElements > 4;

    const { width, height } = useMemo(
        () => ({
            width: vortexConfiguration?.dimensions?.width || defaultVortexConfiguration.dimensions.width,
            height: vortexConfiguration?.dimensions?.height || defaultVortexConfiguration.dimensions.height,
        }),
        [vortexConfiguration?.dimensions?.width, vortexConfiguration?.dimensions?.height],
    );

    /**
     * handle arrow visibility on scroll
     */
    // TODO move to new dex-utility package
    useEffect(() => {
        const scrollContainer = thumbnailRef.current;
        const handleScroll = () => {
            const newScrollPosition = thumbnailRef.current?.scrollLeft || 0;
            const newVerticalScrollPosition = thumbnailRef.current?.scrollTop || 0;

            if (
                newScrollPosition === 0 &&
                (previewPosition === PreviewPosition.BOTTOM || previewPosition === PreviewPosition.TOP)
            ) {
                setArrows((prev: ArrowsState) => ({ ...prev, left: false, right: true }));
            } else if (
                Math.round(newScrollPosition) + Math.round(Number(scrollContainer?.clientWidth)) + 5 >=
                    (scrollContainer?.scrollWidth || 0) &&
                (previewPosition === PreviewPosition.BOTTOM || previewPosition === PreviewPosition.TOP)
            ) {
                // get the next set of images when reaches to the end of the horizontal scroll
                setArrows((prev: ArrowsState) => ({ ...prev, left: true, right: false }));
            } else if (newScrollPosition >= 1) {
                setArrows((prev: ArrowsState) => ({ ...prev, left: true, right: true }));
            } else if (
                newVerticalScrollPosition === 0 &&
                (previewPosition === PreviewPosition.LEFT || previewPosition === PreviewPosition.RIGHT)
            ) {
                setArrows((prev: ArrowsState) => ({ ...prev, top: false, bottom: true }));
            } else if (
                Math.round(newVerticalScrollPosition) + Math.round(Number(scrollContainer?.clientHeight)) + 5 >=
                (scrollContainer?.scrollHeight || 0)
            ) {
                setArrows((prev: ArrowsState) => ({ ...prev, top: true, bottom: false }));
            } else if (newVerticalScrollPosition >= 1) {
                setArrows((prev: ArrowsState) => ({ ...prev, top: true, bottom: true }));
            }
        };

        scrollContainer?.addEventListener('scroll', handleScroll);

        return () => {
            scrollContainer?.removeEventListener('scroll', handleScroll);
        };
    }, [arrows.right, previewPosition]);

    // check height changes for place in right position the preview
    useEffect(() => {
        const sectionContainer = containerRef.current;

        if (sectionContainer && activeButton) {
            const containerHeight = sectionContainer.clientHeight || 0;
            const newScrollPosition = elements.indexOf(activeButton) * containerHeight;
            sectionContainer.scrollTo({
                top: newScrollPosition,
            });
        }
        // we need check the change on height to calculate the right position of preview into the modal.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    // set arrows visibility if the scroll is available
    // TODO move to new dex-utility package
    useEffect(() => {
        const scrollContainer = thumbnailRef.current;

        if (previewPosition === PreviewPosition.BOTTOM || previewPosition === PreviewPosition.TOP) {
            if (scrollContainer) {
                const isScrolling = scrollContainer.offsetWidth < scrollContainer.scrollWidth;

                if (isScrolling) {
                    setArrows((prev: ArrowsState) => ({ ...prev, left: scrollContainer.scrollLeft > 0, right: true }));
                } else if (arrows.right) {
                    setArrows((prev: ArrowsState) => ({ ...prev, left: false, right: false }));
                }
            }
        } else if (previewPosition === PreviewPosition.LEFT || previewPosition === PreviewPosition.RIGHT) {
            if (scrollContainer) {
                const isScrolling = scrollContainer.offsetHeight < scrollContainer.scrollHeight;

                if (isScrolling) {
                    setArrows((prev: ArrowsState) => ({ ...prev, top: scrollContainer.scrollTop > 0, bottom: true }));
                } else if (arrows.bottom) {
                    setArrows((prev: ArrowsState) => ({ ...prev, top: false, bottom: false }));
                }
            }
        }
        // We do not want to add the arrow.right into the dependency because then the arrow is not going away at end when we reach the end of images
        // We want the width as dependency because we can check when the modal is opened
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    useEffect(() => {
        if (
            vortexConfiguration &&
            vortexConfiguration.error &&
            vortexConfiguration.error.errorCallback &&
            elements.length === 0
        ) {
            vortexConfiguration.error.errorCallback(true);
        }
    }, [elements.length, vortexConfiguration]);

    return (
        elements.length > 0 && (
            <>
                <div
                    ref={containerRef}
                    className={cx(`${PREFIX_CLASS}-container`, itemContainerStyle(width, height, hasFooter))}
                >
                    <VortexPreview
                        type={sceneTypeProps.large}
                        vortexViews={vortexViews}
                        width={width}
                        height={height}
                    />
                    <ScenePreview type={sceneTypeProps.large} sceneViews={sceneViews} width={width} height={height} />
                </div>
                {hasFooter && (
                    <div
                        className={cx(
                            `${PREFIX_CLASS}-thumbnail-section`,
                            thumbnailContainerStyle(isSmallDevice, width, height, previewPosition),
                        )}
                    >
                        <div
                            ref={thumbnailRef}
                            className={cx(
                                `${PREFIX_CLASS}-thumbnail-container`,
                                previewBoxContainerStyle(hasScroll, isSmallDevice, previewPosition),
                            )}
                        >
                            {arrows.top && thumbnailRef.current?.scrollTop !== 0 && (
                                <>
                                    <div className="dex-fusion-preview-fading-top"></div>
                                    <ArrowIcon
                                        className={cx('dex-fusion-preview-arrow-top', arrowStyle, arrowTopStyle)}
                                        icon={ARROW_UP}
                                        onClick={() => handleArrowClick('top')}
                                    />
                                </>
                            )}
                            {arrows.left && thumbnailRef.current?.scrollLeft !== 0 && (
                                <>
                                    <div className="dex-fusion-preview-fading-left"></div>
                                    <ArrowIcon
                                        className={cx('dex-fusion-preview-arrow-left', arrowStyle, arrowLeftStyle)}
                                        icon={ARROW_LEFT}
                                        onClick={() => handleArrowClick('left')}
                                    />
                                </>
                            )}
                            <VortexPreview
                                type={sceneTypeProps.small}
                                vortexViews={vortexViews}
                                activeButton={activeButton}
                                handlePreviewButtonClick={handlePreviewButtonClick}
                            />
                            <ScenePreview
                                type={sceneTypeProps.small}
                                sceneViews={sceneViews}
                                handlePreviewButtonClick={handlePreviewButtonClick}
                                activeButton={activeButton}
                            />
                            {arrows.right && (
                                <>
                                    <div className="dex-fusion-preview-fading-right"></div>
                                    <ArrowIcon
                                        className={cx('dex-fusion-preview-arrow-right', arrowStyle, arrowRightStyle)}
                                        icon={ARROW_RIGHT}
                                        onClick={() => handleArrowClick('right')}
                                    />
                                </>
                            )}
                            {arrows.bottom && (
                                <>
                                    <div className="dex-fusion-preview-fading-bottom"></div>
                                    <ArrowIcon
                                        className={cx('dex-fusion-preview-arrow-bottom', arrowStyle, arrowBottomStyle)}
                                        icon={ARROW_DOWN}
                                        onClick={() => handleArrowClick('bottom')}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                )}
            </>
        )
    );
};
