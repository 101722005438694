import React from 'react';

import { cx } from '@emotion/css';

import { errorStyle } from './VortexPreviewCss';
import { defaultVortexConfiguration } from '../../configuration';
import { PREFIX_CLASS } from '../../constants';

interface VortexErrorProps {
    showErrorMessage: boolean | undefined;
    errorMessage?: string | undefined;
    errorStyleWidth: string;
    errorStyleHeight: string;
}

export const VortexError = ({
    showErrorMessage,
    errorMessage,
    errorStyleWidth,
    errorStyleHeight,
}: VortexErrorProps) => {
    if (showErrorMessage) {
        return (
            <div className={cx(`${PREFIX_CLASS}-error`, errorStyle(errorStyleWidth, errorStyleHeight))}>
                {errorMessage || defaultVortexConfiguration.error.errorMessage}
            </div>
        );
    }
    return null;
};
