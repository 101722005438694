import FocusTrap from 'focus-trap-react';
import type { KeyboardEvent,MouseEvent, ReactNode } from 'react';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';

import { css,cx } from '@emotion/css';

import { ESCAPE } from '@design-stack-ct/utility-core';

import { portalTargetId } from '../portalTarget';

const modalStyle = css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    z-index: 2;

    &:focus {
        outline: none;
    }
`;

const modalDialogStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const modalDialogFullscreenStyle = css`
    ${modalDialogStyle}
    width: 100%;
    height: 100%;
`;

export interface ModalProps {
    children: ReactNode | ReactNode[];
    isFullscreen?: boolean;
    isShown: boolean;
    onCancel?: () => void;
}

export function Modal({ children, isFullscreen, isShown, onCancel }: ModalProps) {
    const modalRef = useRef<HTMLDivElement>(null);

    const portalTarget = document.getElementById(portalTargetId) ?? document.body;

    const onClick = (e: MouseEvent) => {
        if (modalRef?.current === e.target && onCancel) {
            onCancel();
        }
    };

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === ESCAPE && modalRef?.current && onCancel) {
            e.preventDefault();
            onCancel();
        }
    };

    const modal = (
        <FocusTrap>
            <div
                role="presentation"
                ref={modalRef}
                onClick={onClick}
                onKeyDown={onKeyDown}
                className={cx('dsc-modal', { 'dsc-modal--fullscreen': isFullscreen }, modalStyle)}
                data-testid="dsc-modal"
            >
                <div
                    className={cx(
                        'dsc-modal__content',
                        { [modalDialogFullscreenStyle]: isFullscreen },
                        { [modalDialogStyle]: !isFullscreen },
                    )}
                >
                    {children}
                </div>
            </div>
        </FocusTrap>
    );
    return isShown ? createPortal(modal, portalTarget) : null;
}
