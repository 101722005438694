/**
 * TODO : This could be changed and optimized with enum and/or object
 */
export const TEXT_TO_IMAGE_DEV_API = 'https://dexter-dev.ecomm.pixartprinting.net/api/v1/txt2img';
export const TEXT_TO_IMAGE_PRD_API = 'https://dexter.ecomm.pixartprinting.net/api/v1/txt2img';
export const PRODUCTION = 'prd';
export const UNAUTHORIZED_ERROR_MSG = 'Unauthorized!';
export const GENERAL_ERROR_MSG = 'Something went wrong. Please try again!';
export const ERROR_STATUS = 'failed';
export const SUCCESS_STATUS = 'success';
export const ERROR_CODE_101 = 101;
export const SUCCESS_CODE_200 = 200;
export const REQUESTED = 'requested';
// As we are checking for the images if they are ready or not every second. We will check maximum for 15 times equivalent to 15000 ms
export const MAX_NUMBER_OF_TRY = 15;
