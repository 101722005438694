export interface TextFontStylesLocalization {
    tooltips: {
        bold: string;
        italic: string;
        underline: string;
        strikeout: string;
    };
}

export const enUS: TextFontStylesLocalization = {
    tooltips: {
        bold: 'Bold',
        italic: 'Italic',
        underline: 'Underline',
        strikeout: 'Strikethrough',
    },
};
