import { action, makeObservable, observable, toJS } from 'mobx';

import type { GetViewsParams, SceneView, View } from '@rendering/lookup';
import { getViews } from '@rendering/lookup';

import {
    DEXTER,
    OVERLAY_URL_KEY_NAME,
    PURPOSE_DESIGN,
    SCENE,
    SCENE_TYPE_OVERLAY,
    SCENE_TYPE_UNDERLAY,
    TYPE_PHOTOGRAPHIC,
    UNDERLAY_URL_KEY_NAME,
    VORTEX,
} from './utility';
import type { DefaultSceneConfiguration, ProductConfiguration, TenantConfiguration } from '../types';

export class LookupServiceManager {
    @observable requestParams: GetViewsParams | undefined;
    @observable viewsData: View[];
    @observable authToken: string;
    @observable tenantConfiguration: TenantConfiguration;
    @observable productConfiguration: ProductConfiguration;
    @observable isLookupDataReady: boolean;
    @observable user: {
        tenant: {
            id: string;
            type: string;
        };
        referer: string;
    };
    /**
     * TODO: Lets verify why we have assign TenantConfiguration as a type to sceneConfiguration,
     * TODO: if it's not correct then let's define and add a proper type for sceneConfiguration
     * */
    @observable sceneConfiguration: TenantConfiguration;
    private lookupEnvironment: Pick<GetViewsParams, 'environment'>;
    @observable panelMapping: string[];
    @observable defaultSceneConfiguration: DefaultSceneConfiguration;

    constructor(
        productConfiguration: ProductConfiguration,
        tenantConfiguration: TenantConfiguration,
        authToken: string,
        viewsData?: View[],
        panelMapping?: string[],
        defaultSceneConfiguration?: DefaultSceneConfiguration,
    ) {
        makeObservable(this);
        this.authToken = authToken.includes('Bearer') ? authToken?.replace('Bearer ', '') : authToken;
        this.productConfiguration = productConfiguration;
        this.tenantConfiguration = tenantConfiguration;
        this.viewsData = viewsData ?? [];
        this.panelMapping = panelMapping || [];
        this.isLookupDataReady = false;
        this.sceneConfiguration = { id: '', type: '' };
        this.defaultSceneConfiguration = defaultSceneConfiguration ?? {
            isDefaultOverlaySceneEnable: true,
            isDefaultUnderlaySceneEnable: true,
        };
        this.lookupEnvironment = {
            environment: 'prod',
        };

        this.user = {
            tenant: {
                id: '',
                type: '',
            },
            referer: '',
        };

        if (this.viewsData.length > 0) {
            this.setIsLookupReady(true);
        } else {
            this.setRequestParams();
            this.getViewsData();
            this.setUserData();
        }
    }

    @action.bound
    setAuthToken(token: string) {
        this.authToken = token.includes('Bearer') ? token?.replace('Bearer ', '') : token;
    }

    @action.bound
    setRequestParams() {
        try {
            if (this.productConfiguration && this.tenantConfiguration && this.authToken)
                this.requestParams = {
                    accessToken: this.authToken,
                    environment: this.lookupEnvironment.environment,
                    request: {
                        reference: {
                            id: this.productConfiguration.sku,
                            attributes: this.productConfiguration.attributes,
                            version: this.productConfiguration.version,
                        },
                        tenant: {
                            id: this.tenantConfiguration.id,
                            type: this.tenantConfiguration.type,
                        },
                    },
                };
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(error);
        }
    }

    @action.bound
    getFilteredViewData(type = '', purpose = '', subPurpose = '', tags: string[] = []) {
        if (this.viewsData.length > 0) {
            switch (type.toLowerCase()) {
                case SCENE.toLowerCase(): {
                    if (purpose && subPurpose) {
                        const filteredData = this.viewsData.filter((view) => {
                            const viewsCheckingCondition =
                                view.type.toLowerCase() === SCENE.toLowerCase() &&
                                // We are casting view as SceneView, for prevent TS error
                                (view as SceneView).scene.type.toLowerCase() === TYPE_PHOTOGRAPHIC.toLowerCase() &&
                                view.purpose.toLowerCase() === purpose.toLowerCase() &&
                                view.subPurpose.toLowerCase() === subPurpose.toLowerCase();

                            if (tags && tags.length > 0 && view.tags && view.tags.length === 0) {
                                return false;
                            }

                            if (tags && tags.length > 0) {
                                let considerTags = false;
                                tags.forEach((tag) => {
                                    const findingTags = view.tags?.find((eachTag) => eachTag === tag);

                                    if (findingTags) {
                                        considerTags = true;
                                    }
                                });

                                return viewsCheckingCondition && considerTags;
                            }

                            return viewsCheckingCondition;
                        }) as SceneView[];

                        return filteredData;
                    }
                    return this.viewsData.filter((view: View) => view.type.toLowerCase() === SCENE.toLowerCase());
                }
                case VORTEX.toLowerCase():
                    return this.viewsData.filter((view: View) => view.type.toLowerCase() === VORTEX.toLowerCase());

                // TODO: Cases could occur later
                // case 'panel':
                //     break;

                default:
                    return this.viewsData;
            }
        }
        return [];
    }

    @action.bound
    setUserData() {
        this.user = {
            tenant: {
                id: this.tenantConfiguration.id,
                type: this.tenantConfiguration.type,
            },
            referer: DEXTER,
        };
    }

    @action.bound
    getUserData() {
        return this.user;
    }

    @action.bound
    sceneDataPreparation(scenesData: SceneView[], sceneType: string) {
        try {
            const photographicData = scenesData.filter(
                (data) => data.scene.type.toLowerCase() === TYPE_PHOTOGRAPHIC.toLowerCase(),
            );

            if (photographicData) {
                photographicData.forEach((data) => {
                    // Pages start from number 1, while index with 0, therefore subtracting 1 from page number
                    if (data.scene.documents) {
                        const index = data.scene.documents[0].page - 1;
                        this.sceneConfiguration[this.panelMapping[index]] = {
                            [sceneType.toLowerCase() === SCENE_TYPE_UNDERLAY.toLowerCase()
                                ? UNDERLAY_URL_KEY_NAME
                                : OVERLAY_URL_KEY_NAME]: data.scene.url,
                        };
                    }
                });
            }
        } catch (error) {
            // TODO: Handle the error here
            // eslint-disable-next-line no-console
            console.warn('Unable to prepare scenes data');
        }
    }

    @action.bound
    prepareSceneConfiguration() {
        if (this.viewsData && this.panelMapping) {
            const scenesUnderlayData = this.getFilteredViewData(
                SCENE,
                PURPOSE_DESIGN,
                SCENE_TYPE_UNDERLAY,
            ) as SceneView[];

            const scenesOverlayData = this.getFilteredViewData(
                SCENE,
                PURPOSE_DESIGN,
                SCENE_TYPE_OVERLAY,
            ) as SceneView[];

            if (scenesUnderlayData && this.defaultSceneConfiguration.isDefaultUnderlaySceneEnable) {
                this.sceneDataPreparation(scenesUnderlayData, SCENE_TYPE_UNDERLAY);
            }

            if (scenesOverlayData && this.defaultSceneConfiguration.isDefaultOverlaySceneEnable) {
                this.sceneDataPreparation(scenesOverlayData, SCENE_TYPE_OVERLAY);
            }
        }
    }

    @action.bound
    setIsLookupReady(isLookupDataReady: boolean) {
        this.isLookupDataReady = isLookupDataReady;
    }

    @action.bound
    setViewsData(viewsData: View[]) {
        this.viewsData = viewsData;
    }

    @action.bound
    getViewsData() {
        if (this.requestParams && this.isLookupDataReady === false) {
            getViews(structuredClone(toJS(this.requestParams)))
                .then((views) => {
                    this.setViewsData(structuredClone(views));
                    this.setIsLookupReady(true);
                    // isLookupReady is used for right side preview, so after loading the same, scenes can be loaded
                    // this.prepareSceneConfiguration();
                })
                .catch((error) => {
                    this.setViewsData([]);
                    // eslint-disable-next-line no-console
                    console.warn('Something went wrong while fetching lookup data', error);
                });
        }
    }
}
