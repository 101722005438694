import type { ReactNode } from 'react';
import React, { createContext, useContext,useEffect, useState } from 'react';

// @ts-ignore We should revisit this code
import { centralizedAuth as CentralizedAuth } from '@cimpress/simple-auth-wrapper';

// * This client is for Single Page Application
// * Regular Webpage client does not work
export const CLIENT_ID = 'AD5hchbNYF9ZBqeB8H2q8QZttmOoWXtI';

export interface AuthContext {
    readonly isLoading: boolean;
    readonly error?: string;
    readonly accessToken?: string;
    readonly user?: {
        name: string;
        avatar: string;
        tenant: string;
    };
}

export const defaultAuthContext: AuthContext = {
    isLoading: true,
};

export const authContext = createContext<AuthContext>(defaultAuthContext);

export function useAuth(): AuthContext {
    return useContext(authContext);
}

interface AuthProps {
    children?: ReactNode | ReactNode[];
    redirectRoute?: string;
}

interface AuthProfile {
    name: string;
    picture: string;
    ['https://claims.cimpress.io/tenants']: string[] | undefined;
}

function parseTenant(value: string) {
    if (value && value === 'cimpress') {
        return 'Cimpress Technology';
    }

    return value;
}

function parseUserProfile(profile: AuthProfile | undefined) {
    if (profile) {
        const { name, picture: avatar } = profile;
        // Due to deprecation of `['https://claims.cimpress.io/tenants'] it is no longer guaranteed to be there.
        const tenant = parseTenant((profile['https://claims.cimpress.io/tenants'] ?? [])[0]);
        return {
            name,
            avatar,
            tenant,
        };
    }

    return undefined;
}

export function AuthProvider({ children, redirectRoute }: AuthProps) {
    const [context, setContext] = useState({ ...defaultAuthContext });
    const [auth] = useState(
        () =>
            new CentralizedAuth({
                clientID: CLIENT_ID,
                redirectRoute: redirectRoute ?? '/',
            }),
    );

    function login() {
        auth.login({ nextUri: window.location.pathname + window.location.search, forceLogin: false });
    }

    useEffect(() => {
        setContext((state) => ({ ...state, isLoading: true }));

        (async () => {
            try {
                await auth.ensureAuthentication({ nextUri: window.location.pathname + window.location.search });

                auth.on('tokenExpired', login);

                const accessToken = auth.getAccessToken();
                const profile = auth.getProfile();

                setContext((state) => ({ ...state, accessToken, user: parseUserProfile(profile) }));
                // TODO: Remove any
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
                setContext((state) => ({ ...state, error: err.message }));
            }

            setContext((state) => ({ ...state, isLoading: false }));
        })();

        return () => {
            auth.removeListener('tokenExpired', login);
        };
        // TODO: Revisit this
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setContext]);

    useEffect(() => {
        // @ts-ignore file copied from Cimpress
        window.logout = auth.logout;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <authContext.Provider value={context}>{children}</authContext.Provider>;
}
