// FIXME: if this version of useElementDimensions works out fine without the ResizeObserver
// it should be moved to utility-react package and removed from here.
// See: https://cimpress.slack.com/archives/C02KH5Z7S7K/p1658409550412479
import type { RefObject } from 'react';
import { useLayoutEffect,useState } from 'react';

export interface ElementDimensions {
    readonly height: number;
    readonly width: number;
}

/**
 * Get the height and width of an element. Uses the resize observer to automatically set a new height and width when the element resizes.
 */
export function useElementDimensions(ref: RefObject<HTMLElement>) {
    const [rect, setRect] = useState<ElementDimensions>({ width: 0, height: 0 });

    useLayoutEffect(() => {
        let resizeObserver: ResizeObserver;
        if (ref.current) {
            // TODO: Remove any
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            resizeObserver = new ResizeObserver((entries: any) => {
                // TODO: Remove any
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const element = entries.find((entry: any) => entry.target === ref.current);
                if (element) {
                    const { width, height } = element.target.getBoundingClientRect();
                    setRect({ width, height });
                }
            });

            resizeObserver.observe(ref.current);
        }

        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [ref]);

    return rect;
}
