import round from 'lodash/round';
import type React from 'react';
import { useEffect, useRef } from 'react';

import type { PrecisionFormatting } from './types';

export const EMPTY_VALUE = '';

export function getPrecisionValue(num: number, precision: number, precisionFormatting: PrecisionFormatting): string {
    switch (precisionFormatting) {
        case 'fixed':
            return num.toFixed(precision);
        case 'round':
            return round(num, precision).toString();
        default:
            throw new Error(`Unexpected precisionFormatting value: ${precisionFormatting}`);
    }
}

export function validateNumberString(value: string): {
    isValid: boolean;
    parsed: number;
} {
    const trimmed = value.trim();
    const parsed = Number(trimmed);
    const isValid = !Number.isNaN(parsed) && trimmed !== EMPTY_VALUE;

    return { isValid, parsed };
}

export function useSyncRef<T>(x: T): React.MutableRefObject<T> {
    const ref: React.MutableRefObject<T> = useRef(x);

    useEffect(() => {
        // eslint-disable-next-line no-param-reassign
        ref.current = x;
    }, [x]);

    return ref;
}

export function useComponentWillUnmount(): React.MutableRefObject<boolean> {
    const componentWillUnmount = useRef(false);

    useEffect(
        () => () => {
            componentWillUnmount.current = true;
        },
        [],
    );

    return componentWillUnmount;
}
