import type { DetailedHTMLProps, InputHTMLAttributes,ReactNode } from 'react';
import React, { useMemo, useRef,useState } from 'react';

import { css,cx } from '@emotion/css';

import { createIdGenerator } from '@design-stack-ct/utility-core';
import { useKeyboardNavigationActive } from '@design-stack-ct/utility-react';

import { visuallyHiddenStyle } from '../a11y';
import { cvar } from '../theme';

const labelStyle = css`
    user-select: none;
    display: flex;
    align-items: center;
`;

const barStyle = css`
    display: inline-block;
    width: 40px;
    height: 16px;
    border-radius: 8px;
    position: relative;
    transition: background-color 200ms ease;
    margin-right: 6px;
`;

const handleStyle = css`
    height: 10px;
    width: 10px;
    border-radius: 5px;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: transform 200ms ease;
    transform: translate(0, 0);
    background-color: ${cvar('contrastColor')};
`;

const translateStyle = css`
    transform: translate(24px, 0);
`;

export interface ToggleProps {
    label?: ReactNode | string;
    disabled?: boolean;
    checked?: boolean;
    className?: string;
    onChange?: (value: boolean) => void;
    /**
     * Pass any props to the underlying input element
     */
    inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}

const generateId = createIdGenerator('dsc-toggle');

export function Toggle({
    label,
    disabled,
    checked: checkedProp,
    onChange = () => {},
    className,
    inputProps,
}: ToggleProps) {
    const { current: isControlled } = useRef(checkedProp != null);
    const [checkedState, setCheckedState] = useState(checkedProp ?? false);
    const [focused, setFocused] = useState(false);
    const usingKeyboard = useKeyboardNavigationActive();
    const checkboxId = useMemo(() => generateId(), []);

    const checked = isControlled ? checkedProp : checkedState;

    return (
        <div
            className={cx(
                'dsc-toggle',
                css`
                    display: inline-block;
                `,
                {
                    'dsc-toggle--checked': checked,
                    [css`
                        opacity: 0.4;
                    `]: disabled,
                },
                className,
            )}
            data-testid="toggle-wrapper"
        >
            <label
                className={cx('dsc-toggle__label', labelStyle, {
                    [css`
                        cursor: default;
                    `]: disabled,
                    [css`
                        cursor: pointer;
                    `]: !disabled,
                    [css`
                        outline: 2px solid ${cvar('primaryColor')};
                    `]: focused && usingKeyboard,
                })}
                htmlFor={checkboxId}
                data-testid="toggle-label"
            >
                <div
                    className={cx('dsc-toggle__bar', barStyle, {
                        [css`
                            background-color: ${cvar('primaryColor')};
                        `]: checked,
                        [css`
                            background-color: ${cvar('inactiveColor')};
                        `]: !checked,
                    })}
                    data-testid="toggle-bar"
                >
                    <input
                        className={visuallyHiddenStyle}
                        id={checkboxId}
                        role="switch"
                        type="checkbox"
                        checked={checked}
                        aria-checked={checked}
                        onChange={(event) => {
                            setCheckedState(event.target.checked);
                            onChange(event.target.checked);
                        }}
                        disabled={disabled}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        {...inputProps}
                    />
                    <div
                        className={cx('dsc-toggle__handle', handleStyle, { [translateStyle]: checked })}
                        data-testid="toggle-handle"
                    ></div>
                </div>
                {label}
            </label>
        </div>
    );
}
