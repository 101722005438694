import type { ReactNode } from 'react';
import React, { useContext } from 'react';

import { CimDocContext, type CimDocProps } from './cimDocContext';

export const CimDocProvider = ({ cimDoc, children }: { cimDoc: CimDocProps; children: ReactNode | ReactNode[] }) => (
    <CimDocContext.Provider value={cimDoc}> {children} </CimDocContext.Provider>
);

export const useCimDoc = () => {
    const cimDocContext = useContext(CimDocContext);
    if (!cimDocContext) {
        throw new Error(' Cannot use the useCimDoc without a CimDocContext provider');
    }
    return cimDocContext;
};
