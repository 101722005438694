import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import React from 'react';

import { css,cx } from '@emotion/css';

const h3Style = css`
    font-size: 19px;
    line-height: 20px;
    font-weight: bold;
    color: #000000;
`;

export function H3({
    className,
    children,
    ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>) {
    return (
        <h3 className={cx('dsc-h3', h3Style, className)} {...props}>
            {children}
        </h3>
    );
}
