import { css } from '@emotion/css';

import { cvar } from '@dexter/dex-component-library';

import { PREFIX_CLASS } from '../../constants';
import { PreviewPosition } from '../../types';

interface BackgroundCssProps {
    height?: string;
    type: string;
    width?: string;
}

const backgroundCss = ({ height = '30px', type, width = '100%' }: BackgroundCssProps) => css`
    height: ${height};
    width: ${width};
    position: absolute;
    ${type}: 0;
    z-index: 1;
    background: -moz-linear-gradient(${type}, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        ${type} left,
        ${type} right,
        color-stop(0%, rgba(255, 255, 255, 0)),
        color-stop(100%, rgba(255, 255, 255, 1))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
        ${type},
        rgba(255, 0, 0, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(${type}, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(${type}, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* IE10+ */
    background: linear-gradient(to ${type}, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C */
    pointer-events: none;
`;

export const vortexOuterContainerStyle = (width: string, height: string) => css`
    width: ${width};
    height: ${height};
    aspect-ratio: 1/1;
`;

export const vortex3DStyle = (controlsHeight: number) => css`
    height: calc(100% - ${controlsHeight}px);
`;

export const vortexStyle = (width: string, height: string, cursor: string, grabbing: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    cursor: ${cursor};
    gap: 8px;
    &:active {
        cursor: ${grabbing ? 'grabbing' : 'default'};
    }
    width: ${width};
    height: ${height};
    max-width: 100%;
    aspect-ratio: 1/1;
    .${PREFIX_CLASS}-loader {
        position: absolute;
        z-index: 1;
    }
    canvas {
        border-radius: 4px;
        max-width: 100%;
    }
`;

export const actionStyle = (width: string) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    height: 32px;
    margin-top: 8px;
    max-width: ${width};
    padding-bottom: 64px;
`;

export const errorStyle = (width: string, height: string) => css`
    width: ${width};
    height: ${height};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const itemContainerStyle = (width: string, height: string, hasFooter: boolean) => css`
    height: ${height};
    width: ${width};
    overflow: hidden;
    ${hasFooter && `margin-bottom: 16px`};
`;

export const thumbnailContainerStyle = (
    isSmallDevice: boolean,
    width: string,
    height: string,
    position?: PreviewPosition,
) => {
    const otherDimension = isSmallDevice ? '72px' : '88px';
    return css`
        position: relative;
        ${position === PreviewPosition.BOTTOM || position === PreviewPosition.TOP
            ? `
    width: ${width};
    height: ${otherDimension}
    `
            : `
        height: ${height};
        width: ${otherDimension}
    `};
    `;
};

export const previewBoxContainerStyle = (hasScroll: boolean, isSmallDevice: boolean, previewPosition?: string) => css`
    ${!hasScroll &&
    (previewPosition === PreviewPosition.BOTTOM || previewPosition === PreviewPosition.TOP) &&
    `
    justify-content: center;
    height: inherit;
    `};
    gap: ${isSmallDevice ? 8 : 16}px;
    display: flex;
    ${(previewPosition === PreviewPosition.BOTTOM || previewPosition === PreviewPosition.TOP) &&
    `
    overflow: auto hidden;
    `}
    ${(previewPosition === PreviewPosition.LEFT || previewPosition === PreviewPosition.RIGHT) &&
    `
    width: inherit;
    overflow: hidden auto;
    display:grid;
    `}
    ${hasScroll &&
    (previewPosition === PreviewPosition.LEFT || previewPosition === PreviewPosition.RIGHT) &&
    `height: 100%`}

    .dex-fusion-preview-fading-left {
        ${backgroundCss({ height: '100%', type: 'left', width: '30px' })}
    }
    .dex-fusion-preview-fading-right {
        ${backgroundCss({ height: '100%', type: 'right', width: '30px' })}
        top: 0;
    }
    .dex-fusion-preview-fading-bottom {
        ${backgroundCss({ height: '30px', type: 'bottom', width: '100%' })}
    }
    .dex-fusion-preview-fading-top {
        ${backgroundCss({ height: '30px', type: 'top', width: '100%' })}
    }
    box-sizing: border-box;
    /* Hiding the scroll bars */
    /* Webkit */
    ::-webkit-scrollbar {
        display: none;
    }
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
`;

export const arrowStyle = css`
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1;
    .dsc-icon-button {
        background: #ffffff;
        box-shadow: 0px 0px 5px #d3d3d3;
        border-radius: 50%;
        display: flex;
        &:hover:not([disabled]):not(.dsc-icon-button--active) {
            background: ${cvar('primaryColor')};
            svg {
                color: #ffffff;
            }
        }
    }
`;

export const arrowLeftStyle = css`
    height: 100%;
    left: -10px;
`;

export const arrowRightStyle = css`
    height: 100%;
    right: -10px;
`;

export const arrowTopStyle = css`
    width: 100%;
    justify-content: center;
    top: -10px;
`;

export const arrowBottomStyle = css`
    width: 100%;
    justify-content: center;
    bottom: -10px;
`;

export const preview360 = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const previewBoxStyle = (height: string) => css`
    cursor: pointer;
    border-radius: 4px;
    aspect-ratio: 1/1;
    height: ${height};
`;
