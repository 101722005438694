import React from 'react';

import { css, cx } from '@emotion/css';

import type { IconRecord } from '@dexter/dex-icon-library';
import { Icon, IconSizes } from '@dexter/dex-icon-library';

import type { IconButtonProps } from '../buttons';
import { IconButton } from '../buttons';
import { cvar } from '../theme';

const containerStyle = css`
    padding: 8px;
    border-radius: 4px;
    border: none;

    &:active:not([disabled]),
    &.dsc-icon-label-button__active {
        background-color: ${cvar('disabledLightColor')};
    }

    &:disabled {
        cursor: not-allowed;
        .dsc-icon-label-button__icon svg {
            color: ${cvar('disabledDarkColor')};
        }
    }

    .dsc-icon-label-button__icon svg {
        color: ${cvar('primaryColor')};
    }

    @media (hover: hover) {
        &:hover:not([disabled]):not(.dsc-icon-button--active) {
            svg,
            span {
                color: ${cvar('secondaryColor')};
            }
        }
    }
`;

const labelStyle = css`
    display: block;
    padding-top: 8px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    color: ${cvar('secondaryColor')};
`;

const disabledStyle = css`
    color: ${cvar('secondaryColor')};
`;

interface IconLabelButtonProps extends Omit<IconButtonProps, 'children'> {
    icon: IconRecord;
    label: string;
}

const labelSizes = {
    ExtraSmall: {
        fontSize: 8,
        lineHeight: 12,
    },
    Small: {
        fontSize: 9,
        lineHeight: 12,
    },
    Default: {
        fontSize: 10,
        lineHeight: 12,
    },
    Medium: {
        fontSize: 10,
        lineHeight: 12,
    },
    Large: {
        fontSize: 11,
        lineHeight: 16,
    },
    ExtraLarge: {
        fontSize: 12,
        lineHeight: 16,
    },
} as const;

const calcLabelSize = (iconSize: keyof typeof labelSizes): { fontSize: number; lineHeight: number } =>
    labelSizes[iconSize] || labelSizes.Default;

export function IconLabelButton({
    icon,
    label,
    disabled = false,
    active = false,
    size = IconSizes.Default,
    className,
    ...props
}: IconLabelButtonProps) {
    const iconLabelBtn = 'dsc-icon-label-button';

    // Convert the enum value to a string key
    const sizeKey = Object.keys(IconSizes).find(
        (key) => IconSizes[key as keyof typeof IconSizes] === size,
    ) as keyof typeof labelSizes;

    const { fontSize, lineHeight } = calcLabelSize(sizeKey as keyof typeof labelSizes);

    return (
        <IconButton
            data-testid={iconLabelBtn}
            active={active}
            disabled={disabled}
            size={size}
            className={cx(containerStyle, iconLabelBtn, { [`${iconLabelBtn}__active`]: active }, className)}
            {...props}
        >
            <Icon content={icon.icon} className={`${iconLabelBtn}__icon`} />
            <span
                data-testid={`${iconLabelBtn}__label`}
                className={cx(
                    `${iconLabelBtn}__label`,
                    labelStyle,
                    { [disabledStyle]: disabled },
                    css`
                        font-size: ${fontSize}px;
                        line-height: ${lineHeight}px;
                    `,
                )}
            >
                {label}
            </span>
        </IconButton>
    );
}
