import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { SliderTooltipPosition } from './types';
import { cvar } from '../theme';

interface SliderTooltipProps {
    isActive: boolean;
    position: SliderTooltipPosition;
}

const sliderTooltipLeft = css`
    left: -10px;
    top: 50%;
    transform: translate(-100%, -50%);

    &:after {
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid ${cvar('primaryColorDark')};
        right: -4px;
    }
`;

const sliderTooltipRight = css`
    right: -10px;
    top: 50%;
    transform: translate(100%, -50%);

    &:after {
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 4px solid ${cvar('primaryColorDark')};
        left: -4px;
    }
`;

const sliderTooltipTop = css`
    left: 50%;
    top: -10px;
    transform: translate(-50%, -100%);
    &:after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid ${cvar('primaryColorDark')};
        bottom: -4px;
    }
`;

const sliderTooltipBottom = css`
    left: 50%;
    bottom: -10px;
    transform: translate(-50%, 100%);

    &:after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid ${cvar('primaryColorDark')};
        top: -4px;
    }
`;

function getTooltipPositionStyle({ position }: SliderTooltipProps) {
    if (position === SliderTooltipPosition.bottom) return sliderTooltipBottom;
    if (position === SliderTooltipPosition.left) return sliderTooltipLeft;
    if (position === SliderTooltipPosition.right) return sliderTooltipRight;
    return sliderTooltipTop;
}

export const SliderTooltip = styled.div<SliderTooltipProps>`
    position: absolute;
    background-color: ${cvar('primaryColorDark')};
    color: ${cvar('primaryBackgroundColor')};
    padding: 4px 8px;
    border-radius: 2px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.2s, opacity 0.2s;

    &:after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
    }
    ${getTooltipPositionStyle}

    ${({ isActive }) => isActive && `opacity: 1; visibility: visible`}
`;
