import React from 'react';

export default function FusionParams() {
    const fusionParamsProps = [
        {
            prop: 'cimDoc',
            type: 'object',
            required: 'true',
            description: 'Cimdoc for the preview',
            value: `cimdoc object`,
        },
        {
            prop: 'productConfiguration',
            type: 'object',
            required: 'true',
            description: 'Configuration of the product',
            value: `{sku: string; attributes: Record<string, string>}`,
        },
        {
            prop: 'className',
            type: 'string',
            required: 'false',
            description: 'Custom class name for the preview',
            value: 'null',
        },
        {
            prop: 'vortexConfiguration.vortexActions',
            type: 'boolean',
            required: 'false',
            description: 'To show/hide the action buttons for the 3D preview',
            value: 'true',
        },
        {
            prop: 'vortexConfiguration.loader',
            type: 'React.ReactNode | React.ReactNode[] | string',
            required: 'false',
            description: 'Loader JSX / string for the preview',
            value: 'Loading...',
        },
        {
            prop: 'vortexConfiguration.dimensions.width',
            type: 'string',
            required: 'false',
            description: 'Width of the preview (it should be css value)',
            value: '400px',
        },
        {
            prop: 'vortexConfiguration.dimensions.height',
            type: 'string',
            required: 'false',
            description: 'Height of the preview (it should be css value)',
            value: '400px',
        },
        {
            prop: 'vortexConfiguration.error.showErrorMessage',
            type: 'boolean',
            required: 'false',
            description: 'To show/hide the error message',
            value: 'true',
        },
        {
            prop: 'vortexConfiguration.error.errorMessage',
            type: 'string',
            required: 'false',
            description: 'Error message for the preview',
            value: 'Unable to load 3D preview',
        },
        {
            prop: 'vortexConfiguration.onAnimate.animationDuration',
            type: 'number',
            required: 'false',
            description: 'Animation duration for the preview',
            value: '3 (in Seconds)',
        },
        {
            prop: 'localization',
            type: 'object',
            required: 'false',
            description: 'localization for the preview',
            value: `{
                    front: 'Front',
                    back: 'Back',
                    open: 'Open',
                    fold: 'Fold',
                    unfold: 'Unfold',
                    folded: 'Close'
                }`,
        },
        {
            prop: 'tenantConfiguration',
            type: 'object',
            required: 'false',
            description: 'Tenant configuration for the preview',
            value: `{ id: "pixart", type: "merchants" }`,
        },
        {
            prop: 'previewSettings.vortex',
            type: 'boolean',
            required: 'false',
            description: 'To show/hide the 3D preview section',
            value: 'true',
        },
        {
            prop: 'previewSettings.scene',
            type: 'boolean',
            required: 'false',
            description: 'To show/hide the scene for the preview',
            value: 'false',
        },
    ];

    return (
        <>
            <p>Fusion Preview component will give the formatted html view. you can use it as below</p>
            <p>
                Fusion Preview component emit 2 events <code>fusionPreviewButtonClick</code> and{' '}
                <code>fusionActionButtonClick</code>. You can listen to these events and perform the required actions
                (ex. GA events).
            </p>

            <code className="code-block">
                <pre>
                    {`
    import { FusionPreview } from '@dexter/fusion-preview';

    <FusionPreview
        cimDoc={cimDoc}
        productConfiguration={{sku: string; attributes: Record<string, string>}}
        className={customClassName}
        vortexConfiguration={vortexConfiguration}
        localization={localization}
        tenantConfiguration={tenantConfiguration}
        previewSettings={previewSettings}
    />
 `}
                </pre>
            </code>

            <p>Props for PreviewGallery component - </p>

            <table className="table-style">
                <tbody>
                    <tr>
                        <th>Property</th>
                        <th>Required</th>
                        <th>Type</th>
                        <th>Value</th>
                        <th>Description</th>
                    </tr>
                    {fusionParamsProps.map(({ prop, type, description, required, value }) => (
                        <tr key={prop}>
                            <td>{prop}</td>
                            <td>{required}</td>
                            <td>{type}</td>
                            <td>{value}</td>
                            <td>{description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}
