import { CONSTANT } from '../constants';
import type { DpDownloadResponseProps, FinalResponse } from '../types';

export const processDownloadHDImageData = (imageId: string, data: DpDownloadResponseProps) => {
    if (data.data && data.data.type === 'success' && data.data.downloadLink !== '') {
        const createResponse = {
            imageId,
            hdImageLink: data.data.downloadLink,
        };
        const finalResponse: FinalResponse = {
            providerId: CONSTANT.depositPhoto,
            status: CONSTANT.httpSuccess.statusText,
            statusCode: CONSTANT.httpSuccess.statusCode,
        };
        finalResponse[CONSTANT.depositPhoto] = createResponse;
        return finalResponse;
    }

    const error: number =
        data.error?.errorCode === 101 ? CONSTANT.httpFailure.statusCode : data.error?.errorCode || 101;
    const errorMsg: string = data.error?.errorMsg || 'Something went wrong';

    const finalResponse: FinalResponse = {
        providerId: CONSTANT.depositPhoto,
        status: CONSTANT.httpFailure.statusText,
        statusCode: CONSTANT.httpFailure.statusCode,
        error: {
            errorCode: error,
            errorMessage: errorMsg,
        },
    };

    return finalResponse;
};
