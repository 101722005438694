import type { ReactNode } from 'react';
import React, { createContext,useContext } from 'react';

import type { DesignExperienceIcons,DexIcons } from '../icons';
import { completeIconSet } from '../icons';

export interface IconRecord {
    name: DesignExperienceIcons | string;
    icon: string;
}

export type IconManifest = Record<DexIcons | string, IconRecord>;

export const defaultIcons = completeIconSet.reduce(
    (icons, icon) => Object.assign(icons, { [icon.name]: { name: icon.name, icon: icon.data } }),
    {} as IconManifest,
);

const iconContext = createContext(defaultIcons);

export function useIcons(): IconManifest {
    const icons = useContext(iconContext);

    if (!icons) {
        return defaultIcons;
    }

    return icons;
}

export interface IconProviderProps {
    icons?: IconManifest;
    children: ReactNode | ReactNode[];
}

export function IconProvider({ icons = defaultIcons, children }: Readonly<IconProviderProps>) {
    const { Provider } = iconContext;
    return <Provider value={icons}>{children}</Provider>;
}
