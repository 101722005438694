import type { ReactNode } from 'react';
import React from 'react';

import { css, cx } from '@emotion/css';

const baseStyle = css`
    cursor: pointer;
`;

const selectedStyle = css`
    background-color: #0088a9;
`;

export interface ButtonToggleProps {
    id: string;
    children: ReactNode | ReactNode[];
    className?: string;
    onClick?(id: string): void;
    isSelected?: boolean;
    disabled?: boolean;
}

export function ButtonToggle({
    id,
    children,
    className,
    isSelected = false,
    onClick = () => {},
    disabled,
}: ButtonToggleProps) {
    const classes = cx(
        'dsc-button-toggle',
        baseStyle,
        {
            [selectedStyle]: isSelected,
            'dsc-button-toggle--selected': isSelected,
        },
        className,
    );
    return (
        <button aria-pressed={isSelected} id={id} onClick={() => onClick(id)} disabled={disabled} className={classes}>
            {children}
        </button>
    );
}
