// TODO: This could be changed and optimized with enum and/or object
// eslint-disable-next-line @typescript-eslint/ban-types
export const isEmptyObject = (obj: Object) => {
    const status = obj ? Object.values(obj).length === 0 : false;
    return status;
};

export const getImageIdFromImageUrl = (url: string | undefined) => {
    if (url !== undefined) {
        // Split the URL by last index of /
        const fileName = url.substring(url.lastIndexOf('/') + 1);
        if (fileName) {
            // Split the part by "."
            const imageId = fileName.substring(0, fileName.lastIndexOf('.'));
            // Check if the imageId is available
            if (imageId) {
                return imageId;
            }
        }
    }
    return '';
};

/**
 * The asterisk (*) in JavaScript denotes a generator function.
 * It allows you to define an iterator by using the yield keyword to pause and resume the function's execution,
 * providing an iterable sequence of values.
 */
export function* fibonacciGenerator() {
    let a = 0;
    let b = 1;
    while (true) {
        yield b;
        [a, b] = [b, a + b];
    }
}

/**
 * TODO : Make a package to get all the common things in single place, we have this same code in print extended as well so it makes sense to create a utility package
 * Get the environment based on
 */
export const getEnvironment = () => {
    if (process.env.NODE_ENV === 'dev' || process.env.NODE_ENV === 'development') {
        return 'dev';
    }
    if (process.env.NODE_ENV === 'stg' || process.env.NODE_ENV === 'staging') {
        return 'stg';
    }
    if (process.env.NODE_ENV === 'qa' || process.env.NODE_ENV === 'testing') {
        return 'qa';
    }
    return 'prd';
};
