import React from 'react';

import { IconButton } from '@dexter/dex-component-library';
import type { IconRecord } from '@dexter/dex-icon-library';
import { Icon, IconSizes } from '@dexter/dex-icon-library';

interface ArrowIconProps {
    icon: IconRecord;
    onClick: () => void;
    className?: string;
    size?: string;
}

export default function ArrowIcon({ icon, onClick, className, size }: ArrowIconProps) {
    return (
        <div className={className} onClick={onClick}>
            <IconButton size={size || IconSizes.ExtraSmall}>
                <Icon content={icon.icon} />
            </IconButton>
        </div>
    );
}
