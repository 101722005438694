//! These types are Design Experience specific concerns and in the future dex-component-library should remove these expectations in favor of a string only.

// TODO(#6) Remove DraggableItem and draggableItemTypes from dex-component-library

export enum DraggableItem {
    Image = 'Image',
    ImageBlob = 'ImageBlob',
    ImageUrl = 'ImageUrl',
    Shape = 'Shape',
    ClipArt = 'ClipArt',
    ImageMultiSelect = 'ImageMultiSelect',
    Graphic = 'Graphic',
}

export type draggableItemTypes = `${DraggableItem}`;
