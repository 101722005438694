import type { FocusEvent, MouseEvent, ReactNode, TouchEvent } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import type { Modifier } from 'react-popper';
import { Manager, Popper, Reference } from 'react-popper';

import { css,cx } from '@emotion/css';

import { createIdGenerator } from '@design-stack-ct/utility-core';
import { useClickOutside } from '@design-stack-ct/utility-react';

import { visuallyHiddenStyle } from '../a11y';
import { cvar } from '../theme';

export type TooltipPlacement = 'auto' | 'top' | 'bottom' | 'left' | 'right';
export type TooltipTrigger = 'hover' | 'click' | 'focus';
export type TooltipVariant = 'default' | 'info' | 'warning' | 'error' | 'success' | 'optionInfo';

export interface TooltipProps {
    /** The element(s) that will trigger the tooltip */
    children: ReactNode | ReactNode[];
    /** optional aria-label for trigger e.g useful with images */
    triggerAriaLabel?: string;
    /** Content of the tooltip */
    content: ReactNode | string;
    /**
     * Allows opening and closing the tooltip programmatically
     * @defaultValue `undefined`
     */
    open?: boolean;
    /**
     * Position of the tooltip relative to the triggering element(s)
     * @defaultValue `'auto'`
     */
    placement?: TooltipPlacement;
    /**
     * What event the tooltip will react to. You can specify more than one event in an array
     * @defaultValue `'hover'`
     */
    trigger?: TooltipTrigger | TooltipTrigger[];
    /**
     * Style variant of the tooltip
     * @defaultValue `'default'`
     */
    variant?: TooltipVariant;
    /**
     * Enable pointer movement between tooltip trigger and tooltip by using delay on mouse leave
     */
    mouseLeaveDelay?: number;
    /**
     * Padding applied around tooltip to prevent overflow out of the viewport. Negative numbers are ignored
     * @defaultValue `0`
     */
    viewportPadding?: number;
    /**
     * Adds a className to the container element of the tooltip
     */
    tooltipContainerClassName?: string;
}

const variantColorsMap: Record<TooltipVariant, string> = {
    default: cvar('secondaryColor'),
    info: cvar('infoDarkColor'),
    warning: cvar('warningDarkColor'),
    error: cvar('errorDarkColor'),
    success: cvar('successDarkColor'),
    optionInfo: cvar('backgroundColorWhite'),
};

const getContainerStyle = (variant: TooltipVariant) => css`
    background-color: ${variantColorsMap[variant]};
    padding: 8px;
    border-radius: 12px;
    box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 12px 12px;
    z-index: 1;
    color: ${variant !== 'optionInfo' ? cvar('primaryBackgroundColor') : cvar('secondaryColor')};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
`;

const getArrowStyle = (variant: TooltipVariant) => css`
    position: absolute;
    width: 12px;
    height: 12px;
    z-index: -1;

    &::before {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        content: '';
        background: ${variant !== 'optionInfo' && variantColorsMap[variant]};
    }

    &[data-placement='top'] {
        bottom: -2px;

        &::before {
            transform: rotate(45deg) skew(10deg, 10deg);
        }
    }

    &[data-placement='bottom'] {
        top: -2px;

        &::before {
            transform: rotate(45deg) skew(10deg, 10deg);
        }
    }

    &[data-placement='right'] {
        left: -2px;

        &::before {
            transform: rotate(-45deg) skew(10deg, 10deg);
        }
    }

    &[data-placement='left'] {
        right: -2px;

        &::before {
            transform: rotate(-45deg) skew(10deg, 10deg);
        }
    }
`;

// TODO: Remove any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialPopperModifiers: Modifier<any>[] = [
    {
        name: 'offset',
        options: {
            offset: [0, 9],
        },
    },
    {
        name: 'arrow',
        options: {
            padding: 3,
        },
    },
];

const generateId = createIdGenerator('dsc-tooltip');

export function Tooltip({
    open: openProp = undefined,
    content,
    variant = 'default',
    placement = 'auto',
    trigger = 'hover',
    children,
    triggerAriaLabel,
    mouseLeaveDelay = 0,
    viewportPadding = 0,
    tooltipContainerClassName,
}: TooltipProps) {
    const { current: isControlled } = useRef(openProp !== undefined);
    const [openState, setOpenState] = useState<boolean | null>(null);
    const isHovered = useRef<boolean>(false);
    const triggerRef = useRef<HTMLSpanElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const tooltipId = useMemo(() => generateId(), []);
    const popperModifiers =
        viewportPadding > 0
            ? [
                  ...initialPopperModifiers,
                  {
                      name: 'preventOverflow',
                      options: {
                          padding: viewportPadding,
                      },
                  },
              ]
            : initialPopperModifiers;

    const variantClassName = variant !== 'default' ? `dsc-tooltip__container--${variant}` : '';

    const open = isControlled ? openProp : openState;

    const showTooltip = (event: MouseEvent<HTMLElement> | TouchEvent<HTMLElement> | FocusEvent<HTMLElement>) => {
        event.preventDefault();
        setOpenState(true);
    };

    const hideTooltip = () => {
        setOpenState(false);
    };

    const toggleTooltip = () => {
        setOpenState((prevState) => !prevState);
    };

    const isTriggeredBy = useCallback(
        (event: TooltipTrigger) => trigger === event || (Array.isArray(trigger) && trigger.includes(event)),
        [trigger],
    );

    const onMouseEnter = (event: MouseEvent<HTMLElement>) => {
        isHovered.current = true;
        showTooltip(event);
    };

    const onMouseLeave = () => {
        isHovered.current = false;
        if (mouseLeaveDelay) {
            setTimeout(() => {
                if (!isHovered.current) {
                    hideTooltip();
                }
            }, mouseLeaveDelay);
        } else {
            hideTooltip();
        }
    };

    const tabindex = {
        ...(isTriggeredBy('focus') && { tabIndex: 0 }),
    };

    const triggerHandlers = {
        ...(isTriggeredBy('click') && {
            onClick: toggleTooltip,
        }),
        ...(isTriggeredBy('hover') && {
            onMouseEnter,
            onMouseLeave,
            onTouchStart: showTooltip,
        }),
        ...(isTriggeredBy('focus') && {
            onFocus: showTooltip,
            onBlur: hideTooltip,
        }),
    };

    const tooltipHandlers = {
        ...(isTriggeredBy('hover') && {
            onMouseEnter,
            onMouseLeave,
        }),
    };

    useClickOutside(
        {
            elementRef: [triggerRef, tooltipRef],
            shouldAddEventListener: !isControlled && isTriggeredBy('click') && !!openState,
        },
        () => {
            hideTooltip();
        },
        [hideTooltip],
    );

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        const ESCAPE = 'escape';
        const loweredKey = event.key.toLowerCase();
        if (loweredKey === ESCAPE) {
            toggleTooltip();
        }
    }, []);

    useEffect(() => {
        open && isTriggeredBy('focus') && window.addEventListener('keydown', handleKeyDown);

        return () => {
            isTriggeredBy('focus') && window.removeEventListener('keydown', handleKeyDown);
        };
    }, [open, handleKeyDown, isTriggeredBy]);

    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <>
                        <span
                            ref={ref}
                            className="dsc-tooltip__trigger"
                            aria-describedby={tooltipId}
                            role="button"
                            aria-label={triggerAriaLabel}
                            data-testid="tooltip-trigger"
                            {...tabindex}
                            {...triggerHandlers}
                        >
                            <span ref={triggerRef} aria-hidden="true">
                                {children}
                            </span>
                        </span>
                        {/* in order to be readable by accessibility technology, because it doesnt work with createPortal */}
                        <span id={tooltipId} className={visuallyHiddenStyle} role="tooltip">
                            {content}
                        </span>
                    </>
                )}
            </Reference>

            {open &&
                createPortal(
                    <Popper innerRef={tooltipRef} placement={placement} modifiers={popperModifiers}>
                        {({ ref, style, placement: popperPlacement, arrowProps }) => (
                            <div
                                ref={ref}
                                aria-hidden="true"
                                className={cx(
                                    'dsc-tooltip__container',
                                    getContainerStyle(variant),
                                    variantClassName,
                                    tooltipContainerClassName,
                                )}
                                data-testid="tooltip-window"
                                style={style}
                                {...tooltipHandlers}
                            >
                                <div
                                    {...arrowProps}
                                    className={cx('dsc-tooltip__arrow', getArrowStyle(variant))}
                                    data-placement={popperPlacement}
                                />
                                {content}
                            </div>
                        )}
                    </Popper>,
                    document.body,
                )}
        </Manager>
    );
}
