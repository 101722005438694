export type Orientation = 'horizontal' | 'vertical';

export interface SliderRailPosition {
    left: number;
    bottom: number;
    top: number;
    right: number;
}

export interface SliderMarksInterface {
    /**
     * Displays a string at the position of the key
     */
    [key: number]: string;
}

export enum SliderTooltipPosition {
    top = 'top',
    left = 'left',
    right = 'right',
    bottom = 'bottom',
}

export interface SliderTooltipProps {
    /**
     * Whether the tooltip shows
     * @default true
     */
    isEnabled: boolean;
    /**
     * Allows customizing the display format of the tooltip.
     * @default '(value: number) => `${Math.round(value)}`'
     */
    formatter: (value: number) => string;
    /**
     * Determines the position of the tooltip. Valid values: 'left' | 'right' | 'top' | 'bottom'
     * @default 'top'
     */
    position: 'left' | 'right' | 'top' | 'bottom';
}
