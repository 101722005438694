import type { CSSProperties, ReactNode } from 'react';
import React from 'react';

import { css, cx, keyframes } from '@emotion/css';

import { DoubleClickable } from '../doubleClickable';
import type { DragData, DraggableItem } from '../dragAndDrop';
import { Draggable, useDraggingState } from '../dragAndDrop';
import { cvar } from '../theme';

const thumbnailBaseStyle = css`
    // Parent container can determine dimensions
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: relative;
    font-size: 14px;
    box-sizing: border-box;
`;

const contentWraperStyle = css`
    padding-top: 100%;
    width: 100%;
`;

const thumbnailImage = css`
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px;
    position: absolute;
    top: 3%;
    left: 3%;
    right: 3%;
    bottom: 3%;
    border: 1px solid #8090a2;

    &:hover {
        border-color: ${cvar('primaryColorLight')};
    }
`;

const thumbnailErrorStyle = css`
    border-color: ${cvar('errorColor')};

    &:hover {
        border-color: ${cvar('errorColor')};
    }
`;

const placeholderShimmer = keyframes`
    from {
        background-position: -100% 0;
    }
    to {
        background-position: 100% 0;
    }
`;

const loadingShimmerEffect = css`
    background-color: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800% 100%;
    position: relative;
    animation-duration: 1.4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeholderShimmer};
    animation-timing-function: linear;
`;

const thumbnailImageDisabled = css`
    &,
    &:hover,
    &:active {
        margin: 0;
        border: 1px solid #8090a2;
        cursor: not-allowed;
    }

    .dsc-thumbnail__disabled-overlay {
        height: 100%;
        border-radius: 4px;
        background-color: #fff;
        opacity: 0.3;
    }
`;

interface ThumbnailProps {
    draggableId?: string;
    dragContentType?: DraggableItem;
    dragData?: DragData;
    thumbnail?: string;
    isDisabled?: boolean;
    onClick?: () => void;
    onDoubleClick?: () => void;
    isDragEnabled?: boolean;
    children?: ReactNode | ReactNode[];
    className?: string;
    style?: CSSProperties;
    isLoading?: boolean;
    hasError?: boolean;
}

export const Thumbnail = ({
    draggableId,
    dragContentType,
    dragData,
    thumbnail,
    isDisabled,
    onClick,
    onDoubleClick,
    isDragEnabled = true,
    children,
    className,
    style,
    isLoading = false,
    hasError,
}: ThumbnailProps) => {
    const { setIsDragging } = useDraggingState();
    const hasDraggableContent = !isDisabled && draggableId && dragContentType;

    const thumbnailContent = (
        <div
            className={cx('dsc-thumbnail', thumbnailImage, {
                [thumbnailErrorStyle]: hasError,
                [thumbnailImageDisabled]: isDisabled,
            })}
            style={{ backgroundImage: thumbnail ? `url('${thumbnail}')` : undefined }}
        >
            {isDisabled ? <div className="dsc-thumbnail__disabled-overlay">{children}</div> : children}
        </div>
    );

    const content = hasDraggableContent ? (
        <Draggable
            dragContentType={dragContentType!}
            dragData={dragData}
            id={draggableId}
            enabled={isDragEnabled}
            className={contentWraperStyle}
            onIsDragging={(isDragging) => setIsDragging(isDragging)}
        >
            {thumbnailContent}
        </Draggable>
    ) : (
        <div className={contentWraperStyle}>{thumbnailContent}</div>
    );

    return !isDisabled ? (
        <DoubleClickable
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            className={cx(thumbnailBaseStyle, { [loadingShimmerEffect]: isLoading }, className)}
            style={style}
        >
            {content}
        </DoubleClickable>
    ) : (
        <div className={cx(thumbnailBaseStyle, className)} style={style}>
            {content}
        </div>
    );
};
