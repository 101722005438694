import { css } from '@emotion/css';

import { cvar, mediaQueries } from '@dexter/dex-component-library';

interface BackgroundCssProps {
    height?: string;
    type: string;
    width?: string;
}

const backgroundCss = ({ height = '30px', type, width = '100%' }: BackgroundCssProps) => css`
    height: ${height};
    width: ${width};
    position: absolute;
    ${type}: 0;
    z-index: 1;
    background: -moz-linear-gradient(${type}, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        ${type} left,
        ${type} right,
        color-stop(0%, rgba(255, 255, 255, 0)),
        color-stop(100%, rgba(255, 255, 255, 1))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
        ${type},
        rgba(255, 0, 0, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(${type}, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(${type}, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* IE10+ */
    background: linear-gradient(to ${type}, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C */
    pointer-events: none;
`;

export const containerStyle = (
    height: number,
    backHeight: number,
    isVertical?: boolean,
    isOverlayNeeded?: boolean,
) => css`
    height: ${height - backHeight}px;
    position: relative;
    margin-bottom: ${30 + backHeight}px;
    width: 100%;
    > * {
        box-sizing: border-box;
    }

    .dex-gallery-arrow {
        position: absolute;
        top: ${backHeight}px;
        display: flex;
        height: 100%;
        align-items: center;
        z-index: 1;
        button {
            background: #ffffff;
            box-shadow: 0px 0px 5px #d3d3d3;
            border-radius: 50%;
            display: flex;
            &:hover {
                background: ${cvar('primaryColor')};
                svg {
                    color: #ffffff;
                }
            }
            &:hover:not([disabled]) {
                background: ${cvar('primaryColor')};
                svg {
                    color: #ffffff;
                }
            }
            svg {
                color: ${cvar('primaryColor')};
            }
        }
    }
    .dex-gallery-arrow-left {
        left: -10px;
        ${mediaQueries.small} {
            left: 0;
        }
    }
    .dex-gallery-arrow-right {
        right: -10px;
        ${mediaQueries.small} {
            right: 0;
        }
    }

    .dex-gallery-photo-store-content-fading-top {
        ${backgroundCss({ type: 'top' })}
    }
    .dex-gallery-photo-store-content-fading-bottom {
        ${backgroundCss({ type: 'bottom' })}
    }
    .dex-gallery-photo-store-content-fading-left {
        ${backgroundCss({ height: '100%', type: 'left', width: '30px' })}
    }
    .dex-gallery-photo-store-content-fading-right {
        ${backgroundCss({ height: '100%', type: 'right', width: '30px' })}
        top: 0;
    }

    ${isVertical &&
    `
    height: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .dex-gallery-back-container {
        margin-bottom: 10px;
    }
    .infinite-scroll-component__outerdiv {
        overflow: auto;
        height: inherit;
        .infinite-scroll-component {
            height: 100% !important;
            .dex-gallery-react-photo-album-outer-div {
                height: 100%;
                padding: 4px;
                overflow-y: scroll;
                /* Hiding the scroll bars */
                /* Webkit */
                ::-webkit-scrollbar {
                    display: none;
                }
                /* IE and Edge */
                -ms-overflow-style: none;
                /* Firefox */
                scrollbar-width: none;
                .react-photo-album {
                    // height: 100%;
                    // min-height: 100%;
                    // overflow-y: scroll;
                    justify-content: initial !important;
                    .dex-gallery-draggable {
                        cursor: pointer;
                        position: relative;
                       ${
                           isOverlayNeeded
                               ? `
                            border: 1px solid ${cvar('greyColor')};
                            &:hover {
                                border: 1px solid ${cvar('primaryHoverColor')};
                            }`
                               : `
                            img {
                                border: 1px solid ${cvar('greyColor')};
                            }
                            &:hover img {
                                outline: 1px solid ${cvar('primaryHoverColor')};
                            }`
                       }
                        border-radius: 6px;
                        box-sizing: border-box;
                    }
                }
                .dex-gallery-react-photo-album--rows.multiple-pages {
                    justify-content: flex-start !important;
                    grid-gap: 10px !important;
                }
            }
        }
    }
    .dex-gallery-image-loader {
        position: absolute;
        width: 100%;
        background: white;
        opacity: 0.8;
        height: 100%;
        top: 0;
        .pda-delayed-spinner {
            height: 100%;
        }
    }
    `}
`;

export const backContainerStyle = css`
    display: flex;
    align-items: center;
`;

export const imageContainerStyle = (isVertical?: boolean) => css`
    ${isVertical
        ? `
    display: flex;
    flex-wrap: wrap;
    overflow: hidden auto;
    height: 100%;
    `
        : `display: flex;
    height: inherit;
    overflow: auto hidden;
    box-sizing: border-box;
    > :first-child{
        margin-left: 0;
    }
    > :last-child {
        margin-right: 0;
    }
    `}

    /* Hiding the scroll bars */
    /* Webkit */
    ::-webkit-scrollbar {
        display: none;
    }
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;

    .back {
        display: block;
    }
    .dex-gallery-image-loader {
        border: 1px solid ${cvar('greyColor')};
        height: 100%;
        border-radius: 6px;
        box-sizing: border-box;
    }
`;

export const elementFixedStyle = css`
    aspect-ratio: 1/1;
    margin: 0 5px;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    &.dex-gallery-loadingElement {
        border-radius: 5px;
        border: 1px solid ${cvar('greyColor')};
        background-color: #ffffff;
        opacity: 0.8;
        font-size: 14px;
    }
    .dex-gallery-image {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        border-radius: 4px;
        border: 1px solid ${cvar('greyColor')};
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    .dex-gallery-folder {
        cursor: pointer;
        aspect-ratio: 1/1;
        position: relative;
        height: 100%;
        border-radius: 5px;
        width: 100%;
        border: 1px solid;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column nowrap;
        padding: 0px 5px;
        color: ${cvar('primaryColor')};
        font-size: 10px;
    }
`;

export const containerElementStyle = (isVertical?: boolean) => css`
    height: 100%;
    margin: ${isVertical ? '0' : '0 5px'};
    position: relative;
    box-sizing: border-box;
    border: 1px solid transparent;
    &:hover {
        border: 1px solid ${cvar('primaryHoverColor')};
        border-radius: 5px;
        .dex-gallery-folder {
            border: none;
        }
    }
    &.dex-gallery-loadingElement {
        border-radius: 5px;
        border: 1px solid ${cvar('primaryColor')};
        ${!isVertical && `aspect-ratio: 1/1;`}
        height: 100%;
        background-color: #ffffff;
        opacity: 0.8;
        font-size: 14px;
    }
    img {
        height: 100%;
        border-radius: 5px;
        box-sizing: border-box;
        ${isVertical &&
        `max-height: 100%;
        min-width: 100%;
        object-fit: cover;`}
    }
    .dex-gallery-folder {
        cursor: pointer;
        aspect-ratio: 1/1;
        position: relative;
        height: 100%;
        border-radius: 5px;
        width: 100%;
        border: 1px solid;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column nowrap;
        padding: 0px 5px;
        color: ${cvar('primaryColor')};
        font-size: 10px;
    }
`;

export const buttonStyle = (
    isLoaded?: boolean,
    backgroundColor?: string,
    color?: string,
    hoverBackground?: string,
    hoverColor?: string,
) => css`
    line-height: 1px;
    background: ${backgroundColor ?? cvar('primaryActiveColor')};
    opacity: 1;
    color: ${color ?? `#ffffff`};
    padding: 5px;
    border: none;

    :hover:not([disabled]) {
        background: ${hoverBackground ?? cvar('primaryActiveColor')};
        ${hoverColor && `color: ${hoverColor};`}
        opacity: 1;
    }
    ${isLoaded ? `display:block` : 'display:none'}
`;

export const processingTextStyle = css`
    bottom: 5px;
    height: 16px;
    width: 75%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px;
    background-color: ${cvar('primaryColor')};
    text-align: center;
    position: absolute;
    line-height: 16px;
    font-size: 9px;
    color: #ffffff;

    ${mediaQueries.small} {
        line-height: 13px;
        font-size: 8px;
        bottom: 2px;
    }
`;

export const thumbnailOverlayStyle = (isClicked: boolean, isSmallDevice: boolean, isOverlayNeeded: boolean) => css`
    width: 100%;
    ${isOverlayNeeded ? 'height: 100%;' : 'height: auto; aspect-ratio: 1/1;'}
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    .dex-gallery-infoContainer {
        background: #ffffff;
        padding: 5px;
        font-size: 10px;
        text-align: center;
        width: 100%;
        height: 100%;
        .dex-gallery-info-box {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .dex-gallery-info {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    ${isClicked &&
    `${isOverlayNeeded && `opacity: 0.8;`}
        .dex-gallery-infoContainer {
            border-radius: 5px;
        }`}

    ${!isSmallDevice &&
    `&:hover {
            cursor:pointer;
            ${isOverlayNeeded && `opacity: 0.8;`}
            .dex-gallery-infoContainer {
                border-radius: 5px;
            }
        }`}
`;

export const borderStyle = (isClicked: boolean, isOverlayNeeded?: boolean) => css`
    img {
        ${isClicked && !isOverlayNeeded && `outline: 1px solid ${cvar('primaryColor')};`}
    }
`;

export const galleryActionStyle = css`
    position: absolute;
    background: #ffffff;
    &.dex-bottom {
        bottom: 3px;
    }
    &.dex-top {
        top: 3px;
    }
    &.dex-right {
        right: 3px;
    }
    &.dex-left {
        left: 3px;
    }
    &.dex-bottom.dex-right {
        border-bottom-right-radius: 6px;
    }
    &.dex-bottom.dex-left {
        border-bottom-left-radius: 6px;
    }
    &.dex-top.dex-left {
        border-top-left-radius: 6px;
    }
    &.dex-top.dex-right {
        border-top-right-radius: 6px;
    }
`;
export const iconStyle = css`
    margin-bottom: 4px;
`;

export const ellipsisStyle = css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
`;

export const contentWrapperStyle = css`
    height: 100%;
`;

export const subTitleStyle = css`
    color: ${cvar('primaryTextColor')};
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 8px 0 0 0;
`;
