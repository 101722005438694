/**
 * The purpose of a theme is that the colors and styles that are defined here can be reused around the application.
 * This can mean that a primary color, a secondary color, etc can be used here. While colors are the most useful
 * application of this, general spacing values, such as "compact" or "large" could describe the general sizes of all
 * components that are used.
 *
 * All components can always be directly altered, as all should have classNames for every DOM element. So try to
 * minimize one off things here.
 *
 * Do not nest values.
 */

import { getColor } from './colors';
import type { Theme } from './types';

/**
 * POTENTIAL VALUES (Don't add until we actually use them in several components and the theme is confirmed)
 * spacing: 'normal' | 'compact' | 'spacious; // Nice for padding and things
 * secondaryColor: string; // What would THIS really be used for?
 * white: string; // Don't use for intentionally white things, only for things that can deal with being offwhite
 * black: string; // The darkest color that shows? idk
 * iconColor: string; // Pretty self explanatory. But would this be ALL icons regardless of location? hmmm
 */

export enum Breakpoint {
    S = 600,
    M = 768,
    L = 992,
    XL = 1200,
}

export const defaultTheme: Theme = {
    contrastColor: getColor('white'),
    designAreaInteractionColor: '#00B5E2',
    disabledLightColor: getColor('grey-100'),
    disabledDarkColor: getColor('grey-300'),
    inactiveBorderColor: getColor('grey-200'),
    inactiveColor: getColor('grey-100'),
    primaryActiveColor: getColor('teal-800'),
    primaryBackgroundColor: getColor('white'),
    primaryColor: getColor('teal-600'),
    primaryColorLight: getColor('teal-400'),
    primaryColorDark: getColor('teal-800'),
    primaryHoverColor: getColor('teal-700'),
    primaryHoverLightColor: getColor('teal-100'),
    primaryOverlayColor: getColor('teal-600', 0.1),
    primaryTextColor: getColor('grey-700'),
    secondaryBackgroundColor: getColor('grey-050'),
    secondaryColor: getColor('grey-700'),
    inactiveIconColor: getColor('teal-600'),
    activeIconColor: getColor('teal-800'),
    errorColor: getColor('red-600'),
    errorDarkColor: getColor('red-700'),
    errorOverlayColor: getColor('red-600', 0.1),
    infoDarkColor: getColor('blue-700'),
    successDarkColor: getColor('green-700'),
    warningDarkColor: getColor('yellow-700'),
    backgroundColorDark: getColor('grey-800'),
    selectFontFamily: 'Open Sans, sans-serif',
    designAreaInteractionBackgroundColor: '#F5F8F8',
    primaryBorderColor: '#EAEAEA',
    filterCtaColor: '#007A66',
    filterIconColor: '#007A66',
    radioButtonColor: '#007a66',
    activeFilterColor: '#007A66',
    filterOrientationColor: '#EFF0F0',
    backgroundColorWhite: '#ffffff',
    backgroundColorGrey: '#eff0f0',
    darkGreyBorderColor: '#c0c3c4',
    shadowColor: '#cfd6d6',
    customFontFamily: 'Montserrat',
    backgroundColorBlack: '#000000',
    selectDropdownBorderColor: '#9bb1b1',
    disabledBackgroundColor: '#9bb1b11a',
    floatingPlusIconColor: '#ffffff',
    contentDrawerOpacity: '#000000',
    colorInfoTextMessageCanvas: '#9BB1B1',
    colorDeleteIcon: '#f83535',
    backgroundColorErrorNotify: '#FCEDE9',
    backgroundColorInfoNotify: '#0076FF1A',
    backgroundColorSuccessNotify: '#E9FBEE',
    backgroundColorWarningNotify: '#FEF4E9',
    iconColorErrorNotify: '#F83535',
    iconColorInfoNotify: '#0076FF',
    iconColorSuccessNotify: '#26D956',
    iconColorWarningNotify: '#FDA922',
    borderColorErrorNotify: '#FFD8D8',
    borderColorInfoNotify: '#C7DFFA',
    borderColorSuccessNotify: '#BBF7CB',
    borderColorWarningNotify: '#FCE0B5',
    backgroundNotificationBadge: '#EA4C4C',
    loaderCircle1: '#00897B',
    loaderCircle2: '#00897BBD',
    loaderCircle3: '#00897B80',
    loaderCircle4: '#00897B40',
    greyBorderColor: '#dfdfdf',
    greyColor: '#e1e1e1',
};

export const mediaQueries = {
    small: `@media (max-width: ${Breakpoint.S}px)`,
    medium: `@media (max-width: ${Breakpoint.M}px)`,
    large: `@media (max-width: ${Breakpoint.L}px)`,
    extraLarge: `@media (max-width: ${Breakpoint.XL}px)`,
};
