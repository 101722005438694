import { hex2rgb } from '@design-stack-ct/utility-core';

export const defaultColors = {
    // General palette
    black: '#000000',
    'grey-900': '#131515',
    'grey-800': '#272A2B',
    'grey-700': '#3A3E40',
    'grey-600': '#4E5356',
    'grey-500': '#676D73',
    'grey-400': '#818689',
    'grey-300': '#A0A4A6',
    'grey-200': '#C0C3C4',
    'grey-100': '#DFE1E1',
    'grey-050': '#EFF0F0',
    white: '#FFFFFF',
    'yellow-800': '#AB6700',
    'yellow-700': '#E09705',
    'yellow-600': '#FFC71F',
    'yellow-500': '#FFD24C',
    'yellow-400': '#FFD862',
    'yellow-300': '#FFE38F',
    'yellow-200': '#FFEFC2',
    'yellow-100': '#FFF9E9',
    'green-800': '#0B441E',
    'green-700': '#126D30',
    'green-600': '#16883C',
    'green-500': '#45A063',
    'green-400': '#5CAC77',
    'green-300': '#8BC49E',
    'green-200': '#C9E3D2',
    'green-100': '#F2F8F4',
    'blue-800': '#0F3D6B',
    'blue-700': '#175CA1',
    'blue-600': '#1774D0',
    'blue-500': '#438FDB',
    'blue-400': '#71A9E0',
    'blue-300': '#ABCCED',
    'blue-200': '#CBE0F6',
    'blue-100': '#EEF5FB',
    'red-800': '#6E140C',
    'red-700': '#B02113',
    'red-600': '#DC2918',
    'red-500': '#E55A4D',
    'red-400': '#E86A5E',
    'red-300': '#F39991',
    'red-200': '#F7CDCA',
    'red-100': '#FCEAE8',
    'teal-800': '#003D33',
    'teal-700': '#006252',
    'teal-600': '#007A66',
    'teal-500': '#339585',
    'teal-400': '#4DA294',
    'teal-300': '#80BDB3',
    'teal-200': '#B3D7D1',
    'teal-100': '#E6FFFB',
};

export type ThemeColors = typeof defaultColors;

/**
 *
 * @param {string} id the key of the defaultColors
 * @param {number=1} alpha number that determines if the return value will be a hex or rgba value
 * @returns {string} a hex or rgba value
 */
export function getColor(id: keyof ThemeColors, alpha = 1) {
    if (alpha >= 1) return defaultColors[id];
    const { r, g, b } = hex2rgb(defaultColors[id]);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
