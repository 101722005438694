import type { Modifier } from '@popperjs/core';
import { detectOverflow } from '@popperjs/core';

import { clamp } from '@design-stack-ct/utility-core';

// based on https://www.npmjs.com/package/popper-max-size-modifier
// TODO: check the types
// eslint-disable-next-line @typescript-eslint/ban-types
const calculateMaxHeightModifier: Modifier<'calculateMaxHeight', {}> = {
    name: 'calculateMaxHeight',
    enabled: true,
    phase: 'main',
    requiresIfExists: ['offset', 'preventOverflow', 'flip'],
    fn: ({ state, name, options }) => {
        const overflow = detectOverflow(state, options);
        const yOffset = state.modifiersData.preventOverflow?.y ?? 0;
        const { height: popperHeight } = state.rects.popper;
        const [basePlacement] = state.placement.split('-');
        const heightProp = basePlacement === 'top' ? 'top' : 'bottom';

        // eslint-disable-next-line no-param-reassign
        state.modifiersData[name] = {
            height: popperHeight - overflow[heightProp] - yOffset,
        };
    },
};

// TODO: check the types
// eslint-disable-next-line @typescript-eslint/ban-types
const applyMaxHeightModifier: Modifier<'applyMaxHeight', {}> = {
    name: 'applyMaxHeight',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['calculateMaxHeight'],
    requiresIfExists: ['flip'],
    fn: ({ state }) => {
        if (state.placement !== state.options.placement) {
            // Flip is active, don't interfere
            return;
        }

        const { height } = state.modifiersData.calculateMaxHeight;
        // eslint-disable-next-line no-param-reassign
        state.styles.popper.maxHeight = `${clamp(height, 100, 400)}px`;
    },
};

export const popperModifiers: Partial<Modifier<unknown, object>>[] = [
    calculateMaxHeightModifier,
    applyMaxHeightModifier,
];
