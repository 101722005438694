import styled from '@emotion/styled';

import { mediaQueries } from '../theme';

export const InputSliderLabel = styled.label`
    width: 100%;
    font-size: 12px;
    line-height: 16px;

    ${mediaQueries.small} {
        font-size: 11px;
    }
`;
