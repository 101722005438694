import type { ReactNode } from 'react';

import type { IconsProp } from '../types';

export type BannerVariant = 'success' | 'info' | 'warning' | 'error';

export interface BannerContent {
    variant: BannerVariant;
    message: string;
}

export enum BannerType {
    notification = 'notification',
    message = 'message',
}

interface BannerIcons extends IconsProp {
    variant: {
        icon: string;
    };
}

export interface BannerProps {
    variant?: BannerVariant;
    icons?: BannerIcons;
    children: ReactNode | ReactNode[];
    onDismiss?: () => void;
    className?: string;
    bannerType?: BannerType;
}
