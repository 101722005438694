import { useEffect } from 'react';

import { usePreviewConfiguration } from '../../providers';

interface VortexModelLoadingProps {
    loading?: boolean;
}

export const VortexModelLoading = ({ loading }: VortexModelLoadingProps) => {
    const { vortexConfiguration } = usePreviewConfiguration();

    useEffect(() => {
        if (vortexConfiguration && vortexConfiguration.successCallback && !loading) {
            vortexConfiguration.successCallback(true);
        }
    }, [loading, vortexConfiguration]);

    return null;
};
