import type { ChangeEvent,DetailedHTMLProps, FocusEvent, InputHTMLAttributes, ReactNode } from 'react';
import React from 'react';

import { css, cx } from '@emotion/css';

import { inputInputClassName } from './config';
import { InputContainer } from './InputContainer';
import type { InputIcons } from './types';
import { cvar } from '../theme';

export interface InputProps
    extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'> {
    value?: string | number;
    onChange(value: string | number): void;
    onFocus?: (event?: FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event?: FocusEvent<HTMLInputElement>) => void;
    prefixLabel?: string | ReactNode;
    suffixLabel?: string | ReactNode;
    labelledBy?: string;
    label?: string;
    type?: 'text' | 'number' | 'date' | 'url';
    validation?: {
        message?: string;
        borderColor?: string;
        backgroundColor?: string;
        labelOverlayColor?: string;
    };
    icons?: InputIcons;
}

export const inputStyle = css`
    display: block;
    flex: auto;
    margin: auto;
    position: relative;
    height: 16px;
    line-height: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    margin-left: 4px;
    margin-right: 4px;
    color: #000;
    background-color: rgba(
        255,
        255,
        255,
        0
    ); // rgba used to workaound Safari bug https://bugs.webkit.org/show_bug.cgi?id=115510
    border: 1px solid transparent;
    font-family: inherit;
    font-size: inherit;
    -moz-appearance: textfield;
    -webkit-appearance: none;

    &:hover,
    &:focus {
        -moz-appearance: number-input;
        outline: none;
    }

    &:disabled {
        color: ${cvar('disabledDarkColor')};
    }

    &::placeholder {
        color: #8090a2;
    }
`;

/**
 * This is a simple styled Input Component
 */
export function Input({
    value,
    onChange,
    className,
    prefixLabel,
    suffixLabel,
    labelledBy,
    label,
    validation,
    disabled,
    icons,
    ...restOfProps
}: InputProps) {
    const wrappedOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.type === 'number') {
            onChange(+e.currentTarget.value as number);
        } else {
            onChange(e.currentTarget.value as string);
        }
    };

    return (
        <InputContainer
            className={className}
            prefixLabel={prefixLabel}
            suffixLabel={suffixLabel}
            validationMessage={validation?.message}
            icons={icons}
            customBorderColor={validation?.borderColor}
            customBackgroundColor={validation?.backgroundColor}
            labelOverlayColor={validation?.labelOverlayColor}
        >
            <input
                data-testid={inputInputClassName}
                className={cx(inputInputClassName, inputStyle)}
                value={value}
                onChange={wrappedOnChange}
                disabled={disabled}
                aria-label={label}
                aria-labelledby={labelledBy}
                {...restOfProps}
            />
        </InputContainer>
    );
}
