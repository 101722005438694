import { css } from '@emotion/css';

import { cvar,mediaQueries } from '../theme';

export const dexCommonModalCancelIconStyle = css`
    > svg {
        height: 22px;
        width: 22px;
    }
`;

export const mainHeadingStyle = css`
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    font-family: Montserrat;
    ${mediaQueries.small} {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        font-family: Montserrat;
    }
    letter-spacing: 0px;
    color: #313131;
    opacity: 1;
`;

export const subHeadingStyle = css`
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    font-family: Montserrat;
    ${mediaQueries.small} {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 14px;
        font-family: Montserrat;
    }
    letter-spacing: 0px;
    color: #313131;
    opacity: 1;
`;

export const headerStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    gap: 10px;
    .dsc-icon-button {
        display: flex;
        flex-direction: inherit;
        padding: 0;
        justify-content: center;
        align-items: flex-end;
    }
`;

export const buttonContainerStyle = (isSmallDevice: boolean) => css`
    display: flex;
    ${!isSmallDevice &&
    `
    width: 30%;
    justify-content: space-between;
    column-gap: 32px;
    max-width: 520px;
    `}
    ${isSmallDevice &&
    `display: flex;
    flex-direction: row;
    width: auto;
    align-items: center;
    justify-content: center;`}
    button {
        border-radius: 6px;
        font-weight: bold;
        font-family: ${cvar('customFontFamily')};
        font-size: 16px;
        padding: 10px 10px;
        display: flex;
        flex: 1;
        width: 100%;
        height: 100%;
        justify-content: center;
        ${isSmallDevice &&
        `align-items: center;
        justify-content: center;
        width: 250px;
        height: 50px;`}
    }
`;

export const buttonStyle = css`
    border-radius: 6px;
    font-weight: bold;
    font-family: ${cvar('customFontFamily')};
    font-size: 16px;
    padding: 10px 10px;
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
`;

export const footerStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    ${mediaQueries.small} {
        flex-direction: column;
        justify-content: center;
        gap: 15px;
        padding-bottom: 20px;
    }
`;

export const cardStyle = (isSmallDevice: boolean) => css`
    ${!isSmallDevice &&
    `
    padding: 23px 25px 40px 25px;
    `}
    ${isSmallDevice &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 13px;
    `}
    .dsc-card__content {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 30px;
        ${mediaQueries.small} {
            justify-content: space-between;
        }
        ${isSmallDevice && `gap: 15px;`}
    }
`;

export const bodyStyle = (isSmallDevice: boolean) => css`
    display: flex;
    flex-direction: column;
    gap: 12px;
    ${!isSmallDevice &&
    `
    margin: auto;
    width: 100%;
    height: 100%;
    `}
    text-align: center;
    font-family: ${cvar('customFontFamily')};
    align-items: center;
    ${isSmallDevice &&
    `
    max-width: 50%;
    margin: 0 auto;
    position: relative;
    `}
`;
