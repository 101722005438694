import React from 'react';

import { isEmptyObject } from '@dexter/photo-store';

import GalleryChild from './GalleryChild';

interface GalleryProps {
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
}

export default function Gallery({ data }: GalleryProps) {
    const dataValues = Object.values(data);

    return (
        <div>
            {dataValues.map(
                // TODO: Remove any
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (val: any) => {
                    if (isEmptyObject(val) === false) {
                        if (val && val?.depositPhoto.status === 'success') {
                            return (
                                <div key={val?.providerId}>
                                    <div
                                        style={{ fontSize: '30px', textAlign: 'center', margin: '20px' }}
                                        key={val?.providerId}
                                    >
                                        {val?.providerId}
                                    </div>
                                    <GalleryChild item={val} />
                                </div>
                            );
                        }
                    }
                    return null;
                },
            )}
        </div>
    );
}
