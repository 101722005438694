import React from 'react';

import type { NumberInputBaseProps } from './NumberInputBase';
import { NumberInputBase } from './NumberInputBase';
import { inputStyle } from '../input/Input';
import { InputContainer } from '../input/InputContainer';

export type NumberInputProps = {
    prefixLabel?: string;
    suffixLabel?: string;
    validationMessage?: string;
    disabled?: boolean;
} & NumberInputBaseProps;

export function NumberInput({
    className,
    prefixLabel,
    suffixLabel,
    validationMessage,
    disabled,
    ...props
}: NumberInputProps) {
    return (
        <InputContainer
            className={className}
            prefixLabel={prefixLabel}
            suffixLabel={suffixLabel}
            validationMessage={validationMessage}
            disabled={disabled}
        >
            <NumberInputBase className={inputStyle} disabled={disabled} {...props} />
        </InputContainer>
    );
}
