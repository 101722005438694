import type { HTMLProps, ReactNode,Ref } from 'react';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { css, cx } from '@emotion/css';

const dragHandleStyle = css`
    top: 0;
    width: 100%;
    height: 40px;
    text-align: center;
    position: absolute;
`;

interface DragHandleProps extends HTMLProps<HTMLDivElement> {
    children: ReactNode;
}

function DragHandle({ children, ...rest }: DragHandleProps, forwardedRef: Ref<HTMLDivElement | null>) {
    const dragRef = useRef(null);
    useImperativeHandle(forwardedRef, () => dragRef.current);

    return (
        <section
            data-testid="dsc-drag-handle"
            ref={dragRef}
            className={cx('dsc-drawer-drag-handle', dragHandleStyle)}
            {...rest}
        >
            {children}
        </section>
    );
}

export default forwardRef(DragHandle);
