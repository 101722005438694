import type { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import React from 'react';

import { css, cx } from '@emotion/css';

import { H2 } from '../headings';
import { cvar } from '../theme';

export interface CardProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    header?: string | ReactNode;
    children: ReactNode | ReactNode[];
    className?: string;
}

const cardStyle = css`
    border: 1px solid ${cvar('primaryBorderColor')};
    border-radius: 8px;
    background-color: ${cvar('primaryBackgroundColor')};
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
`;

const headerStyle = css`
    background-color: ${cvar('secondaryBackgroundColor')};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid ${cvar('primaryBorderColor')};
    padding: 12px 20px;
`;

const contentStyle = css`
    padding: 20px;
`;

const h2Style = css`
    margin: 0;
`;

export function Card({ header, children, className, ...restOfProps }: CardProps) {
    return (
        <div
            role="region"
            aria-label={typeof header === 'string' ? header : undefined}
            className={cx('dsc-card', cardStyle, className)}
            {...restOfProps}
        >
            {header && (
                <header className={cx('dsc-card__header', headerStyle)}>
                    {typeof header === 'string' ? <H2 className={h2Style}>{header}</H2> : header}
                </header>
            )}
            <section className={cx('dsc-card__content', contentStyle)}>{children}</section>
        </div>
    );
}
