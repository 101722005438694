/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Fix the types here
import axios from 'axios';
import { useEffect, useState } from 'react';

import type { SearchProps } from '../types';

export function useSearch({
    api,
    authToken,
    searchQuery = 'nature',
    searchLimit = 100,
    enable = true,
    searchOffset = 0,
    filter = { color: '', people: 'all', orientation: '' },
    language = 'en',
}: SearchProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>({});
    const [result, setResult] = useState<any>({});
    const [ajaxWithSameConfig, setAjaxWithSameConfig] = useState<number>(0);

    const searchWithSameConfig = () => {
        setAjaxWithSameConfig((prevState: number) => prevState + 1);
    };

    useEffect(() => {
        if (enable && searchQuery !== '' && authToken) {
            let cancel: any;
            setLoading(true);
            axios({
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${authToken}`,
                },
                method: 'POST',
                url: api,
                data: {
                    searchQuery,
                    searchLimit,
                    searchOffset,
                    filter,
                    language,
                },
                cancelToken: new axios.CancelToken((c) => {
                    cancel = c;
                }),
            })
                .then((response: any) => {
                    if (response?.status === 200 && response?.data) {
                        if (
                            response?.data?.status !== true &&
                            response?.data?.data?.type === 'failure' &&
                            response?.data?.data?.error
                        ) {
                            setError(response.data);
                            setResult({});
                        }
                        if (response?.data?.status === true && response?.data?.data?.type === 'success') {
                            setError({});
                            setResult(response.data.data);
                        }
                        setLoading(false);
                    }
                })
                .catch((ajaxError: any) => {
                    //* errormsg : added error message keyword which should be in same format as of api
                    //* errorcode : added error code keyword which should be in same format as of api
                    //* we will customise it to a general error
                    if (ajaxError.request) {
                        //* The request was made but no response was received
                        setError({
                            error: {
                                errormsg: 'Unable to get response from server. Please try again.',
                                errorcode: 101,
                            },
                            type: 'failure',
                        });
                        setLoading(false);
                        setResult({});
                        return;
                    }
                    if (axios.isCancel(ajaxError)) {
                        return;
                    }

                    setError(ajaxError);
                    setLoading(false);
                    setResult({});
                });
            return () => cancel();
        }

        setLoading(false);
        return () => {};
        // TODO: Revisit this
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        searchQuery,
        searchLimit,
        searchOffset,
        filter.color,
        filter.orientation,
        filter.people,
        api,
        authToken,
        enable,
        language,
        ajaxWithSameConfig,
    ]);

    return {
        result,
        loading,
        error,
        searchWithSameConfig,
    };
}
