import { downloadHDImage } from '@dexter/deposit-photo-sdk';

import { processDownloadHDImageData } from '../helpers/processDownloadHDImageData';
import type { DpDownloadConfig, DpDownloadResponseProps } from '../types';

const useGetHDImage = (downloadConfig: DpDownloadConfig, authToken: string) => {
    const finalDownloadConfig = { ...downloadConfig };

    const getHDImage = (imageId: string) =>
        downloadHDImage(finalDownloadConfig, imageId, authToken).then((apiResult) => {
            // TODO: Using `as` for the moment
            const result = processDownloadHDImageData(imageId, apiResult as DpDownloadResponseProps);

            return result;
        });

    return {
        getHDImage,
    };
};

export { useGetHDImage };
