import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { css } from '@emotion/css';

import { Button, Toggle } from '@dexter/dex-component-library';
import { Icon, useIcons } from '@dexter/dex-icon-library';
import type { PreviewWithoutCimDocProps } from '@dexter/fusion-preview';
import { FusionPreview as Preview, PREFIX_CLASS, PreviewPosition } from '@dexter/fusion-preview';

import type { ProductConfig } from './config/productConfig';
import { PRODUCT } from './config/productConfig';
import type { McpProduct, VariantsProps } from './demoProducts';
import { demoProducts } from './demoProducts';
import { useAuth } from '../../AuthProvider';

const layoutStyle = css`
    background-color: #fff;
    padding: 0 50px 20px 50px;
    .configuration-column {
        flex: 60%;
    }
    h2 {
        font-size: 24px;
        color: #007a66;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 16px;
    }
    h3 {
        color: #007a66;
    }
    .configuration {
        margin-bottom: 20px;
    }
    .field {
        margin-bottom: 10px;
        max-width: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        input,
        select {
            margin: 0 10px 0 10px;
            padding: 10px 15px;
            border-radius: 10px;
            border: 1px solid;
            min-width: 180px;
            max-width: 170px;
            box-sizing: border-box;
        }
    }
    .preview {
        margin-top: 10%;
        .${PREFIX_CLASS}-thumbnail {
            border: 1px solid #eaeaea;
        }
        & .${PREFIX_CLASS}-thumbnail-360 {
            color: #007a66;
        }
        & .active {
            border: 2px solid #007a66;
        }
    }
`;

export default function FusionPreview() {
    const { accessToken } = useAuth();
    const [productIndex, setProductIndex] = useState(0);
    const skuAndAttributes: ProductConfig = {
        sku: PRODUCT[productIndex].sku,
        attributes: PRODUCT[productIndex].attributes,
    };
    const error = {
        error: {
            showErrorMessage: true,
            errorMessage: 'Unable to load the 3D preview.',
        },
    };
    const [filteredItems, setFilteredItems] = useState<McpProduct[]>(demoProducts);
    const [showSettings, setShowSettings] = useState({
        tenant: false,
        scene: false,
        preview: true,
        docRef: false,
        vortex: false,
    });
    const [showFusionPreview, setShowFusionPreview] = useState(false);
    const [animation, setAnimation] = useState({
        animationDuration: 3,
    });
    const [dimensions, setDimensions] = useState({});
    const [cimDoc, setCimDoc] = useState(PRODUCT[productIndex].cimDoc);
    const [docRef, setDocRef] = useState('');
    const [previewOptions, setPreviewOptions] = useState<PreviewWithoutCimDocProps>({
        productConfiguration: {
            sku: '',
            attributes: {},
        },
        tenantConfiguration: {
            id: 'pixart',
            type: 'merchants',
        },
        previewSettings: {
            vortex: true,
            scene: true,
            position: PreviewPosition.BOTTOM,
        },
        sceneConfiguration: {
            purpose: 'product',
            subPurpose: 'full',
            tags: [],
        },
        vortexConfiguration: {
            vortexActions: true,
            loader: 'Loading...',
            dimensions: {
                width: '400px',
                height: '400px',
            },
            error: {
                showErrorMessage: true,
                errorMessage: 'Unable to load 3D preview',
            },
            onAnimate: animation,
        },
    });
    const previewPosition = Object.values(PreviewPosition);
    const dimensionValue = _.merge(previewOptions.vortexConfiguration?.dimensions, dimensions);
    const productConfigurationValue =
        previewOptions.productConfiguration.sku !== '' &&
        previewOptions.productConfiguration.attributes.attributes !== ''
            ? previewOptions.productConfiguration
            : skuAndAttributes;
    const cimDocMerged = _.merge({ version: '', document: { panels: [] }, fontRepositoryUrl: '' }, cimDoc);
    const icons = useIcons();

    const checkValidJson = (value: string) => {
        try {
            return JSON.parse(value);
        } catch (e) {
            return '';
        }
    };

    const onProductConfigurationChange = (name: string) => (value: string) => {
        setShowFusionPreview(false);
        if (name === 'sku') {
            const index = PRODUCT.findIndex((product) => product.sku === value);
            if (index >= 0) {
                setProductIndex(index);
            }
        }
        setPreviewOptions((rPreviewOptions: PreviewWithoutCimDocProps) => ({
            ...rPreviewOptions,
            productConfiguration: {
                ...rPreviewOptions.productConfiguration,
                [name]: name === 'sku' ? value : checkValidJson(value.trim()),
            },
        }));
    };

    const handleSelectionSku = (product: string) => {
        const unit = product.split('_');
        const filteredItemsValue = demoProducts.filter((item) => item.sku === unit[0] && item.name === unit[1]);
        onProductConfigurationChange('attributes')(JSON.stringify(filteredItemsValue[0].variants[0].skuVariables));
        setFilteredItems(filteredItemsValue);
    };

    const onPreviewSettingsChange = (name: string) => (value: string | boolean) => {
        setShowFusionPreview(false);

        const keyName = name === 'vortexActions' ? 'vortexConfiguration' : 'previewSettings';

        setPreviewOptions((rPreviewOptions) => ({
            ...rPreviewOptions,
            [keyName]: {
                ...rPreviewOptions.previewSettings,
                [name]: value,
            },
        }));
    };

    const handleVortexSettings = () => {
        setShowSettings((prevShowSettings) => ({
            ...prevShowSettings,
            vortex: !prevShowSettings.vortex,
        }));
    };

    const handleShowFusionPreview = () => {
        setShowFusionPreview(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(docRef);
            const data = await response.json();
            const cimDocUrl = await fetch(data.cimDocUrl);
            const newCimDoc = await cimDocUrl.json();
            setCimDoc(newCimDoc);
        };
        docRef !== '' && fetchData();
    }, [docRef]);

    return (
        <div className={layoutStyle} style={{ display: 'flex' }}>
            <div className="configuration-column">
                <h1>Fusion Preview Configurations</h1>
                <div className="configuration">
                    <h2>Product Configurations</h2>
                    <div className="field">
                        <span>SKU:</span>
                        <input
                            type="text"
                            defaultValue={skuAndAttributes.sku}
                            placeholder="SKU"
                            onChange={(event) => {
                                onProductConfigurationChange('sku')(event.target.value);
                            }}
                        />
                    </div>
                    <div className="field">
                        <span>Attributes:</span>
                        <input
                            defaultValue={JSON.stringify(skuAndAttributes.attributes)}
                            type="text"
                            placeholder="Attributes"
                            onChange={(event) => {
                                onProductConfigurationChange('attributes')(event.target.value);
                            }}
                        />
                    </div>
                    OR
                    <div className="field">
                        <span>SKU</span>
                        <select
                            onChange={(event) => {
                                handleSelectionSku(event.target.value);
                                onProductConfigurationChange('sku')(event.target.value.split('_')[0]);
                            }}
                        >
                            {demoProducts.map((p: McpProduct) => (
                                <option key={`${p.sku}_${p.name}`} value={`${p.sku}_${p.name}`}>
                                    {p.sku} ({p.name})
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="field">
                        <span>Attributes</span>
                        <select
                            onChange={(event) => {
                                onProductConfigurationChange('attributes')(event.target.value);
                            }}
                        >
                            {filteredItems[0].variants.map((p: VariantsProps, index: number) => (
                                <option key={index} value={JSON.stringify(p.skuVariables)}>
                                    {p.sampleName ?? JSON.stringify(p.skuVariables)}
                                </option>
                            ))}
                        </select>
                    </div>
                    <h2
                        onClick={() => {
                            setShowSettings((prevShowSettings) => ({
                                ...prevShowSettings,
                                tenant: !prevShowSettings.tenant,
                            }));
                        }}
                    >
                        Tenant Configurations
                    </h2>
                    {showSettings.tenant && (
                        <>
                            <div className="field">
                                <span>Tenant ID:</span>
                                <input
                                    defaultValue={previewOptions.tenantConfiguration?.id}
                                    type="text"
                                    placeholder="tenant id"
                                    onChange={(event) => {
                                        setShowFusionPreview(false);
                                        const previewType = previewOptions.tenantConfiguration?.type;
                                        if (event.target.value !== '' && previewType) {
                                            setPreviewOptions((rPreviewOptions: PreviewWithoutCimDocProps) => ({
                                                ...rPreviewOptions,
                                                tenantConfiguration: {
                                                    id: event.target.value,
                                                    type: previewType,
                                                },
                                            }));
                                        }
                                    }}
                                />
                            </div>
                            <div className="field">
                                <span>Tenant Type </span>
                                <input
                                    defaultValue={previewOptions.tenantConfiguration?.type}
                                    type="text"
                                    placeholder="tenant type"
                                    onChange={(event) => {
                                        setShowFusionPreview(false);
                                        const previewId = previewOptions.tenantConfiguration?.id;
                                        if (event.target.value !== '' && previewId) {
                                            setPreviewOptions((rPreviewOptions: PreviewWithoutCimDocProps) => ({
                                                ...rPreviewOptions,
                                                tenantConfiguration: {
                                                    id: previewId,
                                                    type: event.target.value,
                                                },
                                            }));
                                        }
                                    }}
                                />
                            </div>
                        </>
                    )}
                    <h2
                        onClick={() => {
                            setShowSettings((prevShowSettings) => ({
                                ...prevShowSettings,
                                scene: !prevShowSettings.scene,
                            }));
                        }}
                    >
                        Scene Configuration
                    </h2>
                    {showSettings.scene && (
                        <>
                            <div className="field">
                                <span>Purpose:</span>
                                <input
                                    defaultValue={previewOptions.sceneConfiguration?.purpose}
                                    type="text"
                                    placeholder="purpose"
                                    onChange={(event) => {
                                        setShowFusionPreview(false);
                                        const subPurpose = previewOptions.sceneConfiguration?.subPurpose;
                                        const tags = previewOptions.sceneConfiguration?.tags;
                                        if (event.target.value !== '' && subPurpose) {
                                            setPreviewOptions((rPreviewOptions: PreviewWithoutCimDocProps) => ({
                                                ...rPreviewOptions,
                                                sceneConfiguration: {
                                                    purpose: event.target.value,
                                                    subPurpose,
                                                    tags,
                                                },
                                            }));
                                        }
                                    }}
                                />
                            </div>
                            <div className="field">
                                <span>Sub Purpose:</span>
                                <input
                                    defaultValue={previewOptions.sceneConfiguration?.subPurpose}
                                    type="text"
                                    placeholder="Sub Purpose"
                                    onChange={(event) => {
                                        setShowFusionPreview(false);
                                        const purpose = previewOptions.sceneConfiguration?.purpose;
                                        const tags = previewOptions.sceneConfiguration?.tags;
                                        if (event.target.value !== '' && purpose) {
                                            setPreviewOptions((rPreviewOptions: PreviewWithoutCimDocProps) => ({
                                                ...rPreviewOptions,
                                                sceneConfiguration: {
                                                    purpose,
                                                    subPurpose: event.target.value,
                                                    tags,
                                                },
                                            }));
                                        }
                                    }}
                                />
                            </div>
                            <div className="field">
                                <span>Tags:</span>
                                <input
                                    defaultValue={previewOptions.sceneConfiguration?.tags}
                                    type="text"
                                    placeholder="Tags"
                                    onChange={(event) => {
                                        setShowFusionPreview(false);
                                        const purpose = previewOptions.sceneConfiguration?.purpose;
                                        const subPurpose = previewOptions.sceneConfiguration?.subPurpose;
                                        if (event.target.value !== '' && purpose && subPurpose) {
                                            setPreviewOptions((rPreviewOptions: PreviewWithoutCimDocProps) => ({
                                                ...rPreviewOptions,
                                                sceneConfiguration: {
                                                    purpose,
                                                    subPurpose,
                                                    tags: event.target.value.split(','),
                                                },
                                            }));
                                        }
                                    }}
                                />
                            </div>
                        </>
                    )}
                    <h2
                        onClick={() => {
                            setShowSettings((prevShowSettings) => ({
                                ...prevShowSettings,
                                preview: !prevShowSettings.preview,
                            }));
                        }}
                    >
                        Preview Settings
                    </h2>
                    {showSettings.preview && (
                        <>
                            <div className="field">
                                <span>Vortex Enabled:</span>
                                <Toggle
                                    checked={previewOptions.previewSettings?.vortex}
                                    onChange={onPreviewSettingsChange('vortex')}
                                ></Toggle>
                            </div>
                            <div className="field">
                                <span>Vortex actions enabled: </span>
                                <Toggle
                                    checked={previewOptions.vortexConfiguration?.vortexActions}
                                    onChange={onPreviewSettingsChange('vortexActions')}
                                ></Toggle>
                            </div>
                            <div className="field">
                                <span>Scenes enabled: </span>
                                <Toggle
                                    checked={previewOptions.previewSettings?.scene}
                                    onChange={onPreviewSettingsChange('scene')}
                                ></Toggle>
                            </div>
                            <div className="field">
                                <span>Preview Position: </span>
                                <select
                                    value={previewOptions.previewSettings?.position}
                                    onChange={(event) => {
                                        onPreviewSettingsChange('position')(event.target.value);
                                    }}
                                >
                                    {previewPosition.map((position) => (
                                        <option key={position}>{position}</option>
                                    ))}
                                </select>
                            </div>
                        </>
                    )}
                    <h2
                        onClick={() => {
                            setShowSettings((prevShowSettings) => ({
                                ...prevShowSettings,
                                docRef: !prevShowSettings.docRef,
                            }));
                        }}
                    >
                        DocRef
                    </h2>
                    {showSettings.docRef && (
                        <>
                            <div className="field">
                                <span>DocRef URL: </span>
                                <input
                                    type="text"
                                    placeholder="docRef.url"
                                    onChange={(event) => {
                                        setDocRef(event.target.value);
                                    }}
                                />
                            </div>
                        </>
                    )}
                    <h2 onClick={() => handleVortexSettings()}>
                        Vortex Settings
                        <Icon content={icons.SETTINGS.icon} />
                    </h2>
                    {showSettings.vortex && (
                        <div>
                            <h3>Dimensions</h3>
                            <div className="field">
                                <span>Width: </span>
                                <input
                                    defaultValue={previewOptions.vortexConfiguration?.dimensions?.width}
                                    type="text"
                                    placeholder="width"
                                    onChange={(event) => {
                                        setShowFusionPreview(false);
                                        setDimensions((prevDimensions) => ({
                                            ...prevDimensions,
                                            width: `${event.target.value}px`,
                                        }));
                                    }}
                                />
                            </div>
                            <div className="field">
                                <span>Height: </span>
                                <input
                                    defaultValue={previewOptions.vortexConfiguration?.dimensions?.height}
                                    type="text"
                                    placeholder="height"
                                    onChange={(event) => {
                                        setShowFusionPreview(false);
                                        setDimensions((prevDimensions) => ({
                                            ...prevDimensions,
                                            height: `${event.target.value}px`,
                                        }));
                                    }}
                                />
                            </div>
                            <h3>Animate</h3>
                            <div className="field">
                                <span>Animation Duration: </span>
                                <input
                                    defaultValue={previewOptions.vortexConfiguration?.onAnimate?.animationDuration}
                                    type="number"
                                    placeholder="animate duration"
                                    onChange={(event) => {
                                        setShowFusionPreview(false);
                                        setAnimation({
                                            animationDuration: Number(event.target.value),
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div className="field">
                    <span></span>
                    <Button onClick={handleShowFusionPreview}>Load</Button>
                </div>
            </div>
            <div className="preview">
                {showFusionPreview && accessToken && (
                    <Preview
                        cimDoc={cimDocMerged}
                        authToken={accessToken}
                        productConfiguration={productConfigurationValue}
                        tenantConfiguration={previewOptions.tenantConfiguration}
                        previewSettings={previewOptions.previewSettings}
                        sceneConfiguration={previewOptions.sceneConfiguration}
                        vortexConfiguration={{
                            vortexActions: previewOptions.vortexConfiguration?.vortexActions,
                            dimensions: dimensionValue,
                            error: error.error,
                            onAnimate: previewOptions.vortexConfiguration?.onAnimate,
                        }}
                    />
                )}
            </div>
        </div>
    );
}
