import type { PhotoStoreConfigProps as PsConfigProps } from '@dexter/photo-store';

import type { PhotoStoreConfigProps } from '../config/photoStoreConfig';

export const transformData = (data: PhotoStoreConfigProps[]): PsConfigProps => {
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = {};
    const filteredData = data.filter((item: PhotoStoreConfigProps) => item.enable);
    const newData = filteredData.map((item: PhotoStoreConfigProps) => item);

    newData.forEach(
        // TODO: Remove any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => {
            const p = item.provider;
            obj[p] = item;
        },
    );

    const finalObject = { providers: { ...obj } };
    return finalObject;
};
