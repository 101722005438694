import styled from '@emotion/styled';

import { inputSliderSpacing } from './config';

export const InputSliderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 ${inputSliderSpacing}px;
`;
