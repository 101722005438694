import type { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';
import React from 'react';

import { css, cx } from '@emotion/css';

import { cvar } from '../../theme';

export interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    children?: ReactNode | ReactNode[];
    /**
     * Whether to show a normal button, an outlined button, or a link button
     * @default 'default'
     */
    variant?: 'default' | 'outline' | 'link';
}

function getButtonStyle(variant: 'default' | 'outline' | 'link') {
    const solidVariant = variant === 'default';
    const mainColor = cvar('primaryColor');
    const hoverColor = solidVariant ? cvar('primaryHoverColor') : '#b0d9e6';
    const activeColor = solidVariant ? cvar('primaryActiveColor') : '#92c8da';
    return css`
        background: ${variant === 'default' ? mainColor : 'transparent'};
        color: ${variant === 'default' ? '#fff' : mainColor};
        padding: 10px 24px;
        font-family: inherit;
        font-size: 14px;
        border: 1px solid ${variant === 'link' ? 'transparent' : mainColor};
        border-radius: 2px;
        font-weight: 600;
        cursor: pointer;

        &[disabled] {
            background: ${cvar('inactiveColor')};
            border: 1px solid ${cvar('inactiveColor')};
            color: rgba(58, 65, 76, 0.5);
            cursor: default;
        }

        &:hover:not([disabled]) {
            background: ${hoverColor};
            border-color: ${hoverColor};
        }

        &:active:not([disabled]) {
            background: ${activeColor};
            border-color: ${activeColor};
        }
    `;
}

export function Button({ children, variant = 'default', disabled, className, ...restOfProps }: ButtonProps) {
    const variantClassName = variant !== 'default' ? `dsc-button--${variant}` : '';

    return (
        <button
            data-testid="dsc-button"
            className={cx('dsc-button', variantClassName, getButtonStyle(variant), className)}
            disabled={disabled}
            type="button"
            {...restOfProps}
        >
            {children}
        </button>
    );
}
