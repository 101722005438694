import type { CSSProperties, ReactNode } from 'react';
import React, { useRef } from 'react';

function delay(timeout: number) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(resolve, timeout));
}

interface CancellablePromise {
    // TODO: Remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    promise: Promise<any>;
    cancel: () => void;
}

// TODO: Remove any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function cancellablePromise(promise: Promise<any>) {
    const isCancelled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            (value) => (isCancelled ? reject() : resolve(value)),
            (error) => reject(new Error(error)),
        );
    });

    return {
        promise: wrappedPromise,
        cancel: () => !isCancelled,
    };
}

function useCancellablePromises() {
    const pendingPromises = useRef<CancellablePromise[]>([]);

    const appendPendingPromise = (promise: CancellablePromise) => {
        pendingPromises.current = [...pendingPromises.current, promise];
    };

    const removePendingPromise = (promise: CancellablePromise) => {
        pendingPromises.current = pendingPromises.current.filter((p: CancellablePromise) => p !== promise);
    };

    const clearPendingPromises = () => pendingPromises.current.map((p: CancellablePromise) => p.cancel());

    return {
        appendPendingPromise,
        removePendingPromise,
        clearPendingPromises,
    };
}

export function useClickPreventionOnDoubleClick(onClick: () => void, onDoubleClick: () => void) {
    const api = useCancellablePromises();

    const handleClick = () => {
        api.clearPendingPromises();
        const waitForClick = cancellablePromise(delay(300));
        api.appendPendingPromise(waitForClick);

        return waitForClick.promise
            .then(() => {
                api.removePendingPromise(waitForClick);
                onClick();
            })
            .catch((error) => {
                api.removePendingPromise(waitForClick);
                throw error;
            });
    };

    const handleDoubleClick = () => {
        api.clearPendingPromises();
        onDoubleClick();
    };

    return [handleClick, handleDoubleClick];
}

interface DoubleClickableProps {
    children: ReactNode | ReactNode[];
    onClick?: () => void;
    onDoubleClick?: () => void;
    className?: string;
    style?: CSSProperties;
}

export function DoubleClickable({
    onClick = () => {},
    onDoubleClick = () => {},
    children,
    ...props
}: DoubleClickableProps) {
    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClick, onDoubleClick);

    return (
        // TODO (#38) Accessibility improvements of DoubleClickable component
        <div onClick={handleClick} onDoubleClick={handleDoubleClick} {...props}>
            {children}
        </div>
    );
}
