import { css } from '@emotion/css';

import { PreviewPosition } from './types';

export const wrapperStyle = (position?: string) => {
    const positionToFlexDirection = {
        [PreviewPosition.LEFT]: 'row-reverse',
        [PreviewPosition.RIGHT]: 'row',
        [PreviewPosition.TOP]: 'column-reverse',
        [PreviewPosition.BOTTOM]: 'column',
    };
    const flexDirection = position && positionToFlexDirection[position as keyof typeof positionToFlexDirection];

    return css`
        display: flex;
        ${flexDirection ? `flex-direction: ${flexDirection};` : ''}
    `;
};
