import styled from '@emotion/styled';

import { NumberInput } from '../numberInput';

export const InputSliderNumberInput = styled(NumberInput)`
    width: 72px;

    > input {
        width: 100%;
    }
`;
