import { useEffect } from 'react';

import type { VortexContext } from '@rendering/product-preview-react';
import { cameraHelpers } from '@rendering/product-preview-react';

interface CustomEventProps {
    action: 'lookAtFront' | 'lookAtBack';
}

export const triggerVortexAction = (action: 'lookAtFront' | 'lookAtBack') => {
    const vortexActionEvent = new CustomEvent('VortexActionEvent', { detail: { action } });
    document.dispatchEvent(vortexActionEvent);
};

export const VortexActionsHandler = ({ preview }: { preview: VortexContext['preview'] }) => {
    useEffect(() => {
        const onAction = (e: CustomEvent<CustomEventProps>) => {
            if (!preview) return;

            if (e.detail.action === 'lookAtFront') {
                cameraHelpers.lookAtFront(preview);
            } else if (e.detail.action === 'lookAtBack') {
                cameraHelpers.lookAtBack(preview);
            }
        };

        function handleOnAction(e: Event) {
            onAction(e as CustomEvent<CustomEventProps>);
        }

        // TODO: Optimize the code by eliminating the repeated code for event listener (VortexPreviewActions and VortexActionsHandler)
        document.addEventListener('VortexActionEvent', handleOnAction);

        return () => {
            document.removeEventListener('VortexActionEvent', handleOnAction);
        };
    }, [preview]);

    return null;
};
