import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';

export function DelayedLoadingSpinner({
    loader,
    content,
    time = 300,
    className,
}: Readonly<{
    loader: boolean;
    content: string | ReactNode | ReactNode[];
    time?: number;
    className?: string;
}>) {
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (!loader) {
            setShow(false);
        } else {
            timeout = setTimeout(() => {
                setShow(true);
            }, time);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [loader, time]);

    if (show) {
        return <div className={className}>{content}</div>;
    }
    return null;
}
