import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { cx } from '@emotion/css';

import { useInitializeEffect } from '@design-stack-ct/utility-react';

import { Preview } from './components';
import {
    defaultPreviewSettings,
    defaultSceneConfiguration,
    defaultTenantConfiguration,
    defaultVortexConfiguration,
} from './configuration/configuration';
import { PREFIX_CLASS } from './constants/constants';
import { wrapperStyle } from './FusionPreviewCss';
import { CimDocProvider, LookUpServiceProvider, PreviewConfigurationProvider } from './providers';
import { LookupServiceManager } from './store';
import { type PreviewProps, type PreviewWithoutCimDocProps } from './types';

// !NOTE: This is the entry point for the fusion-preview package and it won't work with <React.StrictMode> enabled.
export const FusionPreview = observer(
    ({
        cimDoc,
        viewData,
        productConfiguration,
        authToken,
        tenantConfiguration = defaultTenantConfiguration,
        previewSettings = defaultPreviewSettings,
        vortexConfiguration = defaultVortexConfiguration,
        sceneConfiguration = defaultSceneConfiguration,
        className,
        localization = {},
    }: PreviewProps) => {
        const previewConfiguration: PreviewWithoutCimDocProps = useInitializeEffect(
            () => ({
                productConfiguration,
                tenantConfiguration,
                previewSettings: _.merge(defaultPreviewSettings, previewSettings),
                vortexConfiguration: _.merge(defaultVortexConfiguration, vortexConfiguration),
                sceneConfiguration: _.merge(defaultSceneConfiguration, sceneConfiguration),
                localization,
            }),
            // TODO: We need this as only constructor but check once
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [vortexConfiguration],
        );

        const lookupServiceManager = useInitializeEffect(
            () => new LookupServiceManager(productConfiguration, tenantConfiguration, authToken, viewData),
            [productConfiguration, tenantConfiguration, authToken, viewData],
        );

        const memoizedCimDoc = useMemo(() => cimDoc, [cimDoc]);

        if (previewConfiguration && lookupServiceManager) {
            return (
                <PreviewConfigurationProvider config={previewConfiguration}>
                    <LookUpServiceProvider lookupServiceManager={lookupServiceManager}>
                        <CimDocProvider cimDoc={{ cimDoc: memoizedCimDoc }}>
                            <div
                                className={cx(
                                    `${PREFIX_CLASS}-wrapper`,
                                    wrapperStyle(previewConfiguration.previewSettings?.position),
                                    className,
                                )}
                            >
                                {(previewConfiguration.previewSettings?.vortex ||
                                    previewConfiguration.previewSettings?.scene) && <Preview />}
                            </div>
                        </CimDocProvider>
                    </LookUpServiceProvider>
                </PreviewConfigurationProvider>
            );
        }

        return null;
    },
);
