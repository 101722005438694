import { css } from '@emotion/css';

import { getOutlineStyle } from './getOutlineStyle';
import type { ButtonToggleGroupOrientation,ButtonToggleGroupVariant } from './types';
import { cvar } from '../../theme';

const gridStyle = css`
    .dsc-button-toggle {
        height: 96px;
        margin: 4px;
        border-radius: 4px;
        border-style: solid;
        border-color: ${cvar('primaryBorderColor')};
        background-color: ${cvar('primaryBackgroundColor')};

        &[disabled] {
            cursor: default;
            color: ${cvar('inactiveColor')};
            border-color: ${cvar('inactiveBorderColor')};
        }

        :focus {
            outline-color: ${cvar('primaryActiveColor')};
        }
    }

    .dsc-button-toggle--selected {
        background-color: ${cvar('primaryOverlayColor')};
        color: ${cvar('primaryActiveColor')};
        border-color: ${cvar('primaryColor')};
        border-radius: 4px;
    }
`;

const iconStyle = css`
    .dsc-button-toggle {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        background: transparent;
        border: none;
        color: ${cvar('primaryColor')};

        &[disabled] {
            cursor: default;
            color: ${cvar('inactiveColor')};
            svg {
                color: ${cvar('inactiveColor')};
            }
        }

        svg {
            color: ${cvar('primaryColor')};
        }

        @media (hover: hover) {
            &:hover:not([disabled]):not(.dsc-button-toggle--selected) {
                color: ${cvar('primaryHoverColor')};
                svg {
                    color: ${cvar('primaryHoverColor')};
                }
            }
        }

        &:active:not([disabled]):not(.dsc-button-toggle--selected) {
            color: ${cvar('primaryActiveColor')};
            svg {
                color: ${cvar('primaryActiveColor')};
            }
        }
    }

    .dsc-button-toggle--selected {
        color: ${cvar('primaryActiveColor')};

        &[disabled] {
            cursor: default;
            color: ${cvar('inactiveColor')};
        }

        svg {
            color: ${cvar('primaryActiveColor')};
        }
    }
`;

const underlineStyle = css`
    .dsc-button-toggle {
        margin: 0;
        padding: 5px 12px;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid ${cvar('primaryBorderColor')};
        background-color: ${cvar('primaryBackgroundColor')};
        color: #8090a2;
        flex-grow: 1;
        flex-shrink: 0;

        &[disabled] {
            cursor: default;
            color: ${cvar('inactiveColor')};
            border-color: ${cvar('inactiveBorderColor')};
        }

        :focus {
            outline-color: ${cvar('primaryActiveColor')};
        }
    }

    .dsc-button-toggle--selected {
        border-bottom-color: ${cvar('primaryActiveColor')};
        color: ${cvar('primaryActiveColor')};
    }
`;

const defaultStyle = css`
    .dsc-button-toggle {
        margin: 0;
        border-radius: 3px;
        background: none;
        border: none;
        color: ${cvar('primaryColor')};

        &[disabled] {
            cursor: default;
            color: ${cvar('inactiveColor')};
        }

        :focus {
            outline-color: ${cvar('primaryActiveColor')};
        }
    }

    .dsc-button-toggle--selected {
        color: ${cvar('primaryActiveColor')};
        background-color: ${cvar('primaryOverlayColor')};
    }
`;

export function getVariantStyle(
    variant: ButtonToggleGroupVariant,
    orientation: ButtonToggleGroupOrientation,
): string | undefined {
    switch (variant) {
        case 'grid':
            return gridStyle;
        case 'icon':
            return iconStyle;
        case 'outline':
            return getOutlineStyle({ orientation });
        case 'underline':
            return underlineStyle;
        case 'default':
        default:
            return defaultStyle;
    }
}
