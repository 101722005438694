import { css } from '@emotion/css';

import type { ButtonToggleGroupOrientation } from './types';
import { cvar } from '../../theme';

export interface GetOutlineStyleArgs {
    orientation: ButtonToggleGroupOrientation;
}

export function getOutlineStyle({ orientation }: GetOutlineStyleArgs) {
    return css`
        .dsc-button-toggle {
            margin: 0;
            color: ${cvar('primaryColor')};
            border-style: solid;
            border-color: ${cvar('primaryColor')};
            background-color: ${cvar('primaryBackgroundColor')};
            padding: 5px 15px;

            &[disabled] {
                cursor: default;
                color: ${cvar('inactiveColor')};
                border-color: ${cvar('inactiveBorderColor')};
            }

            :focus {
                outline-color: ${cvar('primaryActiveColor')};
            }

            /* Achieve effect of single border between buttons in both orientations */
            border-width: ${orientation === 'horizontal' ? '1px 0px 1px 1px' : '1px 1px 0 1px'};

            /* First of type has rounded corners on correct sides based on orientation */
            :first-of-type {
                border-radius: ${orientation === 'horizontal' ? '3px 0 0 3px' : '3px 3px 0 0'};
            }

            /* Last has rounded corners on correct sides based on orientation & has all borders */
            :last-child {
                border-width: 1px;
                border-radius: ${orientation === 'horizontal' ? '0 3px 3px 0' : '0px 0px 3px 3px'};
            }
        }

        .dsc-button-toggle--selected {
            background-color: ${cvar('primaryOverlayColor')};
            color: ${cvar('primaryActiveColor')};
            border-color: ${cvar('primaryActiveColor')};

            /* Because of single border between buttons, style the next button toggle's touching border to be selected color */
            + .dsc-button-toggle {
                ${orientation === 'horizontal'
                    ? `border-left: 1px solid ${cvar('primaryActiveColor')}`
                    : `border-top: 1px solid ${cvar('primaryActiveColor')}`};
            }
        }
    `;
}
