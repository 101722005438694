import type { ReactNode } from 'react';
import React, { createContext, useContext, useState } from 'react';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider, MouseTransition, TouchTransition } from 'react-dnd-multi-backend';
import TouchBackend from 'react-dnd-touch-backend';

interface DraggableState {
    isDragging: boolean;
    setIsDragging: (isDragging: boolean) => void;
}

const draggableContext = createContext<DraggableState>({ isDragging: false, setIsDragging: () => {} });

export function useDraggingState() {
    return useContext(draggableContext);
}

const HTML5toTouch = {
    backends: [
        {
            backend: HTML5Backend,
            transition: MouseTransition,
        },
        {
            backend: TouchBackend,
            options: {
                enableMouseEvents: true,
            },
            preview: true,
            transition: TouchTransition,
        },
    ],
};

export function DragAndDropProvider({ children }: { children: ReactNode | ReactNode[] }) {
    const { Provider } = draggableContext;
    const [isDragging, setIsDragging] = useState(false);

    return (
        <DndProvider options={HTML5toTouch}>
            <Provider value={{ isDragging, setIsDragging }}>{children}</Provider>
        </DndProvider>
    );
}
