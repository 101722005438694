import type { ReactNode } from 'react';

import type { CimDoc } from '@design-stack-ct/cdif-types';
import type { View } from '@rendering/lookup';

import { LARGE, SMALL } from '../constants';

export interface ProductConfiguration {
    sku: string;
    attributes: Record<string, string>;
    version?: number;
}

export interface TenantConfiguration {
    id: string;
    type: string;
    /**
     * Note: we don't know all the property names at the time of defining the interface,
     * but we want to allow for additional properties dynamically,
     * that's why we use an index signature with string keys and any value type
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any; // Allow indexing with string keys
}

export interface DefaultSceneConfiguration {
    isDefaultUnderlaySceneEnable: boolean;
    isDefaultOverlaySceneEnable: boolean;
}

export interface SceneConfiguration {
    purpose: string;
    subPurpose: string;
    tags?: string[];
}

export enum PreviewPosition {
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
}

export enum sceneTypeProps {
    small = SMALL,
    large = LARGE,
}

export type PreviewPositionType = keyof typeof PreviewPosition;

export interface PreviewSettings {
    vortex?: boolean;
    scene?: boolean;
    position?: PreviewPosition;
}

export interface VortexConfiguration {
    vortexActions?: boolean;
    loader?: ReactNode | ReactNode[];
    dimensions?: {
        width: string;
        height: string;
    };
    error: {
        showErrorMessage: boolean;
        errorMessage?: string;
        errorCallback?: (error: boolean) => void;
    };
    successCallback?: (success?: boolean) => void;
    onAnimate?: {
        animationDuration?: number;
    };
}

export interface PreviewWithoutCimDocProps {
    productConfiguration: ProductConfiguration;
    tenantConfiguration?: TenantConfiguration;
    vortexConfiguration?: VortexConfiguration;
    sceneConfiguration?: SceneConfiguration;
    previewSettings?: PreviewSettings;
    className?: string;
    localization?: Record<string, string>;
}

export interface PreviewProps extends PreviewWithoutCimDocProps {
    authToken: string;
    cimDoc: CimDoc;
    viewData?: View[];
}

export interface PreviewButtonEvent extends CustomEvent {
    detail: {
        id?: string;
    };
}
